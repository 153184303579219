import React from 'react'
import PropTypes from 'prop-types'
import { clearError } from '../../../../util/validates'
import BeaconLink from '../../../BeaconLink'
import CharacterLimitation from '../Input/CharacterLimitation'

const Textarea = ({
    width,
    className: classNameFromProps,
    placeholder,
    name,
    value,
    containerStyle,
    label,
    beaconId,
    error,
    isDisabled,
    errorMessage,
    onChange,
    showLengthWarningAtLength,
    showLengthWarning,
    ...rest
}) => {
    const className = `input_wrapper mb-0 width_${width} ${
        classNameFromProps || ''
    } `
    return (
        <div className={className} style={containerStyle}>
            {label && (
                <label htmlFor={name}>
                    {beaconId ? (
                        <BeaconLink beaconId={beaconId} label={label} />
                    ) : (
                        label
                    )}
                </label>
            )}
            <div>
                <textarea
                    className={error ? 'error' : ''}
                    type="text"
                    name={name}
                    placeholder={placeholder}
                    value={value? value : undefined}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                    disabled={isDisabled}
                    readOnly={isDisabled}
                    onChange={onChange || (() => clearError())}
                    maxLength={showLengthWarningAtLength}
                />
                {showLengthWarningAtLength && value && (
                    <CharacterLimitation
                        value={value}
                        limit={showLengthWarningAtLength}
                        text={showLengthWarning}
                    />
                )}
               
                <div className="error_message orange-t">
                    {error === true ? errorMessage : ''}
                </div>
            </div>
        </div>
    )
}
Textarea.defaultProps = {
    isDisabled: false,
    beaconId: null,
    errorMessage: null,
    error: null,
    containerStyle: null,
    className: '',
    name: null,
    onChange: null,
    showLengthWarningAtLength: null,
    showLengthWarning: ''
}

Textarea.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
    containerStyle: PropTypes.node,
    label: PropTypes.string.isRequired,
    beaconId: PropTypes.number,
    error: PropTypes.string,
    isDisabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.string,
    showLengthWarningAtLength: PropTypes.number,
    showLengthWarning: PropTypes.string
}

export default Textarea

import { post } from '../api/common';

export const createTag = async(store, state, payload) => {
    const tag = await post('/api/tags', 'POST', payload);

    store.setState({
        tags: [...state.tags, tag],
        error: false,
    });
};

import React, { Component } from 'react'
import ReactGA from 'react-ga'
import MultiSteps from '../../../components/MultiSteps'
import Step1 from './Step1/CreateStep1'
import Step2 from './Step2/CreateStep2'
import Content from "../../../components/Content";
import {hasReachedLinksLimit} from "../../../util/user-management";
import {Link} from "react-router-dom";

class LinksCreateWizard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            disabledSteps: [2],
        }

        this.setStep = this.setStep.bind(this)
        this.handleDisabledSteps = this.handleDisabledSteps.bind(this)
    }

    componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Link Create',
        })
    }

    setStep(currentStep) {
        this.setState({ currentStep })
    }

    handleDisabledSteps(disabledSteps) {
        this.setState({ disabledSteps })
    }

    render() {
        const { history } = this.props
        const { currentStep, disabledSteps } = this.state
        const getCurrentStep = (step) => this.setState({ currentStep: step })

        return (
            <Content>
                {!hasReachedLinksLimit() ? (
                    <div>
                        <MultiSteps
                            totalSteps={2}
                            currentStep={currentStep}
                            disabledSteps={disabledSteps}
                            getCurrentStep={getCurrentStep}
                        />

                        {currentStep === 1 && (
                            <Step1
                                setStep={this.setStep}
                                handleDisabledSteps={this.handleDisabledSteps}
                                history={history}
                            />
                        )}

                        {currentStep === 2 && <Step2 history={history} />}
                    </div>
                ) : (
                    <div>
                        <h1>Links Limit Reached</h1>
                        <p>You have reached the maximum amount of links for your subscription.</p>
                        <Link to="/stripe-customer-portal">Upgrade your subscription</Link>
                    </div>
                )}
            </Content>
        )
    }
}

export default LinksCreateWizard

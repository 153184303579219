import React from 'react';
import { Link } from 'react-router-dom';
import buttonStyle from '../style.module.scss';

const ButtonLink = (props) => {
  const button = `${props.secondary ? buttonStyle.button_secondary : buttonStyle.button_primary}`;

  return (
    // Notice: use props 'targetBlank' to link extern//
    props.targetBlank
      ? <a className={button} href={props.href} target="blank">{props.label ? props.label : 'Learn more'}</a>
      : <Link onClick={props.onClick} className={button} to={props.href}>{props.label ? props.label : 'Learn more'}</Link>
  );
};

export default ButtonLink;

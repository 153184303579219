import React, { Component } from 'react'
import style from './style.module.scss'

export default class MultiSteps extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentStep: 1,
            totalSteps: 2,
            disabledSteps: [2],
        }
    }

    componentDidMount() {
        const totalSteps = this.props.totalSteps ? this.props.totalSteps : 2
        const currentStep = this.props.currentStep ? this.props.currentStep : 1
        this.setState({
            totalSteps,
            currentStep,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentStep !== this.state.currentStep) {
            window.scrollTo(0, 0)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (
            props.totalSteps !== state.totalSteps ||
            props.currentStep !== state.currentStep ||
            props.disabledSteps !== state.disabledSteps
        ) {
            return {
                totalSteps: props.totalSteps,
                currentStep: props.currentStep,
                disabledSteps: props.disabledSteps,
            }
        }
        return null
    }

    setStep(index) {
        this.setState({ currentStep: index })
        this.props.getCurrentStep(index)
    }

    render() {
        const { currentStep, totalSteps, disabledSteps } = this.state
        const { headline, description } = this.props

        const stepContainerWidth = 35
        const spaceBetween = this.props.spaceBetween
            ? this.props.spaceBetween
            : 50
        const progressBarWidth =
            (stepContainerWidth + spaceBetween) * (currentStep - 1)
        const totalWidth =
            spaceBetween * (totalSteps - 1) + stepContainerWidth * totalSteps

        const items = []

        for (let index = 1; index <= totalSteps; index++) {
            items.push(
                <span
                    key={index}
                    style={{ width: `${stepContainerWidth}px` }}
                    data-active={currentStep === index}
                    data-disabled={disabledSteps.includes(index)}
                    onClick={() => {
                        this.setStep(index)
                    }}
                >
                    {index}

                    {description && (
                        <span className={style.description}>
                            {description[index - 1]}
                        </span>
                    )}
                </span>
            )
        }

        return (
            <div>
                <div
                    className={style.steps_container}
                    style={{ width: `${totalWidth}px` }}
                >
                    {items}
                    <div
                        className={style['active-bar']}
                        style={{ width: `${progressBarWidth}px` }}
                    />
                </div>

                {headline && <h1>{headline}</h1>}
            </div>
        )
    }
}

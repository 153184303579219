import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import chartStyle from '../../../components/Charts/style.module.scss'
import { getLinks, getLinkAnalytics } from '../../../api/links'
import Spinner from '../../../components/Spinner'
import DoughnutChart from '../../../components/Charts/DoughnutChart'
import LineChart from '../../../components/Charts/LineChart'
import BarChart from '../../../components/Charts/BarChart'
import ListChart from '../../../components/Charts/ListChart'
import Content from '../../../components/Content'
import FilterBar from '../../../components/FilterBar'
import DateFilter from '../../../components/FilterBar/DateFilter'
import Copy from '../../../components/Copy/Copy'
import BackLink from '../../../components/BackLink'
import CSVDownloadButton from '../../../components/CSVDownloadButton/CSVDownloadButton'

const moment = require('moment')

export default class LinksDetail extends Component {
    constructor(props) {
        super(props)
        const { match } = props
        const { id } = match.params
        this.state = {
            id,
            dateStart: moment()
                .startOf('day')
                .add(12, 'hours')
                .subtract(30, 'days')
                .format('YYYY-MM-DD'),
            dateEnd: moment()
                .startOf('day')
                .subtract(12, 'hours')
                .format('YYYY-MM-DD'),
            selectedRange: '30d',
            analytics: null,
            title: '',
            url: '',
            isFetchingData: true,
        }
    }

    async componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Link Analytics',
        })
        const { id, dateStart, dateEnd } = this.state

        const link = await getLinks(id)
        const analytics = await getLinkAnalytics(id, { dateStart, dateEnd })

        this.setState({
            link,
            analytics,
            title: link.title,
            url: link.url,
            isFetchingData: false,
        })
    }

    async updateDate({ from, to }) {
        const { id } = this.state

        const dateStart = moment(from).format('YYYY-MM-DD')
        const dateEnd = moment(to).format('YYYY-MM-DD')
        this.setState({ isFetchingData: true })
        const analytics = await getLinkAnalytics(id, { dateStart, dateEnd })
        this.setState({
            analytics,
            dateStart,
            dateEnd,
            isFetchingData: false,
        })
    }

    render() {
        const {
            dateStart,
            dateEnd,
            link,
            analytics,
            selectedRange,
            title,
            url,
            isFetchingData,
            id,
        } = this.state

        function getDescriptionText() {
            return `Created: ${moment(link.created_at).format(
                'DD.MM.Y'
            )} · User: ${link.user} · Group: ${link.usergroup}`
        }

        return (
            <Content>
                <BackLink target="/links" title="Back to Links" />

                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, paddingTop: '28px' }}>
                        <h1
                            style={{
                                display: 'inline-block',
                                fontSize: '38px',
                            }}
                        >
                            {title}
                        </h1>
                        {link ? (
                            <p className="information-bar">
                                {getDescriptionText()}
                            </p>
                        ) : null}
                    </div>
                    <div>
                        <FilterBar>
                            <DateFilter
                                selectedRange={selectedRange}
                                dateStart={dateStart}
                                dateEnd={dateEnd}
                                callback={(dates) => this.updateDate(dates)}
                            />
                        </FilterBar>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 9,
                    }}
                >
                    <div style={{ marginTop: 0, marginBottom: 0 }}>
                        <Copy value={url} />
                    </div>
                    <CSVDownloadButton
                        type="links"
                        id={id}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                    />
                </div>

                {analytics ? (
                    <div>
                        <br/>
                        <br/>
                        <LineChart
                            data={analytics.hitsByDay.data}
                            totalHits={analytics.hitsTotals.data.total}
                            headline="Clicks by day"
                            keyColor="#318692"
                            containerStyle={{ marginTop: 20 }}
                        />
                        <BarChart
                            data={analytics.hitsByHourOfDay.data}
                            headline="Clicks by time"
                            keyColor="#318692"
                            containerStyle={{ marginTop: 30 }}
                        />
                        <div
                            className={chartStyle.chartGroup}
                            style={{ marginBottom: 30 }}
                        >
                            <DoughnutChart
                                data={analytics.hitsTotals.data}
                                headline="Devices"
                                colorDesktop="#B9DFE5"
                                colorMobile="#3DB3C4"
                                colorTablet="#318692"
                                containerStyle={{ marginTop: 30 }}
                            />
                            <ListChart
                                data={analytics.hitsByReferrer.data}
                                headline="Top 5 visitor sources by clicks"
                                containerStyle={{ marginTop: 30 }}
                            />
                        </div>
                    </div>
                ) : null}
                <Spinner show={isFetchingData} />
            </Content>
        )
    }
}

LinksDetail.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.node,
        }).isRequired,
    }).isRequired,
}

import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import InviteUser from "./InviteUser";
import actions from '../../../actions';
import Content from '../../../components/Content';
import { createUsers } from '../../../api/users';
import {
    hasReachedUserLimit,
} from "../../../util/user-management";
import {Link} from "react-router-dom";

class UserCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [
                {
                    userId: 1,
                    firstname: '',
                    lastname: '',
                    email: '',
                    role: 'ROLE_USER',
                    usergroups: [],
                },
            ],
            defaultGroupId: null,
            quantity: 1,
            inactiveSubmit: true,
        };

        this.handleDisabledSteps = this.handleDisabledSteps.bind(this);
        this.updateData = this.updateData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleErrorMessage = this.handleErrorMessage.bind(this);
    }

    async componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'User Create',
        });
        const defaultGroupId = this.props.groups[0].id;
        const users = this.state.users.map((user) => ({ ...user, usergroups: [defaultGroupId] }));
        this.setState({ users, defaultGroupId });
    }

    handleDisabledSteps(disabledSteps) {
        this.setState({ disabledSteps });
    }

    async handleSubmit() {
        const {
            history, toggleLoading, refreshAdminData, toggleSnackbar,
        } = this.props;

        const { users } = this.state;
        toggleLoading(true);
        try {
            const json = await createUsers(JSON.stringify({ users }));
            // refetch users and groups
            await refreshAdminData();
            toggleLoading(false);
            history.push(`/user?newuser=${json.ids.join(',')}`);
        } catch (err) {
            toggleLoading(false);
            toggleSnackbar(err.message);
        }
    }

    handleErrorMessage() {
        this.props.toggleSnackbar('PayPal is currently processing your payment. Please try again later.');
    }

    updateData(data) {
        this.setState({ ...this.state, ...data });
    }

    render() {
        const {
            users, defaultGroupId, quantity,
        } = this.state;
        const { history } = this.props;

        return (
            <Content>
                {!hasReachedUserLimit() ? (
                    <InviteUser
                        users={users}
                        defaultGroupId={defaultGroupId}
                        quantity={quantity}
                        handleSubmit={this.handleSubmit}
                        updateData={this.updateData}
                        handleDisabledSteps={this.handleDisabledSteps}
                        history={history}
                    />
                ) : <div>
                    <h1>Invite Users</h1>
                    <p>
                        You have reached the maximum number of users for your account. Please <Link to="/stripe-customer-portal">upgrade</Link> your account to invite more users.
                    </p>
                </div>}
            </Content>
        );
    }
}

export default connect('groups', actions)(UserCreate);

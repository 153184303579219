import React from 'react';
import style from './style.module.scss';

const LinkboardSpinner = (props) => {
  const show = `${style.wrapper} ${props.show ? style.show : ''} ${props.small ? style.small : ''}`;
  return (
    <div className={show}>
      <div className={style.wrapper_circle}>
        <div className={style.circle_dark} />
        <div className={style.circle_light} />
      </div>
      {(!props.small || !props.small === undefined) ?
        <p>loading</p> : <></>
      }
    </div>
  );
};

export default LinkboardSpinner;

import { get, post } from '../api/common';
import { getCampaigns as getCampaign } from '../api/data-management';

export const getCampaigns = async(store, state, page, limit, query = null) => {
    store.setState({ loading: true });

    const searchQuery = query !== null ? `&search=${query}` : '';

    const res = await get(`/api/campaigns?page=${page}&limit=${limit}${searchQuery}`, null, false);
    const total = res.headers.get('X-Total-Count');
    const items = await res.json();

    return {
        campaigns: {
            ...state.campaigns,
            items,
            currentPage: page,
            pages: Math.ceil(total / limit),
            fetched: true,
        },
        loading: false,
    };
};

export const createCampaign = async(store, state, payload) => {
    const json = await post('/api/campaigns', 'POST', payload);

    const { id } = json;
    const campaign = await getCampaign(id);

    store.setState({
        campaign,
        error: false,
    });
};

import React from 'react'
import ReactDOM from 'react-dom'
import createStore from 'unistore'
import { Provider } from 'react-unistore'
import App from './components/app'
import './style/index.module.scss'
import initialState from './model'
import devtools from 'unistore/devtools'

// For other environments we want to have devtools running
// see https://github.com/developit/unistore for further informations 
const store = process.env.NODE_ENV === 'production' ?  createStore(initialState) : devtools(createStore(initialState));

// TODO: only show logs in development
if (process.env.NODE_ENV !== 'production') {
    // store.subscribe((data) => {
    //     console.info(data);
    // });
}

ReactDOM.render(
    <React.StrictMode>
        <Provider value={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

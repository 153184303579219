import moment from 'moment'
import React from 'react'
import { BanOutline, DocumentTextOutline, InformationOutline } from 'react-ionicons'
import ReactTooltip from 'react-tooltip'
import ActionToggle from '../ActionToggle/ActionToggle'
import { addDecimalDivider } from '../Charts/Tooltip'
import Copy from '../Copy/Copy'
import FavoriteToggle from '../FavoriteToggle/FavoriteToggle'
import TrendlineChart from '../TrendlineChart/TrendlineChart'
import style from './style.module.scss'

function Nothing({ ...props }) {
    const nothingFoundText = props.nothingFoundText
    return (
        // eslint-disable-next-line react/no-unescaped-entities
        <div className={style.nothingToShow}>{nothingFoundText}</div>
    )
}

function LinkGrid({
    data,
    fetched,
    ...props }) {

    const nothingFoundText = props.nothingFoundText
    const highLightId = props.highLightId

    return (
        <div>
            {
                data.length === 0 ?
                    <div className={style.nothingFoundContainer}>
                        <Nothing nothingFoundText={nothingFoundText} />
                    </div>
                    :
                    <div className={style.linkGrid}>
                        {data.map((item) => (
                            <div className={`${highLightId == item.id ? `${style.highlight}` : ''} ${style.linkGridItem}`} key={item.id}>
                                <div className={`${style.cardImgWrapper} ${item.imageUrl ? '' : `${style.noImage}`}`}>
                                    {item.imageUrl ?
                                        <img src={item.imageUrl} title={item.title} width="1200" height="630" />
                                        :
                                        <>
                                        {item.available ?
                                            <div className={style.available}>
                                                <InformationOutline
                                                    color={'currentColor'}
                                                    title="New link, image is being checked daily. Please wait!"
                                                    height="2rem"
                                                    width="2rem"
                                                />
                                                <span>New link!</span>
                                                <span>Image is being checked daily. Please wait!</span>
                                            </div>
                                            :
                                            <div className={style.nothingFound}>
                                                <BanOutline
                                                    color={'currentColor'}
                                                    title="No image found"
                                                    height="2rem"
                                                    width="2rem"
                                                />
                                                <span>No image found!</span>
                                            </div>
                                        }
                                        </>
                                    }
                                </div>
                                <div className={style.cardContent}>
                                    {item.notes &&
                                        <div className={style.noteBadge}>
                                            <span className={style.notes} data-tip={item.notes} data-event="click">
                                                <DocumentTextOutline
                                                    color={'currentColor'}
                                                    title={'Links'}
                                                    width="1.25rem"
                                                    height="1.25rem"
                                                    className="icon" />
                                            </span>
                                            <ReactTooltip
                                                place="right"
                                                effect="solid"
                                                className="customTooltip"
                                                multiline={true}
                                                getContent={dataTip => (
                                                    <div className={style.notesTooltip}>
                                                        <span>Note:</span>
                                                        <p>{dataTip}</p>
                                                    </div>
                                                )}
                                                clickable={true}
                                                delayHide={200}
                                                globalEventOff="click"
                                            >
                                            </ReactTooltip>
                                        </div>
                                    }
                                    <div className={style.actionTogglerGrid}>
                                        <ActionToggle
                                            id={item.id}
                                            archive={item.archived}
                                            favorite={item.favorite}
                                            changeArchiveState={props.setArchive}
                                            type="links"
                                        />
                                    </div>
                                    <div className={style.favoriteTogglerGrid}>
                                        <FavoriteToggle
                                            id={item.id}
                                            fetched={fetched}
                                            favorite={item.favorite}
                                            changeFavoriteState={props.favChanged}
                                        />
                                    </div>

                                    <div className={`${style.cardRow} ${style.two}`}>
                                        <div className={style.title}>{item.title}</div>
                                    </div>
                                    <div className={`${style.cardRow} ${style.two}`}>
                                        <div className={style.overflow}>
                                            <a
                                                href={`${item.targetUrl}`}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                title={`${item.title}`}
                                                className={style.link}
                                            >
                                                {item.targetUrl}
                                            </a>
                                        </div>
                                        <TrendlineChart id={item.id} />
                                    </div>
                                    <div className={`${style.cardRow} ${style.three}`}>
                                        <div className={style.mono}>
                                            {moment(item.created_at).format('DD.MM.YY')}
                                        </div>
                                        <div className={style.groups}>{item.usergroup}</div>
                                        <div className={style.clicks}>
                                            {addDecimalDivider(item.views, ',')}
                                        </div>
                                    </div>
                                    <div className={style.copyField}>
                                        <Copy value={`${item.shortUrl}`} archive={item.archived} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
            }
        </div>
    )
}
export default LinkGrid
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import ReactTooltip from 'react-tooltip'
import style from './style.module.scss'
import { CopyOutline } from 'react-ionicons'
class Copy extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tooltipVisible: false,
            tooltipText: 'copy to clipboard',
        }

        this.copyToClipboard = this.copyToClipboard.bind(this)
        this.tip = this.tip.bind(this)
        this.unTip = this.unTip.bind(this)
    }

    copyToClipboard(text) {
        const copyTextArea = document.createElement('textarea')
        copyTextArea.value = `https://${text}`
        document.body.appendChild(copyTextArea)
        copyTextArea.select()
        try {
            ReactGA.event({
                category: 'Shortlink',
                action: 'Copy to Clipboard',
                label: `Copy to Clipboard [${window.location.pathname}]`,
            })
            document.execCommand('copy')
            this.tip('copied!')
        } catch (err) {
            this.tip('Ooops! Unable to copy')
        }
        document.body.removeChild(copyTextArea)
    }

    tip(text) {
        this.setState({
            tooltipVisible: true,
            tooltipText: text,
        })
    }

    unTip() {
        this.setState({ tooltipVisible: false })
    }

    render() {
        // eslint-disable-next-line no-useless-concat
        const tooltip = `${style.tooltip}` + ' copyTooltip'
        const iconWrapper = `${style.iconWrapper}` + ' resetHoverOnCopy'
        const maxWidth = this.props.maxWidth ? this.props.maxWidth : 250
        if (this.props.archive === true) {
            return (
                <>
                    <div
                        className={style.copyToClipboard}
                        data-tip="Shortlink not active"
                        data-for={this.props.value}
                    >
                        <div className={iconWrapper}>
                            <div
                                className={style.linkOverflow}
                                style={{ maxWidth: `${maxWidth}px` }}
                            >
                                {this.props.value}
                            </div>
                            <span className={style.iconBlocked}>
                                <CopyOutline color={'currentColor'} />
                            </span>
                        </div>
                    </div>
                    <ReactTooltip id={this.props.value}
                        place="top"
                        effect="solid"
                        className="customTooltip"
                    />
                </>
            )
        } else {
            return (
                <div className={style.copyToClipboard}>
                    <div
                        className={iconWrapper}
                        style={{ backgroundColor: this.props.bg }}
                    >
                        <a
                            className={style.linkOverflow}
                            href={`https://${this.props.value}`}
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ maxWidth: `${maxWidth}px` }}
                        >
                            {this.props.value}
                        </a>
                        <span
                            className={style.icon}
                            onMouseOver={() => this.tip('copy to clipboard')}
                            onMouseOut={() => this.unTip()}
                        >
                            <CopyOutline
                                color={'currentColor'}
                                onClick={() =>
                                    this.copyToClipboard(this.props.value)
                                }
                            />
                        </span>
                        <span
                            data-visible={this.state.tooltipVisible}
                            className={tooltip}
                        >
                            {this.state.tooltipText}
                        </span>
                    </div>
                </div>
            )
        }
    }
}

export default Copy

import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-unistore'
import actions from '../../../../actions'
import Content from '../../../../components/Content'
import style from './style.module.scss'
import Copy from '../../../../components/Copy/Copy'
import Button from '../../../../components/Button'
import { LinkOutline } from 'react-ionicons'

class Step2 extends Component {
    render() {
        const { link } = this.props.getState
        const filteredUsergroup = this.props.usergroups.filter(
            (item) => item.id === link.usergroup
        )
        const usergroup = filteredUsergroup[0].name

        return (
            <div>
                <Content wide headerGrid noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-links">
                            <LinkOutline
                                color={'currentColor'}
                                title={'Links'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            Well done!
                        </h1>
                    </div>
                </Content>
                <Content smallLeft>
                    <h2>Shortlink successfully updated.</h2>

                    <table className={`ReactTable ${style.tableGeneral}`}>
                        <tbody>
                            <tr>
                                <td>Title:</td>
                                <td>{link.title}</td>
                            </tr>
                            <tr>
                                <td>Target URL:</td>
                                <td>
                                    <a
                                        className={style.shorten}
                                        href={link.targetUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {link.targetUrl}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Group:</td>
                                <td>{usergroup}</td>
                            </tr>
                            <tr>
                                <td>Shortlink:</td>
                                <td>
                                    <Copy bg={'white'} value={link.url} />
                                </td>
                            </tr>
                            {link.notes && link.notes.length ? (
                                <tr>
                                    <td>Notes:</td>
                                    <td>
                                        {link.notes}
                                    </td>
                                </tr>
                            ) : null }
                        </tbody>
                    </table>

                    <Content
                        button
                        style={{ display: 'inline-block', marginTop: '40px' }}
                    >
                        <Button
                            label="Links overview"
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Link',
                                    action: 'Back to Overview',
                                    label: 'Back to Link Overview',
                                })
                                this.props.history.push(
                                    `/links?highlightId=${link.id}`
                                )
                            }}
                        />
                    </Content>
                </Content>
            </div>
        )
    }
}

export default connect('usergroups', actions)(Step2)

import React, { Component } from 'react';
import style from './style.module.scss';

export default class Tag extends Component {
    render() {
        const { label } = this.props;

        return (
            <span className={style.tag}>
                {label}
            </span>
        );
    }
}

import React, { Component } from 'react';
import Content from "../Content";
import {GlobeOutline} from "react-ionicons";
import Button from "../Button";
import PropTypes from "prop-types";
import MultiSteps from "../MultiSteps";
import Form from "../Form";

class DeleteMultiStep extends Component {

    constructor(props) {
        super(props)
    }
    render() {
        const getMultiSteps = (currentStep) => (
            <MultiSteps
                description={['DNS', 'Submit', 'Deletion', 'Finish']}
                totalSteps={4}
                currentStep={currentStep}
                disabledSteps={[1,2,3,4].filter((e) => e !== currentStep)}
            />
        )
        const {domain, onClick: deletionHandler, onChange: changeHandler, history, error} = this.props;

        return (
            <div>
                {domain.status === 'AVAILABLE' &&
                domain.verification_result === false ? (
                    <>
                        {getMultiSteps(1)}
                        <Content wide headerGrid noMargin>
                            <div className="headerRow">
                                <h1 className="underline__lh-default">
                                    <GlobeOutline
                                        color={'currentColor'}
                                        title={'Domain'}
                                        width="2rem"
                                        height="2rem"
                                        className="icon"
                                    />
                                    Update your DNS NS records
                                </h1>
                                <h3> {domain.name} </h3>
                            </div>
                        </Content>
                        <Content className="custom_dns">
                            <p>
                                In order to delete your domain you need
                                to point your domains nameservers back to your original provider.
                                <br/>
                                In your providers DNS settings you should delete the following nameservers:
                            </p>
                            <div
                                style={{
                                    lineHeight: '24px',
                                    backgroundColor: '#F7F7F7',
                                    padding: '.8rem',
                                    paddingLeft: '.8rem',
                                    paddingRight: '32px',
                                    borderRadius: '4px',
                                    marginTop: '20px',
                                    marginBottom: '20px'
                                }}
                            >
                                <ul>
                                    {domain.expected_nameservers.map((nameServer) => (
                                        <li>
                                            <>Delete <b>{nameServer}</b></>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <p>
                                In some cases, the provider offer an option to reset your nameserver to default.
                                After the records have been updated it can take
                                <b> up to 48 hours </b> for the changes to take
                                affect. <br/>
                                You can check if the change has been propagated on
                                this page.
                            </p>
                        </Content>
                    </>
                ) : null}
                {(domain.status === 'AVAILABLE' || domain.status === 'PENDING_VERIFICATION') &&
                domain.verification_result === true ? (
                    <>
                        {getMultiSteps(2)}
                        <Content wide headerGrid noMargin>
                            <div className="headerRow">
                                <h1 className="underline__lh-default">
                                    <GlobeOutline
                                        color={'currentColor'}
                                        title={'Domain'}
                                        width="2rem"
                                        height="2rem"
                                        className="icon"
                                    />
                                    Looks like your nameserver records are all reset to original provider!
                                </h1>
                                <h3> {domain.name} </h3>
                            </div>
                        </Content>
                        <Content className="custom_dns">
                            <p>
                                You need to set a default target, so the existing links can be redirected.
                            </p>
                            <Form>
                                <Form.Input
                                    label="Default Target"
                                    name="defaultTarget"
                                    placeholder="mydomain.com"
                                    width={100}
                                    error={error !== null}
                                    errorMessage={error}
                                    data-required
                                    onInput={(e) => {
                                        changeHandler(e.target.value)
                                    }}
                                />
                            </Form>
                            <p>
                                You can start the deletion process by pressing the
                                button below.
                                Please make sure you set another default domain in your account,
                                if this domain was your default, otherwise it will be reset!
                                You will be notified by mail when your domain is
                                deleted.
                            </p>
                        </Content>
                    </>
                ) : null}
                {domain.status === 'PENDING_DELETION' ? (
                    <>
                        {getMultiSteps(3)}
                        <Content wide headerGrid noMargin>
                            <div className="headerRow">
                                <h1 className="underline__lh-default">
                                    <GlobeOutline
                                        color={'currentColor'}
                                        title={'Domain'}
                                        width="2rem"
                                        height="2rem"
                                        className="icon"
                                    />

                                    Deletion in progress
                                </h1>
                                <h3> {domain.name} </h3>
                            </div>
                        </Content>
                        <Content className="custom_dns">
                            <p>
                                You will be notified by mail when your domain is
                                deleted.
                            </p>
                        </Content>
                    </>
                ) : null}
                {domain.status === 'DELETED' ? (
                    <>
                        {getMultiSteps(4)}
                        <Content wide headerGrid noMargin>
                            <div className="headerRow">
                                <h1 className="underline__lh-default">
                                    <GlobeOutline
                                        color={'currentColor'}
                                        title={'Domain'}
                                        width="2rem"
                                        height="2rem"
                                        className="icon"
                                    />

                                    Your domain is now deleted, you will no longer see the domain in your account!
                                    The domain will only be remain for statistic purposes.
                                </h1>
                                <h3> {domain.name} </h3>
                            </div>
                        </Content>
                    </>
                ) : null}
                <Content
                    button
                    style={{
                        display: 'inline-block',
                    }}
                >
                    <Button
                        label="Back to domain overview"
                        secondary
                        onClick={() => {
                            history.push('/domains')
                        }}
                    />
                    {(domain.status === 'AVAILABLE' || domain.status === 'PENDING_VERIFICATION') &&
                    domain.verification_result === true ? (
                        <Button
                            label="Delete my Domain!"
                            onClick={() => {
                                deletionHandler()
                            }}
                        />
                    ) : null}
                </Content>
            </div>
        );
    }
}

DeleteMultiStep.propTypes = {
    domain: PropTypes.object.isRequired,
    defaultTarget: PropTypes.string,
    error: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DeleteMultiStep;
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

export default class TagCloud extends Component {
    constructor(props) {
        super(props)

        this.state = {
            expanded: false,
            tagsShownWhenCollapsed: 3,
        }
    }

    render() {
        const { tags } = this.props
        const { tagsShownWhenCollapsed, expanded } = this.state

        const renderedTags = function () {
            return tags.map((tag) => {
                const hidden =
                    !expanded && tags.indexOf(tag) >= tagsShownWhenCollapsed
                        ? 'hidden'
                        : ''
                return <div className={`tag ${hidden}`}>{tag.name}</div>
            })
        }

        const buttonText = function () {
            return expanded
                ? 'less'
                : `… + ${tags.length - tagsShownWhenCollapsed} more`
        }

        if (tags.length === 0) {
            return null
        }

        return (
            <div className={style.tagCloudContainer}>
                {renderedTags()}
                {tags.length > 3 ? (
                    <button
                        type="button"
                        onClick={() => {
                            this.setState({ expanded: !expanded })
                        }}
                        className={style.moreTagsButton}
                    >
                        {buttonText()}
                    </button>
                ) : null}
            </div>
        )
    }
}

TagCloud.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Switch,
    Route,
} from 'react-router-dom';
import { connect } from 'react-unistore';

import ReactGA from 'react-ga';
import SubscribedAdminRoute from './Routing/SubscribedAdminRoute';
import SubscribedUserRoute from './Routing/SubscribedUserRoute';
import UserRoute from './Routing/UserRoute';
import AdminRoute from './Routing/AdminRoute';

import Snackbar from './Snackbar';
import Spinner from './Spinner';

import Login from '../routes/login';
import Signup from '../routes/signup';
import Logout from '../routes/logout';

import LinksCreateWizard from '../routes/links/create/CreateLink';
import LinksEdit from '../routes/links/edit/EditLink';
import LinksDetail from '../routes/links/detail/LinkDetails';
import Links from '../routes/links/LinkListFunction';

import CampaignsCreate from '../routes/campaigns/create/CreateCampaign';
import CampaignsEdit from '../routes/campaigns/edit/EditCampaign';
import CampaignsDetail from '../routes/campaigns/detail/CampaignDetails';
import Campaigns from '../routes/campaigns/CampaignList';

import Analytics from '../routes/analytics/Analytics';

import UserCreate from '../routes/user/create/InviteUsers';
import UserEdit from '../routes/user/edit/EditUser';
import Users from '../routes/user/UserList';

import GroupsCreate from '../routes/groups/create/GroupsCreate';
import GroupsEdit from '../routes/groups/edit/GroupsEdit';
import GroupsList from '../routes/groups/GroupsList';

import PasswordChange from '../routes/password/password_change';
import PasswordSet from '../routes/password/password_set';
import PasswordReset from '../routes/password/password_reset';

import ConfirmEmail from '../routes/email/email_confirm';

import Branding from '../routes/branding/Branding';

import Inactive from '../routes/inactive';
import closedAccount from '../routes/closedAccount';

import Welcome from '../routes/welcome';
import Offline from './Offline';

import { logSessionStatusToConsole } from '../api/common';
import ScrollToTop from './ScrollToTop';
import Domains from '../routes/domains/DomainList';
import AddDomain from '../routes/domains/add';
import DomainDetails from '../routes/domains/details';
import Return from '../routes/checkout/StripeReturn';
import StripeCustomerPortalRedirect from "../routes/payment/StripeCustomerPortalRedirect";
import UnsubscribedAdminRoute from "./Routing/UnsubscribedAdminRoute";
import StripePricingTable from "../routes/checkout/StripePricingTable";

ReactGA.initialize(process.env.REACT_APP_GA_UA, {
    debug: false,
    titleCase: false,
});

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
    constructor(props)
    {
        super(props);
        logSessionStatusToConsole();
    }

    render()
    {
        const {
            snackbarVisible, snackbarText, snackbarType, loading, networkError,
        } = this.props;

        // this shloud only be used if backend is completely offline or unreachable
        // app can't be bootstrapped
        if (networkError && networkError.recoverable === false) {
            return (<Offline message={networkError.message} />);
        }

        return (
            <div id="app" className="lh__layout">
                <div className="content">
                    <BrowserRouter>
                        <ScrollToTop>
                            <Switch>
                                <Route path="/login" component={Login} />
                                <Route path="/signup" component={Signup} />
                                <Route path="/logout" component={Logout} />
                                <Route path="/welcome" component={PasswordSet} />
                                <Route path="/new-password" component={PasswordSet} />
                                <Route path="/reset-password" component={PasswordReset} />
                                <Route path="/closed-account" component={closedAccount} />
                                <Route path="/confirm-email" component={ConfirmEmail} />

                                <SubscribedUserRoute path="/links/create" component={LinksCreateWizard} />
                                <SubscribedUserRoute path="/links/edit/:id" component={LinksEdit} />
                                <SubscribedUserRoute path="/links/detail/:id" component={LinksDetail} />
                                <SubscribedUserRoute path="/links/:id?" component={Links} />

                                <SubscribedUserRoute path="/campaigns/create" component={CampaignsCreate} />
                                <SubscribedUserRoute path="/campaigns/edit/:id" component={CampaignsEdit} />
                                <SubscribedUserRoute path="/campaigns/detail/:id" component={CampaignsDetail} />
                                <SubscribedUserRoute path="/campaigns" component={Campaigns} />

                                <SubscribedUserRoute path="/analytics" component={Analytics} />

                                <SubscribedAdminRoute path="/user/create" component={UserCreate} />
                                <SubscribedAdminRoute path="/user/edit/:id" component={UserEdit} />
                                <SubscribedAdminRoute path="/user" component={Users} />

                                <SubscribedAdminRoute path="/groups/create" component={GroupsCreate} />
                                <SubscribedAdminRoute path="/groups/edit/:id" component={GroupsEdit} />
                                <SubscribedAdminRoute path="/groups" component={GroupsList} />

                                <UnsubscribedAdminRoute path="/checkout" component={StripePricingTable} />

                                <AdminRoute path="/stripe-customer-portal" component={StripeCustomerPortalRedirect} />
                                <AdminRoute path="/branding" component={Branding} />
                                <AdminRoute path="/domains/add" component={AddDomain} />
                                <AdminRoute path="/domains/details/:id/:action" component={DomainDetails} />
                                <AdminRoute path="/domains" component={Domains} />

                                <AdminRoute path="/return" component={Return} />

                                <UserRoute path="/password" component={PasswordChange} />

                                <UserRoute path="/account-inactive" component={Inactive} />

                                <SubscribedUserRoute path="/" component={Welcome} />
                            </Switch>
                        </ScrollToTop>
                    </BrowserRouter>

                    <Snackbar show={snackbarVisible} type={snackbarType}>
                        <span>{snackbarText}</span>
                    </Snackbar>
                    <Spinner show={loading} />
                </div>
            </div>
        );
    }
}
App.defaultProps = {
    networkError: null,
};
App.propTypes = {
    snackbarVisible: PropTypes.bool.isRequired,
    snackbarText: PropTypes.string.isRequired,
    snackbarType: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    networkError: PropTypes.shape({
        recoverable: PropTypes.bool.isRequired,
        message: PropTypes.string,
    }),
};

export default connect(
    'snackbarVisible,snackbarText,snackbarType,loading,networkError',
    null,
)(App);

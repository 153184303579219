import React from 'react';
import style from './style.module.scss';
import Button from '../Button';
import { addDecimalDivider } from './Tooltip';
import BeaconLink from "../BeaconLink";
import Table from '../Table/DynamicTable';


function formatNumber(number) {
    return new Intl.NumberFormat('en-GB').format(number);
}

function Top5Chart(props) {
    const containerStyle = props.containerStyle
    const headline = props.headline
    const history = props.history
    const beaconId = props.beaconId
    const data = props.data
    const title = props.title
    const numberOfItems = props.data.length
    let numberOfClicks = 0

    props.data.forEach(item => {
       numberOfClicks += parseInt(item.hits);
    });

    const columns = React.useMemo(
        () => [
            {
                Header: `${title.slice(0, -1)}`,
                id: 'title',
                accessor: (d) => (
                    <div className={style.listChartEntryBold}>
                        <p>{d.title}</p>
                    </div>
                ),
                style: {
                    width: '100%',
                }
            },
            {
                Header: 'Clicks',
                id: 'clicks',
                accessor: (d) => (
                    <div className={style.listChartEntryBold}>
                        <p>{`${addDecimalDivider(d.hits, ',')} Clicks`}</p>
                    </div>
                ),
                className: 'center',
                style: {
                    width: '4rem',
                    textAlign: 'right',
                    paddingRight: 10,
                }
            },
        ], [title]
    )

    return (
        <div style={containerStyle}>
            <div className={style.listChartWrapper} style={{ display: 'flex', minHeight: '400px' }}>
                <h2 className={style.chartHeadline}>{headline}</h2>
                <div style={{ width: '340px', textAlign: 'center' }}>
                    {data !== null ? (
                        <div>
                            <div style={{ display: 'flex', fontWeight: 'bold', marginTop: '26px' }}>
                                <div style={{
                                    fontSize: '40px', lineHeight: '54px', textAlign: 'right', marginRight: '12px', width: '50%',
                                }}
                                >
                                    {`${formatNumber(numberOfItems)}`}
                                    <br />
                                    {`${formatNumber(numberOfClicks)}`}
                                </div>
                                <div style={{
                                    fontSize: '22px', textAlign: 'left', lineHeight: '54px', width: '50%',
                                }}
                                >
                                    {`${title}`}
                                    <br />
                                    Clicks
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className={style.chart} style={{ flex: 1, marginLeft: '26px' }}>
                    <h3 style={{ marginTop: 0 }}>{beaconId ? (<BeaconLink beaconId={beaconId} label={`Top 5 ${title.toLowerCase()}`} />) : `Top 5 ${title.toLowerCase()}`}</h3>
                    {data !== null ? (
                        <div>
                            <Table
                                data={data}
                                columns={columns}
                                defaultPageSize={5}
                                showPagination={false}
                                nothingFoundText="Sorry, we couldn't find any data yet ..."
                            />
                            {data.length > 0 ? (
                                <Button onClick={() => { history.push(`${title.toLowerCase()}`); }} label={`Show all ${title.toLowerCase()}`} />
                            ) : (
                                <Button onClick={() => { history.push(`${title.toLowerCase()}`); }} label={`Create${numberOfItems > 0 ? '' : ' first'} ${title.toLowerCase().slice(0, -1)}`} />
                            )}
                        </div>
                    ) : <span>loading...</span>}
                </div>
            </div>
        </div>
    );
}

export default Top5Chart;

import {
    logout as logoutAndResetState,
} from '../actions/general';
import { STRIPE, INVOICE } from './constants';
import moment from "moment";

export const bearerToken = () => {
    try {
        const { token } = JSON.parse(localStorage.getItem('token'));
        return token;
    } catch (err) {
        return null;
    }
};

/**
 * Logout
 */
export const logout = () => {
    logoutAndResetState();
};

/**
 * Checks if a user is currently logged in
 *
 * @return {Boolean}
 */
export const isLoggedIn = () => (localStorage.getItem('token') !== null);

/**
 * Check if user is admin
 *
 * @return {Boolean}
 */
export const isAdmin = () => {
    try {
        const userToken = JSON.parse(localStorage.getItem('token'));
        return userToken.data.roles.includes('ROLE_SUPER_ADMIN');
    } catch (err) {
        return false;
    }
};


/**
 * returns role of logged in user
 *
 * @return {Sting}
 */
export const role = () => {
    const r = 'anon';
    if (isAdmin()) {
        return 'admin';
    }
    if (isLoggedIn()) {
        return 'user';
    }
    return r;
};

export const getCurrentUserId = () => {
    const userToken = JSON.parse(localStorage.getItem('token'));
    return userToken.data.id;
};

export const getCurrentUserName = () => {
    const userToken = JSON.parse(localStorage.getItem('token'));
    return userToken.data.name;
};

export const getCurrentUserEmail = () => {
    const userToken = JSON.parse(localStorage.getItem('token'));
    return userToken.data.email;
}

/**
 * Get the users account data.
 *
 * @return {String}
 */
export const getAccount = () => JSON.parse(localStorage.getItem('account'));

export const subscribedUserCount = () => {
    const account = getAccount();
    return account.quantity;
};

/**
 * Get the latest payment ordered by updatedAt DESC.
 */
export const getLatestPayment = () => {
    const account = JSON.parse(localStorage.getItem('account'));
    return account.payments.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
};

const nextBillingDateOrToday = (date) => (new Date(date) > new Date() ? new Date(date) : new Date());

/**
 * Get the next billing date of the latest payment or today if it is in the past.
 */
export const getNextBillingDate = () => {
    const latestPayment = getLatestPayment();
  // INFO: Always use nextBillingDate as it is synchronized with db
    return nextBillingDateOrToday(latestPayment.nextBillingDate);
};

/**
 * Get the next billing date of the latest payment as formatted string.
 */
export const getNextBillingDateFormatted = () => {
    const latestBillingDate = getNextBillingDate();
    return latestBillingDate.toLocaleString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' });
};

/**
 * TODO: Differentiate between canceled and authorization or payment needed
 */
export const isCanceled = () => {
    const account = getAccount();
    const { subscription } = getLatestPayment();
    return subscription
    ? (subscription.status === 'canceled' || subscription.status === 'incomplete' || subscription.status === 'incomplete_expired') || subscription.status === 'CANCELLED'
    : !account.subscribed;
};

/**
 * Check if user is subscribed.
 * The subscribed state is dependent on the accounts subscribed flag and the status of
 * the latest subscription. If the latest subscription is not active and the date of its last
 * payment is not in the near future then the user should be treated as not subscribed.
 *
 * @return {String}
 */
export const isSubscribed = () => {
    try {
        const account = getAccount();

        if (!account || !account.subscribed) {
            return false;
        }

        if (account.trialEndsAt && moment(account.trialEndsAt).isAfter(moment())) {
            return true;
        }

        const latestPayment = getLatestPayment();
        switch (latestPayment.provider) {
            case STRIPE:
            return account.subscribed || latestPayment.subscription.status === 'active' || !(latestPayment.subscription.status !== 'active' && new Date() > new Date(latestPayment.nextBillingDate));
            case INVOICE:
            return account.subscribed;
            default:
            return account.subscribed;
        }
    } catch (err) {
        return false;
    }
};

export const isEnterprise = () => {
    const latestPayment = getLatestPayment();
    return latestPayment.provider === INVOICE;
}

export const hasReachedUserLimit = () => {
    const latestPayment = getLatestPayment();

    const userQuotas = latestPayment.subscription.quotas.users;

    return userQuotas.count >= userQuotas.limit;
}

export const hasReachedLinksLimit = () => {
    const latestPayment = getLatestPayment();

    const linkQuotas = latestPayment.subscription.quotas.links;

    return linkQuotas.count >= linkQuotas.limit;
}

export const hasReachedCampaignsLimit = () => {
    const latestPayment = getLatestPayment();

    const campaignQuotas = latestPayment.subscription.quotas.campaigns;

    return campaignQuotas.count >= campaignQuotas.limit;
}

export const hasReachedCustomDomainsLimit = () => {
    const latestPayment = getLatestPayment();

    const customDomainQuotas = latestPayment.subscription.quotas.custom_domains;

    return customDomainQuotas.count >= customDomainQuotas.limit;
}

export const getAnalyticsStorageDays = () => {
    const latestPayment = getLatestPayment();

    return latestPayment.subscription.quotas.analytics_storage_days;
}
import React from 'react';
import style from './style.module.scss';

const Background = (props) => (
    <div id={props.id} className={style.background}>
        {props.children}
            <p className={`copyright ${style.copyright}`}>
                    Background&nbsp;image&nbsp;by&nbsp;
                    <a href="http://www.melanie-freund.de" target="_blank" rel="noopener noreferrer">Melanie Freund</a>
                    <a href={`${process.env.REACT_APP_WEBSITE_URL}/imprint`} target="_blank"
                       rel="noopener noreferrer">Imprint</a>
                    <a href={`${process.env.REACT_APP_WEBSITE_URL}/cookie-policy`} target="_blank"
                       rel="noopener noreferrer">Cookie Policy</a>
                    <a href={`${process.env.REACT_APP_WEBSITE_URL}/terms-of-service`} target="_blank"
                       rel="noopener noreferrer">Terms of Service</a>
                    <a href="#app" role="button" onClick={() => window.Optanon.ToggleInfoDisplay()}>Cookie Settings</a>
            </p>
    </div>
);

export default Background;

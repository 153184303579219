import React from 'react'
import PropTypes from 'prop-types'

import Creatable from 'react-select/creatable'
import style from './style.module.scss'
import BeaconLink from '../../../BeaconLink'

const CreateableMultiSelectField = (props) => {
    const renamedOptions = props.options.map((el) => ({
        value: el.id,
        label: el.name,
    }))

    const label = props.label || 'Label'
    const { beaconId } = props
    const className = `${style.input_wrapper} width_${props.width} ${
        props.error ? style.error : ''
    }`
    const placeholder = props.placeholder || 'Select'

    return (
        <div
            className={`selectboxWrapper ${className} ${
                props['data-required'] ? 'required' : ''
            }`}
            data-type="multi-select"
            data-required={props['data-required']}
        >
            {label && (
                <label htmlFor={props.name}>
                    {beaconId ? (
                        <BeaconLink beaconId={beaconId} label={label} />
                    ) : (
                        label
                    )}
                </label>
            )}
            <Creatable
                isMulti
                name={props.name}
                value={props.value}
                options={renamedOptions}
                placeholder={placeholder}
                classNamePrefix="selectbox"
                onChange={props.onChange}
                onCreateOption={props.onCreateOption}
                isLoading={props.isLoading}
                isDisabled={props.isDisabled}
            />
            {props.error && (
                <div className={style.error_message}>{props.errorMessage}</div>
            )}
        </div>
    )
}

CreateableMultiSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    BeaconLink: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    onCreateOption: PropTypes.func,
}

export default CreateableMultiSelectField

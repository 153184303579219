import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { CaretDownOutline, CaretUpOutline, ChevronBackOutline, ChevronForwardOutline } from 'react-ionicons'
import { usePagination, useRowSelect, useTable } from "react-table"
import useDebounce from '../../hooks/debounce'
import style from './style.module.scss'

function Nothing({ ...props }) {
    const nothingFoundText = props.nothingFoundText
    return (
        // eslint-disable-next-line react/no-unescaped-entities
        <div className={style.nothingToShow}>{nothingFoundText}</div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})


// Checkbox for Selections
const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <div className={style.checkboxWrapper}>
                <input type="checkbox" ref={resolvedRef} {...rest} />
                <div className={style.checkmark} />
            </div>
        )
    }
)
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

function Table({
    columns,
    data,
    getInstanceCallback,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    ...props }) {
    const showAlerts = props.showAlerts
    const archived = props.archived
    const sortedField = props.sortedField
    const sortDirection = props.sortDirection
    const nothingFoundText = props.nothingFoundText
    const inlinePagination = props.pagination
    const highLightId = props.highLightId

    /** 
     * NOTE: 
     * we want to hide the selection Column on all Tables besides when we do add an export feature from outside via prop.
     */
    const hiddenColumns = useState(['selection'])

    /** 
     * NOTE: 
     * further we do want to use effects on this, and keep track via our custom hook with debounce ... see further note on the effect
     */
    const exportActive = useDebounce(props.export, 100)

    const useInstance = (instance) => {
        if (instance && instance.getInstanceCallback) {
            instance.getInstanceCallback(instance);
        }
    };

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        // will only kick in, if we supply a pageSize prop from outside
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize },
        setHiddenColumns,
    } =
        useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: hiddenColumns
            },
            getInstanceCallback
        },
            usePagination,
            useRowSelect,
            (hooks) => {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps, }) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
                hooks.useInstance.push(useInstance)
            }
        )

    /** 
     * NOTE: 
     * we do handle our prop from outside this functional component here
     * and manipulate the hidden columns
     * 
     * make sure to give in an array here
     * 
     * the false case, can be made dynamically in the future, for now we do only need the selection checkboxes for 
     * LinkList
     */
    useEffect(() => {
        if (exportActive) {
            setHiddenColumns([])
        } else {
            setHiddenColumns(['selection'])
        }
    }, [exportActive, setHiddenColumns, selectedFlatRows])

    useEffect(() => {
        const pageSizeProp = props.pageSize;
        if (pageSizeProp !== undefined) {
            setPageSize(pageSizeProp)
        }
    }, [props.pageSize, pageOptions, pageCount, gotoPage, pageIndex, pageSize, setPageSize])

    // Render the UI for your table
    return (
        <div className={style.fullWidth}>
            {/* <pre>
                <code>
                    {JSON.stringify(
                        {
                            pageIndex,
                            pageCount,
                            canNextPage,
                            canPreviousPage,
                            pageSize
                        },
                        null,
                        1
                    )}
                </code>
            </pre> */}
            {/* {
                // TODO: comment me out!
                exportActive && (
                    <div style={{ fontSize: '10px' }}>
                        {JSON.stringify(
                            {
                                // selectedRowIds: selectedRowIds,
                                selectedFlatRows: selectedFlatRows.map(
                                    d => d.original.id
                                ),
                            },
                            null,
                            2
                        )}
                    </div>
                )} */}
            <table {...getTableProps()} className="DynamicTable">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps([
                                    {
                                        className: column.className ? `${column.className} columnHead` : 'columnHead',
                                        style: column.style,
                                    },
                                    getColumnProps(column),
                                    getHeaderProps(column),
                                ])}>
                                    <span className={`${column.id === sortedField ? `${style.active}` : ''}`}>
                                        {column.id === sortedField
                                            ? (sortDirection === 'desc') ?
                                                <CaretDownOutline color={'currentColor'} /> :
                                                <CaretUpOutline color={'currentColor'} />
                                            : ''}
                                        {column.render('Header')}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {!inlinePagination ?
                        // eslint-disable-next-line no-unused-vars
                        rows.map((row, _i) => {
                            prepareRow(row)
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className={`${!row.original.available && showAlerts ? `${style.danger}` : ''} ${archived ? `${style.dimmed}` : ''} ${highLightId == row.original.id ? `${style.highlight}` : ''}`}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td className={`${style.tableCell}`} {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                        :
                        // eslint-disable-next-line no-unused-vars
                        page.map((row, _i) => {
                            prepareRow(row)
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className={`${!row.original.available && showAlerts ? `${style.danger}` : ''} ${archived ? `${style.dimmed}` : ''}`}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td className={style.tableCell} {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {data.length === 0 ? <Nothing nothingFoundText={nothingFoundText} /> : null}
            {inlinePagination && data.length !== 0 &&
                <div className="Table__pagination">
                    <div className={!canPreviousPage ? 'Table__prevPageWrapper disabled' : 'Table__prevPageWrapper'}>
                        <ChevronBackOutline
                            color={'currentColor'}
                            height="0.875rem"
                            width="0.875rem"
                            onClick={() => previousPage()} />
                    </div>

                    {/* <div className="Table__visiblePagesWrapper">
                        {Array.apply(null, { length: pageCount }).map(Number.call, Number).map(index => {
                            return (
                                <button
                                    key={index}
                                    className={
                                        pageIndex === index
                                            ? 'Table__pageButton Table__pageButton--active'
                                            : 'Table__pageButton'
                                    }
                                    onClick={gotoPage(index)}
                                >
                                </button>
                            );
                        })}
                    </div> */}

                    <div className={!canNextPage ? 'Table__nextPageWrapper disabled' : 'Table__nextPageWrapper'}>
                        <ChevronForwardOutline
                            color={'currentColor'}
                            height="0.875rem"
                            width="0.875rem"
                            onClick={() => nextPage()}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default Table;

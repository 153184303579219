import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import style from './style.module.scss'
import { isSubscribed, isLoggedIn } from '../../util/user-management'
import {
    EllipsisHorizontal,
    LogOutOutline,
    AnalyticsOutline,
    AddOutline,
    MegaphoneOutline,
    LinkOutline,
    LockClosedOutline,
    PeopleOutline,
    GlobeOutline,
    CardOutline,
    ColorPaletteOutline,
    PersonOutline,
    ArrowBackOutline,
} from 'react-ionicons'
import { withRouter } from 'react-router-dom';
import actions from '../../actions'
import { connect } from 'react-unistore'
import { isEnterprise } from "../../util/user-management";

class Header extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            userRole: [],
            isOpened: false,
        }
        this.toggleSettingsNavigation = this.toggleSettingsNavigation.bind(this)
        this.toggleCreateNavigation = this.toggleCreateNavigation.bind(this)
        this.closeCreateNavigation = this.closeCreateNavigation.bind(this)
        this.closeAllNavigations = this.closeAllNavigations.bind(this)
    }

    toggleSettingsNavigation()
    {
        this.setState((prevState) => ({
            isOpened: !prevState.isOpened,
        }))
        this.closeCreateNavigation()
    }

    toggleCreateNavigation()
    {
        this.setState({
            isOpened: false,
        })
        const { toggleCreateNavigation } = this.props
        toggleCreateNavigation()
    }

    closeCreateNavigation()
    {
        const { closeCreateNavigation } = this.props
        closeCreateNavigation()
    }

    closeAllNavigations()
    {
        this.setState(() => ({
            isOpened: false,
        }))
        this.closeCreateNavigation()
    }

    componentDidUpdate(prevProps)
    {
        const currentLink = this.props.location.pathname;
        if (currentLink !== prevProps.activeLink) {
            this.closeAllNavigations();
        }
    }

    render()
    {
        const { activeLink, showNavigation, showCreateNavigation } = this.props
        const isLoggedInAndSubscribed = isLoggedIn() && isSubscribed()

        return (
            showNavigation && (
                <header className={style.header}>
                    <div className={style.mainNavigation}>
                        <Link
                            className={style.logo}
                            to="/"
                            onClick={this.closeAllNavigations}
                        >
                            <img
                                src="/icons/logo-hawk.svg"
                                alt="Linkhawk Logo"
                            />
                        </Link>
                        {isLoggedInAndSubscribed && (
                            <nav className={style.nav_main}>
                                <div
                                    className={`${style.createNavElement} ${
                                        this.state.showCreateNavigation
                                            ? style.createNavElementActive
                                            : ''
                                        }`}
                                >
                                    <AddOutline
                                        color={'currentColor'}
                                        title={'Add'}
                                        className='btn_icon_rounded_pulse'
                                        onClick={this.toggleCreateNavigation}
                                    />
                                </div>
                                <Link
                                    className={
                                        activeLink.includes('links')
                                            ? `${style.active}`
                                            : ''
                                    }
                                    to="/links"
                                >
                                    <LinkOutline
                                        color={'currentColor'}
                                        title={'Links'}
                                    />
                                </Link>
                                <Link
                                    className={
                                        activeLink.includes('campaigns')
                                            ? `${style.active}`
                                            : ''
                                    }
                                    to="/campaigns"
                                >
                                    <MegaphoneOutline
                                        color={'currentColor'}
                                        title={'Campaigns'}
                                    />
                                </Link>
                                <Link
                                    className={
                                        activeLink.includes('analytics')
                                            ? `${style.active}`
                                            : ''
                                    }
                                    to="/analytics"
                                >
                                    <AnalyticsOutline
                                        color={'#00000'}
                                        title={'Analytics'}
                                    />
                                </Link>
                            </nav>
                        )}

                        <nav className={style.adminNavigation}>
                            <div className={style.nav_icon}>
                                <span
                                    className={
                                        this.state.isOpened
                                            ? style.active + ' rotate_90'
                                            : ''
                                    }
                                >
                                    <div
                                        onClick={this.toggleSettingsNavigation}
                                    >
                                        <EllipsisHorizontal
                                            color={'currentColor'}
                                            title={'Settings'}
                                        />
                                    </div>
                                </span>
                            </div>

                            <Link className={style.logout} to="/logout">
                                <LogOutOutline
                                    color={'currentColor'}
                                    title={'Logout'}
                                />
                            </Link>
                        </nav>
                    </div>

                    <nav
                        className={`${style.settingsNavigation} ${
                            this.state.isOpened
                                ? style.settingsNavigationActive
                                : ''
                            }`}
                    >
                        <div
                            className={style.nav_header}
                            onClick={this.toggleSettingsNavigation}
                        >
                            <ArrowBackOutline
                                color={'currentColor'}
                                title={'Back'}
                            />
                            <span>Settings</span>
                        </div>
                        <ul>
                            {showNavigation === 'admin' && isLoggedInAndSubscribed && (
                                <li>
                                    <Link
                                        className={
                                            activeLink.includes('groups')
                                                ? 'active'
                                                : ''
                                        }
                                        to="/groups"
                                    >
                                        <PeopleOutline
                                            color={'currentColor'}
                                            title={'Groups'}
                                        />
                                        <span>Groups</span>
                                    </Link>
                                </li>
                            )}
                            {showNavigation === 'admin' && isLoggedInAndSubscribed && (
                                <li>
                                    <Link
                                        className={
                                            activeLink.includes('user')
                                                ? `${style.active}`
                                                : ''
                                        }
                                        to="/user"
                                    >
                                        <PersonOutline
                                            color={'currentColor'}
                                            title={'Users'}
                                        />
                                        <span>Users</span>
                                    </Link>
                                </li>
                            )}
                            {showNavigation === 'admin' && isLoggedInAndSubscribed && (
                                <li>
                                    <Link
                                        className={
                                            activeLink.includes('branding')
                                                ? `${style.active}`
                                                : ''
                                        }
                                        to="/branding"
                                    >
                                        <ColorPaletteOutline
                                            color={'currentColor'}
                                            title={'Branding'}
                                        />
                                        <span>Branding</span>
                                    </Link>
                                </li>
                            )}
                            {showNavigation === 'admin' && isLoggedInAndSubscribed && !isEnterprise() && (
                                <li>
                                    <Link
                                        className={
                                            activeLink.includes('payment')
                                                ? `${style.active}`
                                                : ''
                                        }
                                        to="/stripe-customer-portal"
                                    >
                                        <CardOutline
                                            color={'currentColor'}
                                            title={'Subscription'}
                                        />
                                        <span>Subscription</span>
                                    </Link>
                                </li>
                            )}
                            {showNavigation === 'admin' && isLoggedInAndSubscribed && (
                                <li>
                                    <Link
                                        className={
                                            activeLink.includes('domains')
                                                ? `${style.active}`
                                                : ''
                                        }
                                        to="/domains"
                                    >
                                        <GlobeOutline
                                            color={'currentColor'}
                                            title={'Domains'}
                                        />
                                        <span>Domains</span>
                                    </Link>
                                </li>
                            )}
                            <li>
                                <Link
                                    className={
                                        activeLink.includes('password')
                                            ? `${style.active}`
                                            : ''
                                    }
                                    to="/password"
                                >
                                    <LockClosedOutline
                                        color={'currentColor'}
                                        title={'Change password'}
                                    />
                                    <span>Change password</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    {showCreateNavigation && (
                        <nav
                        className={`${style.createNavigation} ${
                            showCreateNavigation
                                ? style.createNavigationActive
                                : ''
                            }`}
                    >
                        <div
                            className={style.nav_header}
                            onClick={this.toggleCreateNavigation}
                        >
                            <ArrowBackOutline
                                color={'currentColor'}
                                title={'Back'}
                            />
                            <span>Create a ...</span>
                        </div>
                        <ul>
                            <li>
                                <Link
                                    className={
                                        activeLink.includes('links/create')
                                            ? `${style.active}`
                                            : ''
                                    }
                                    to="/links/create"
                                    onClick={this.closeAllNavigations}
                                >
                                    <LinkOutline
                                        color={'currentColor'}
                                        title={'Links'}
                                    />
                                    <span>Link</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        activeLink.includes('campaigns/create')
                                            ? `${style.active}`
                                            : ''
                                    }
                                    to="/campaigns/create"
                                    onClick={this.closeAllNavigations}
                                >
                                    <MegaphoneOutline
                                        color={'currentColor'}
                                        title={'Campaign'}
                                    />
                                    <span>Campaign</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        activeLink.includes('domains/add')
                                            ? `${style.active}`
                                            : ''
                                    }
                                    to="/domains/add"
                                    onClick={this.closeAllNavigations}
                                >
                                    <GlobeOutline
                                        color={'currentColor'}
                                        title={'Domain'}
                                    />
                                    <span>Domain</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        activeLink.includes('groups/create')
                                            ? `${style.active}`
                                            : ''
                                    }
                                    to="/groups/create"
                                    onClick={this.closeAllNavigations}
                                >
                                    <PeopleOutline
                                        color={'currentColor'}
                                        title={'Group'}
                                    />
                                    <span>Group</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    )}
                </header>
            )
        )
    }
}

Header.propTypes = {
    toggleCreateNavigation: PropTypes.func.isRequired,
    closeCreateNavigation: PropTypes.func.isRequired,
}

export default withRouter(connect(["showCreateNavigation"], actions)(Header));

import moment from 'moment-timezone'
import { get, post } from './common'

export const updateLink = async (id, payload) =>
    post(`/api/links/${id}`, 'POST', payload)

export const archiveLink = async (id) =>
    get(`/api/links/${id}/archive`)

export const unArchiveLink = async (id) =>
    get(`/api/links/${id}/unarchive`)

export const favLink = async (id) =>
    get(`/api/links/${id}/favorite`)

export const unFavLink = async (id) =>
    get(`/api/links/${id}/unfavorite`)

export const getLinkAlerts = async () =>
    get(`/api/links/alerts`)

export const getLinks = async (id, dates = null) => {
    const dateFilter =
        dates != null
            ? {
                startDate: dates.dateStart,
                endDate: dates.dateEnd,
            }
            : {}

    return get(`/api/links/${id}`, {
        ...dateFilter,
        timezone: moment.tz.guess(),
    })
}

export const getLinkAnalytics = async (
    id,
    dates = null,
    stats = null,
    region = null
) => {
    const filter = {
        startDate: dates.dateStart,
        endDate: dates.dateEnd,
        include:
            stats ||
            'hits_by_day,hits_totals,hits_by_hour_of_day,hits_by_referrer',
        timezone: moment.tz.guess(),
        region,
    }

    return get(`/api/links/${id}/analytics`, filter)
}

import React, { Component } from 'react'

import Input from './components/Input'
import Checkbox from './components/Checkbox'
import SelectField from './components/SelectField'
import FileUploader from './components/FileUploader'
import FontPicker from './components/FontPicker'
import ColorPicker from './components/ColorPicker'
import Dropdown from './components/Dropdown'
import MultiSelectField from './components/MultiSelectField'
import CreateableMultiSelectField from './components/CreateableMultiSelectField'
import AutocompleteInput from './components/AutocompleteInput'
import style from './style.module.scss'
import { clearErrors } from '../../util/validates'
import Textarea from './components/Textarea/Textarea'

class Form extends Component {
    componentDidMount() {
        clearErrors()
    }

    render() {
        return (
            <div style={this.props.styles}>
                <form id={this.props.id} className={style.form}>
                    <div className={style.formInputs} style={this.props.styles}>
                        {this.props.children}
                    </div>
                </form>
            </div>
        )
    }
}

Form.AutocompleteInput = AutocompleteInput
Form.Input = Input
Form.SelectField = SelectField
Form.FileUploader = FileUploader
Form.FontPicker = FontPicker
Form.ColorPicker = ColorPicker
Form.Dropdown = Dropdown
Form.MultiSelectField = MultiSelectField
Form.CreateableMultiSelectField = CreateableMultiSelectField
Form.Checkbox = Checkbox
Form.Textarea = Textarea

export default Form

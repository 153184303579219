// export const Tooltip = (props) => (
//   <div className="tooltip-content">
//     <div className="tooltip-label">{props.date}</div>
//     <div className="tooltip-value">{props.value}</div>
//     <div className="tooltip-caret" />
//   </div>
// );

export const customTooltips = (tooltip, id) => {
    // , unit
    // tooltip will be false if tooltip is not visible or should be hidden
    if (!tooltip) { return; }

    // Otherwise, tooltip will be an object with all tooltip properties like:
    // tooltip.backgroundColor = '#000';
    tooltip.mode = 'index';
    tooltip.intersect = true;
    tooltip.yPadding = 10;
    tooltip.xPadding = 10;
    // tooltip.bodyFontColor = '#FFF';
    tooltip.borderWidth = 0.05;
    tooltip.cornerRadius = 0;
    tooltip.displayColors = false;

    // Tooltip Element
    const tooltipEl = document.getElementById(id);
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    let date = null;
    if (tooltip.title) {
        date = tooltip.title[0];
    }

    // Set custom tooltip
    if (tooltip.body) {
        // const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body[0].lines;
        const tooltipData = bodyLines[0];
        // Custom tooltip requires an id for proper positioning

        // Create inner html
        const tooltipTitle = `${tooltipData.xLabel ? tooltipData.xLabel : tooltipData.match(':') != null ? tooltipData.split(':')[0] : ''}`;
        const tooltipValue = tooltipData.value !== false && tooltipData.xLabel ? `${tooltipData.value}${tooltipData.percent ? '%' : ''}` : typeof tooltipData === 'string' ? tooltipData.split(':')[1] : '';

        let color = 'gray';
        switch (tooltipTitle) {
        case 'Links':
            color = '#318692';
            break;
        case 'Campaigns':
            color = '#3bd5c3';
            break;
        default:
            color = '#318692';
        }

        tooltipEl.style.backgroundColor = color;
        tooltipEl.innerHTML = '<div class="tooltip-content">';
        tooltipEl.innerHTML += `<div class="tooltip-label">${tooltipTitle}</div>`;
        tooltipEl.innerHTML += `<div class="tooltip-value">${addDecimalDivider(tooltipValue, ',')}</div>`;
        if (date) {
            tooltipEl.innerHTML += `<div class="tooltip-label">${date}</div>`;
        }
        tooltipEl.innerHTML += `<div style="border-color: ${color} transparent transparent transparent" class="tooltip-caret"></div>`;
        tooltipEl.innerHTML += '</div';

        // Set inner html to tooltip
        const chartElement = tooltipEl.parentNode.getBoundingClientRect();
        // Calculate position
        const positionY = chartElement.top + tooltip.yPadding + (tooltipEl.getBoundingClientRect().height / 2)- 20;
        const positionX = chartElement.left - tooltip.xPadding + (tooltipEl.getBoundingClientRect().width / 2);
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = `${positionX + tooltip.caretX + 1.5}px`;
        tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
    }
};

export const addDecimalDivider = (number, divider) => {
    number += '';
    const x = number.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? `.${x[1]}` : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1${divider}$2`);
    }
    return x1 + x2;
};

import React from 'react';
import PropTypes from 'prop-types';
import style from "../../../routes/campaigns/style.module.scss";
import {Search} from "react-ionicons";

const SearchBar = ({ placeholder, onSearch }) => {
    const handleInputChange = (event) => {
        onSearch(event.target.value);
    };

    return (
        <div style={{marginRight: '1rem'}}>
            <div className={style.inputWithIcon}>
                <Search
                    className={style.inputIcon}
                    color={'currentColor'}
                    title={'Search'}
                />
                <input
                    type="text"
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    autoComplete="off"
                />
            </div>
        </div>
    );
};

SearchBar.propTypes = {
    placeholder: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
    placeholder: 'Search...',
};

export default SearchBar;

import moment from 'moment-timezone'
import { get } from './common'

export const getAnalytics = async (
    dates = null,
    stats = null,
    region = null
) => {
    const filter = {
        startDate: dates.dateStart,
        endDate: dates.dateEnd,
        include:
            stats ||
            'hits_by_day,hits_totals,hits_by_hour_of_day,hits_by_referrer',
        timezone: moment.tz.guess(),
        region,
    }

    return get('/api/analytics/overview', filter)
}
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import style from './style.module.scss'

import { Star, StarOutline } from 'react-ionicons'
import ReactTooltip from 'react-tooltip'
import { useStore } from "react-unistore"
import { toggleSnackbar } from '../../actions/general'
import { favLink, unFavLink } from '../../api/links'

function FavoriteToggle({ changeFavoriteState, ...props }) {
    const store = useStore()
    const state = useState()
    const id = props.id
    const fetched = props.fetched
    const bookmarked = props.favorite
    const isArchive = props.isArchive

    const onClickFavLink = (id, isFavorite) => {
        if (isFavorite) {
            unFavLink(id).then(() => {
                ReactGA.event({
                    category: 'Link',
                    action: 'Favorite',
                    label: 'Unbookmarked Link',
                })
                changeFavoriteState(false)
                // console.info('Unbookmarked', id, isFavorite)
                toggleSnackbar(
                    store,
                    state,
                    'Link successfully removed from favorites'
                )
            })
        } else {
            favLink(id).then(() => {
                ReactGA.event({
                    category: 'Link',
                    action: 'Favorite',
                    label: 'Bookmarked Link',
                })
                changeFavoriteState(true)
                // console.info('Bookmarked', id, isFavorite)
                toggleSnackbar(
                    store,
                    state,
                    'Link successfully added to favorites'
                )
            })
        }
    }

    useEffect(() => {
        //
    }, [])

    return (
        <div>
            {!(bookmarked === undefined) && (
                <>
                    {(bookmarked) ? (
                        <span className={style.bookmarkIcon} data-tip="Remove from favorites" data-for="bookmark">
                            <Star
                                color="currentColor"
                                onClick={() => fetched && !isArchive ?
                                    onClickFavLink(id, bookmarked)
                                    :
                                    toggleSnackbar(
                                        store,
                                        state,
                                        'Sorry, not able to unset favorite'
                                    )}
                            />
                        </span>
                    ) : (
                        <span className={style.bookmarkIcon} data-tip="Add to favorites" data-for="bookmark">
                            <StarOutline
                                color="currentColor"
                                onClick={() => fetched && !isArchive ? 
                                    onClickFavLink(id, bookmarked)
                                    :
                                    toggleSnackbar(
                                        store,
                                        state,
                                        'Sorry, not able to set favorite'
                                    )}
                            />
                        </span>
                    )}
                    <ReactTooltip place="right" effect="solid" id="bookmark" className="customTooltip" />
                </>

            )}
        </div>
    )
}
export default FavoriteToggle

import React from 'react';
import PropTypes from 'prop-types';
import { ChevronBackOutline, ChevronForwardOutline } from 'react-ionicons';

const defaultButton = props => <button {...props}>{props.children}</button>;

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.changePage = this.changePage.bind(this);

        this.state = {
            visiblePages: this.getVisiblePages(null, props.pages)
        };
    }

    componentDidUpdate(nextProps) {
        if (this.props.pages !== nextProps.pages) {
            this.setState({
                visiblePages: this.getVisiblePages(null, nextProps.pages)
            });
        }
        // TODO: FIX FOR LINKBOARDS ... state triggered to often
        // this.changePage(nextProps.page + 1);
    }

    filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter(page => page <= totalPages);
    };

    getVisiblePages = (page, total) => {
        if (total < 7) {
            return this.filterPages([1, 2, 3, 4, 5, 6], total);
        } else {
            if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                return [1, page - 1, page, page + 1, total];
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                return [1, total - 3, total - 2, total - 1, total];
            } else {
                return [1, 2, 3, 4, 5, total];
            }
        }
    };

    changePage(page) {
        const activePage = this.props.page + 1;

        if (page === activePage) {
            return;
        }

        const visiblePages = this.getVisiblePages(page, this.props.pages);

        this.setState({
            visiblePages: this.filterPages(visiblePages, this.props.pages)
        });

        this.props.onPageChange(page - 1);
    }

    render() {
        const { PageButtonComponent = defaultButton } = this.props;
        const { visiblePages } = this.state;
        const activePage = this.props.page + 1;

        return (
            <div className="Table__pagination">
                <div className={activePage === 1 ? 'Table__prevPageWrapper disabled' : 'Table__prevPageWrapper'}>
                    <ChevronBackOutline
                        color={'currentColor'}
                        height="0.875rem"
                        width="0.875rem"
                        onClick={() => {
                            if (activePage === 1) return;
                            this.changePage(activePage - 1);
                        }}
                    />
                </div>
                <div className="Table__visiblePagesWrapper">
                    {visiblePages.map((page, index, array) => {
                        return (
                            <PageButtonComponent
                                key={page}
                                className={
                                    activePage === page
                                        ? 'Table__pageButton Table__pageButton--active'
                                        : 'Table__pageButton'
                                }
                                onClick={this.changePage.bind(null, page)}
                            >
                                {array[index - 1] + 2 < page
                                    ? <span className='dots'>{page}</span>
                                    : page}
                            </PageButtonComponent>
                        );
                    })}
                </div>
                <div className={activePage === this.props.pages ? 'Table__nextPageWrapper disabled' : 'Table__nextPageWrapper'}>
                    <ChevronForwardOutline
                        color={'currentColor'}
                        height="0.875rem"
                        width="0.875rem"
                        onClick={() => {
                            if (activePage === this.props.pages) return;
                            this.changePage(activePage + 1);
                        }}
                    />
                </div>
            </div>
        );
    }
}

Pagination.propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
};

export default Pagination;
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import style from './style.module.scss';
import Content from '../../components/Content';
import { getCurrentUserName } from '../../util/user-management';

// eslint-disable-next-line react/prefer-stateless-function
class Inactive extends Component {
    componentDidMount()
    {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Account Inactive',
        });
    }

    render()
    {
        return (
        <section id={style.accountInactive}>
            <Content small center>
            <h1>
                Hello&nbsp;
                {getCurrentUserName()}
                :
            </h1>
            <p>
                We are sorry, but your email has not been verified or this account has been deactivated.
                Please contact the administrator if you have any questions.
            </p>
            <p>
                Feel free to open an account of your own by signing up <a href="/signup">here</a>.
            </p>
            <p>We value your participation. Please let us know if we can assist you further.</p>
            <p>Thank you for using Linkhawk.</p>
            <p>
                Sincerely,
                <br />
                The Linkhawk Team
            </p>

            </Content>
        </section>
        );
    }
}

export default Inactive;

export const fontOptions = [
  { name: 'Acme' },
  { name: 'Alice' },
  { name: 'Barlow' },
  { name: 'Dancing Script' },
  { name: 'Do Hyeon' },
  { name: 'Fondamento' },
  { name: 'Handlee' },
  { name: 'Indie Flower' },
  { name: 'Inter' },
  { name: 'Itim' },
  { name: 'Leckerli One' },
  { name: 'Lora' },
  { name: 'Mali' },
  { name: 'Merriweather' },
  { name: 'Neucha' },
  { name: 'Noto Serif' },
  { name: 'Nunito' },
  { name: 'Open Sans' },
  { name: 'Open Sans Condensed' },
  { name: 'Oswald' },
  { name: 'PT Serif' },
  { name: 'Pacifico' },
  { name: 'Playfair Display' },
  { name: 'Roboto' },
  { name: 'Roboto Mono' },
  { name: 'Rowdies' },
  { name: 'Sora' },
  { name: 'Ubuntu' },
  { name: 'Zilla Slab' },
];

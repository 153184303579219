import moment from 'moment-timezone'
import { get, post } from '../api/common'
import { getLinks as getLink } from '../api/links'

export const getLinkList = async(store, state, page, limit, query = null, archive, sortedField, sortDirection, favorite, alertFilter) => {
    store.setState({ loading: true })

    const searchQuery = query !== null ? `&search=${query}` : ''

    const archivedParam = archive? 1: 0

    const favoriteParam = favorite? '&favorite=1' : ''

    const alertParam = alertFilter? '&alert=1' : ''

    const sort = sortedField !== null ? `&sort=${sortedField}` : ''
    const sortASC = sortDirection === 'desc'? 'desc': 'asc'

    const res = await get(
        `/api/links?page=${page}&limit=${limit}${searchQuery}${sort}&timezone=${moment.tz.guess()}&archived=${archivedParam}${favoriteParam}${alertParam}&sortDirection=${sortASC}`,
        null,
        false
    )
    const total = res.headers.get('X-Total-Count')
    const items = await res.json()
    // console.log(items, (Math.ceil(total / limit) < 2) ? 0 : page, Math.ceil(total / limit), 'ITEMS')
 
    return {
        links: {
            ...state.links,
            items,
            currentPage: (Math.ceil(total / limit) < 2) ? 0 : page, // we want to reset the currentPage back to 0 incase we paginated
            pages: Math.ceil(total / limit),
            fetched: true,
        },
        loading: false,
    }
}

export const createLink = async(store, state, payload) => {
    const json = await post('/api/links', 'POST', payload)

    const { id } = json
    const link = await getLink(id)

    store.setState({
        link,
        error: false,
    })
}

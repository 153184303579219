import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { downloadCsv, downloadCsvFromAllLinksFiltered, downloadCsvFromSelectedLinks } from '../../api/data-management';
import style from './style.module.scss';
import { DownloadOutline } from 'react-ionicons';

class CSVDownloadButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            idDownloadingCSV: false,
        };
    }

    render() {
        const {
            dateEnd, dateStart, id, type, ids, archived, search
        } = this.props;
        const { idDownloadingCSV } = this.state;

        return (
            <button
                type="button"
                disabled={idDownloadingCSV}
                className={style.csv}
                onClick={async (e) => {
                    e.preventDefault();
                    this.setState({ idDownloadingCSV: true });
                    try {
                        const name = type.charAt(0).toUpperCase() + type.slice(1, -1);
                        ReactGA.event({
                            category: name,
                            action: 'Download',
                            label: `CSV-Export ${name}`,
                        });
                        if (type === 'linklist') {
                            if (ids.length) {
                                await downloadCsvFromSelectedLinks({ dateStart, dateEnd }, ids)
                            } else {
                                await downloadCsvFromAllLinksFiltered({ dateStart, dateEnd }, search, archived)
                            }
                        } else {
                            // StandardExport single
                            await downloadCsv(type, id, { dateStart, dateEnd });
                        }
                    } catch (err) {
                        // eslint-disable-next-line no-alert
                        alert('Something went wrong.');
                    }
                    this.setState({ idDownloadingCSV: false });
                }}
            >
                <span>Download CSV</span>
                <DownloadOutline
                    color={'#222'}
                    title={'Download CSV'}
                    width="1.5rem"
                    height="1.5rem"
                    className="icon" />
            </button>
        );
    }
}

CSVDownloadButton.propTypes = {
    id: PropTypes.number, // not required anymore
    ids: PropTypes.array, // not required anymore
    dateStart: PropTypes.string.isRequired,
    dateEnd: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['linklist', 'links', 'campaigns']).isRequired,
};

export default CSVDownloadButton;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from '../style.module.scss';

export default class Filter extends Component {
  constructor(props) {
    super(props);
    const { showsOverlay } = this.props;
    this.state = {
      showsOverlay,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { onClick } = this.props;
    const { showsOverlay } = this.state;
    onClick();
    this.setState({ showsOverlay: !showsOverlay });
  }

  render() {
    const { id, label, showsOverlay } = this.props;
    return (
      <div
        id={id || ''}
        className={style.filter}
        onClick={this.handleClick}

      >
        <span style={{ }}>{label}</span>
        <img
          alt="arrow down"
          src="/icons/arrow-down.svg"
          className={style.dropdownIcon}
          style={
            showsOverlay
              ? {
                transform: 'rotate(180deg)',
              }
              : null
          }
        />
      </div>
    );
  }
}

Filter.propTypes = {
  onClick: PropTypes.func.isRequired,
  showsOverlay: PropTypes.bool.isRequired,
};

import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga';
import {Link} from 'react-router-dom';
import style from './style.module.scss';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Background from '../../components/Background';
import {signup} from '../../api/common';
import {connect} from "react-unistore";
import actions from "../../actions";
import ResendEmailConfirm from "../../components/ResendEmailConfirm";

const Signup = (props) => {
    const [state, setState] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        successfulSignup: false,
    });

    useEffect(() => {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Login',
        });
    }, []);

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    }

    const submitSignup = async (e) => {
        e.preventDefault();

        let error = false;
        const requiredFields = Array.from(document.querySelectorAll('[data-required]'));
        const data = {
            username: state.username,
            password: state.password,
            confirmPassword: state.confirmPassword,
            email: state.email,
        };

        if (data.password !== data.confirmPassword) {
            props.toggleSnackbar('Passwords do not match.');
            return;
        }

        const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if (!passwordRegex.test(data.password)) {
            props.toggleSnackbar('Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(data.email)) {
            props.toggleSnackbar('Please enter a valid email address.');
            return;
        }

        requiredFields.map((formItem) => {
            if (formItem.value === '') {
                formItem.classList.add('error');
                error = true;
            } else {
                formItem.classList.remove('error');
            }
        });

        if (error) {
            props.toggleSnackbar('Please fill in all required fields.');
            return;
        }

        props.toggleLoading(true);
        const res = await signup(JSON.stringify(data));

        if (res.status !== 201) {
            props.toggleSnackbar('An error occurred. Please try again.');
            setState({...state, password: '', confirmPassword: ''});
            return;
        }

        props.toggleLoading(false);

        setState({...state, successfulSignup: true});
    }

    const successfulSignup = state.successfulSignup;
    let signupForm;
    if (successfulSignup) {
        signupForm = <div className={style.success}>
            <h2>Success!</h2>
            <p>
                We sent you an email (<b>{state.email}</b>) with a confirmation link. Please click on the link to
                activate your account.
            </p>
            <p>
                If you dont receive an email within a few minutes, please check your spam folder.
            </p>
            <div>
                <ResendEmailConfirm email={state.email} />
            </div>
        </div>;
    } else {
        signupForm = (
            <div>
                <div className={style.header}>
                    <h2>Create Account</h2>
                    <p>Sign up to start using Linkhawk</p>
                </div>
                <Form>
                    <Form.AutocompleteInput
                        type="text"
                        width={100}
                        name="username"
                        placeholder="Username"
                        data-required
                        value={state.username}
                        onChange={handleChange}
                    />
                    <Form.AutocompleteInput
                        type="text"
                        width={100}
                        name="email"
                        placeholder="Email address"
                        data-required
                        value={state.email}
                        onChange={handleChange}
                    />
                    <Form.AutocompleteInput
                        type="password"
                        width={100}
                        name="password"
                        placeholder="Password"
                        data-required
                        value={state.password}
                        onChange={handleChange}
                    />
                    <Form.AutocompleteInput
                        type="password"
                        width={100}
                        name="confirmPassword"
                        placeholder="Confirm password"
                        data-required
                        value={state.confirmPassword}
                        onChange={handleChange}
                    />
                    <Link to="/login">Already have an account?</Link>
                    <Button label="Sign Up" onClick={submitSignup}/>
                </Form>
                <div className={style.terms}>
                    By proceeding, you agree to our <a href={`${process.env.REACT_APP_WEBSITE_URL}/terms-of-service`} target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a
                    href={`${process.env.REACT_APP_WEBSITE_URL}/cookie-policy`} target="_blank"
                    rel="noopener noreferrer">Privacy Policy</a>. You will also receive marketing emails from us, which you can opt out of anytime.
                </div>
            </div>
        );
    }

    return (
        <Background id="background">
            <a href={`${process.env.REACT_APP_WEBSITE_URL}`} target="_blank" rel="noopener noreferrer"
               className={style.logo_header}>
                <img className={style.logo} src="/icons/linkhawk-logo.png" alt="Linkhawk Website"/>
            </a>
            <div id={style.signup}>
                <div className={style.logo_wrapper}>
                    <img className={style.logo} src="/icons/logo.svg" alt="Linkhawk"/>
                </div>
                {signupForm}
            </div>
        </Background>
    );
}

export default connect(null, actions)(Signup);

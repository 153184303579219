import React, { Component } from 'react';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import style from './style.module.scss';

export default class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasImage: false,
    };

    this.uppy = Uppy({
      restrictions: {
        autoProceed: false,
        maxFileSize: this.props.maxFileSize ? this.props.maxFileSize : 1048576,
        maxNumberOfFiles: this.props.maxNumberOfFiles ? this.props.maxNumberOfFiles : 1,
        allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg'],
        hideUploadButton: true,
        hideCancelButton: true,
        showSelectedFiles: false,
      },
      locale: {
        strings: {
          exceedsSize2: 'This file exceeds max. allowed size of %{size}',
        },
      },
    });

    this.uppy.on('file-added', (file) => {
      this.props.onChange(file);

      this.setState({ hasImage: true });
    });

    this.uppy.on('file-removed', (file, reason) => {
      if (reason === 'removed-by-user') {
        this.props.handleImageRemove();
      }

      this.setState({ hasImage: false });
    });

    this.setImagePreview = this.setImagePreview.bind(this);
  }

  componentDidMount() {
    if ((this.props.maxNumberOfFiles === 1 || !this.props.maxNumberOfFiles)) {
      document.getElementById(this.props.id)
        .classList
        .add('singleUpload');
    }

    if (this.props.file) {
      this.setImagePreview(this.props.file);
    }
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  setImagePreview(file) {
    if (file) {
      return fetch(file, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
      })
        .then((response) => response.blob()) // returns a Blob
        .then((blob) => {
          this.uppy.addFile({
            name: this.props.imageName,
            type: blob.type,
            data: blob, // changed blob -> data
          });
        });
    }
  }

  render() {
    const { label, error, errorMessage } = this.props;
    const dataText = this.props.data ? this.props.data : 'Max. file size 1 MB';
    const height = this.props.height ? this.props.height : 100;
    const className = `${style.input_wrapper} width_${this.props.width} ${this.props.isDisabled ? 'isDisabled' : ''}`;

    return (
      <div className={className} id={this.props.id}>
        {label && <label>{label}</label>}
        <div data-required={this.props.isRequired} data-image={this.state.hasImage} data-type="file">
          <Dashboard uppy={this.uppy} height={height} />
          <p className={`${style.infoText} infoMessage`}>{dataText}</p>
          {error === true ? (<div className={style.error_message}>{errorMessage}</div>) : null}
        </div>
      </div>
    );
  }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import PopUp from './index';
import ReactGA from 'react-ga';
import PopUpStyle from './popup.module.scss';
import Form from "../Form";
import Button from "../Button";
import {deleteUser} from "../../api/users";
import {fetchAccount} from "../../api/account";

const ConfirmDeleteUserPopup =
    ({
        id,
        toggleLoading,
        refreshAdminData,
        toggleSnackbar,
        togglePopup,
    }) => {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const history = useHistory();

    const confirmCheckout = async () => {
        if (inputValue === 'delete') {
            setError(false);
            toggleLoading(true);
            try {
                ReactGA.event({
                    category: 'User',
                    action: 'Delete',
                    label: 'Confirmed',
                });
                await deleteUser(id);
                await fetchAccount();
                await refreshAdminData();
                toggleLoading(false);
                togglePopup();
                history.push('/user');
            } catch (err) {
                toggleLoading(false);
                toggleSnackbar(err.message);
            }
        } else {
            setError(true);
        }
    };

    return (
        <PopUp onClose={togglePopup}>
            <div className={PopUpStyle.content_wrapper}>
                <h2>
                    Do you really want to delete <br />
                    this user?
                </h2>

                <Form>
                    <p style={{margin: '0 auto 1rem auto'}}>Enter the word „delete“ below to delete the user permanently.</p>
                    <Form.Input
                        containerStyle={{
                            width: '450px',
                            margin: '0 auto 1rem auto',
                        }}
                        placeholder={'Enter "delete"'}
                        name='delete'
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        autoComplete='off'
                        className={error ? 'error' : ''}
                    />

                    <div className={PopUpStyle.button_wrapper}>
                        <Button
                            secondary
                            label='Cancel'
                            onClick={togglePopup}
                            type='reset'
                        />
                        <Button
                            label='Delete User now'
                            onClick={confirmCheckout}
                            type="button"
                        />
                    </div>
                </Form>
            </div>
        </PopUp>
    );
};

ConfirmDeleteUserPopup.propTypes = {
    id: PropTypes.string.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    refreshAdminData: PropTypes.func.isRequired,
    toggleSnackbar: PropTypes.func.isRequired,
    togglePopup: PropTypes.func.isRequired,
};

export default ConfirmDeleteUserPopup;

import React from 'react';
import style from './style.module.scss';
import { addDecimalDivider } from './Tooltip';
import BeaconLink from "../BeaconLink";
import Table from '../../components/Table/DynamicTable'

function ListChart(props) {
    const beaconId = props.beaconId
    const containerStyle = props.containerStyle
    const headline = props.headline

    const data = props.data

    const columns = React.useMemo(
        () => [
            {
                Header: 'Source',
                accessor: 'source',
                className: 'listChartEntry',
                Cell: ({row}) => (

                    <div className={style.listChartEntry}>
                        <p>{row.original[0] !== '' ? row.original[0] : 'direct'}</p>
                    </div>
                ),
            }, {
                Header: 'Clicks',
                accessor: 'clicks',
                className: 'end listChartEntryBold',
                style: {
                    fontWeight: '900',
                },
                Cell: ({row}) => (
                    <div className={style.listChartEntryBold}>
                        <p>{`${addDecimalDivider(row.original[1], ',')} Views`}</p>
                    </div>
                ),
            },
        ], []
    )

    return (
        <div className={style.listChartWrapper} style={containerStyle}>
            <h2 className={style.chartHeadline}>{beaconId ? (<BeaconLink beaconId={beaconId} label={headline} />) : headline}</h2>
            <div className={style.chart}>
                {data !== undefined ? (
                    <Table
                        columns={columns}
                        data={Object.entries(data)}
                        nothingFoundText="Sorry, we couldn't find any data yet ..."
                    />
                ) : <span>loading...</span>}
            </div>
        </div>
    );
}

export default ListChart;
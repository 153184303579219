import React, { Component } from 'react'
import ReactGA from 'react-ga'
import moment from 'moment'
import { getAnalytics } from '../../api/analytics'
import AnalyticsMetrics from "../../components/Analytics";
import Content from "../../components/Content";
import {AnalyticsOutline} from "react-ionicons";
import FilterBar from "../../components/FilterBar";
import DateFilter from "../../components/FilterBar/DateFilter";
import Spinner from "../../components/Spinner";

export default class Analytics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateStart: moment()
                .startOf('day')
                .add(12, 'hours')
                .subtract(14, 'days')
                .format('YYYY-MM-DD'),
            dateEnd: moment()
                .startOf('day')
                .subtract(12, 'hours')
                .format('YYYY-MM-DD'),
            selectedRange: '14d',
            analytics: null,
            isFetchingData: true,
        }

        this.updateDate = this.updateDate.bind(this)
    }

    async componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Analytics',
        })
        const { dateStart, dateEnd } = this.state

        const analytics = await getAnalytics(
            { dateStart, dateEnd },
            'hits_by_day,hits_totals,hits_by_hour_of_day,top_5_links,top_5_campaigns,hits_by_referrer'
        )

        this.setState({
            analytics,
            isFetchingData: false,
        })
    }

    async updateDate({ from, to }) {
        const dateStart = moment(from).format('YYYY-MM-DD')
        const dateEnd = moment(to).format('YYYY-MM-DD')
        this.setState({ isFetchingData: true, dateStart, dateEnd })
        const analytics = await getAnalytics(
            { dateStart, dateEnd },
            'hits_by_day,hits_totals,hits_by_hour_of_day,top_5_links,top_5_campaigns,hits_by_referrer'
        )
        this.setState({
            analytics,
            dateStart,
            dateEnd,
            isFetchingData: false,
        })
    }

    render() {
        const {
            dateStart,
            dateEnd,
            selectedRange,
            isFetchingData,
        } = this.state

        return (
            <div>
                <Content wide headerGrid withFilter noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-default">
                            <AnalyticsOutline
                                color={'currentColor'}
                                title={'Analytics'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            Analytics
                        </h1>
                    </div>
                    <div>
                        <FilterBar>
                            <DateFilter
                                selectedRange={selectedRange}
                                dateStart={dateStart}
                                dateEnd={dateEnd}
                                callback={(dates) => this.updateDate(dates)}
                            />
                        </FilterBar>
                    </div>
                </Content>

                <Content>
                    <AnalyticsMetrics
                        analytics={this.state.analytics}
                        history={this.props.history}
                        dateStart={this.state.dateStart}
                        dateEnd={this.state.dateEnd}
                    />
                    <Spinner show={isFetchingData} />
                </Content>
            </div>
        )
    }
}

Analytics.propTypes = {}

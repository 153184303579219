import React, { Component } from 'react'
import { connect } from 'react-unistore'
import ReactGA from 'react-ga'
import Form from '../../../../components/Form'
import Button from '../../../../components/Button'
import actions from '../../../../actions'
import Content from '../../../../components/Content'
import { validateRequiredFields } from '../../../../util/validates'
import { convertToSelectValue } from '../../../../util/helperFunctions'
import Spinner from '../../../../components/Spinner'
import { updateLink, getLinks } from '../../../../api/links'
import ParagraphRequiredField from '../../../../components/ParagraphRequiredField'
import { LinkOutline } from 'react-ionicons'

class LinksEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            link: null,
            tags: [],
            selectedTags: [],
            tagsLoading: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleTagsChange = this.handleTagsChange.bind(this)
        this.handleCreateTag = this.handleCreateTag.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
        const { id, usergroups, tags } = this.props

        const link = await getLinks(id)

        // usergroup needs to be converted because api returns it as string
        const usergroup = usergroups.filter(
            (item) => item.name === link.usergroup
        )[0].id
        // convert tags of link to ids
        const tagIds = link.tags.map((tag) => tag.id)
        const selectedTags = tags
            .filter((item) => tagIds.includes(item.id))
            .reduce((acc, item) => [...acc, convertToSelectValue(item)], [])

        this.setState({
            selectedTags,
            link: {
                ...link,
                usergroup,
            },
        })
    }

    handleChange(event) {
        const { link } = this.state
        this.setState({
            link: {
                ...link,
                [event.target.name]: event.target.value,
            },
        })
    }

    handleSelectChange(items, name) {
        const { link } = this.state
        const value = Array.isArray(items)
            ? items.map((item) => item.value)
            : items.value
        this.setState({
            link: {
                ...link,
                [name]: value,
            },
        })
    }

    handleTagsChange(items) {
        this.setState({
            selectedTags: items,
            tags: items !== null ? items.map((item) => item.value) : [],
        })
    }

    async handleCreateTag(value) {
        this.setState({ tagsLoading: true })
        await this.props.createTag(JSON.stringify({ name: value }))
        // retrieve the tag that was newly added
        const latestTag = this.props.tags[this.props.tags.length - 1]
        const selectedTags = [
            ...this.state.selectedTags,
            convertToSelectValue(latestTag),
        ]
        const tags = [...this.state.tags, latestTag.id]
        this.setState({
            tags,
            selectedTags,
            tagsLoading: false,
        })
    }

    async handleSubmit(event) {
        event.preventDefault()

        const {
            id,
            getState,
            handleDisabledSteps,
            setStep,
            toggleSnackbar,
            toggleLoading,
        } = this.props

        const requiredFields = Array.from(
            document.querySelectorAll("[data-required], [data-type='url']")
        )
        const error = validateRequiredFields(requiredFields)

        if (!error) {
            const { link, tags } = this.state
            const { title, targetUrl, usergroup, notes } = link
            const payload = JSON.stringify({
                title,
                targetUrl,
                usergroup,
                tags,
                notes
            })

            toggleLoading(true)
            try {
                ReactGA.event({
                    category: 'Link',
                    action: 'Edit',
                    label: 'Save Changes',
                })
                await updateLink(id, payload)
                toggleLoading(false)
            } catch (err) {
                toggleLoading(false)
                toggleSnackbar(err.message)
                return
            }

            if (this.props.error) {
                return
            }

            getState(this.state)
            handleDisabledSteps([1])
            setStep(2)
        } else {
            toggleSnackbar('Please complete all required fields!')
        }
    }

    render() {
        const { usergroups, domains, tags, history } = this.props
        const { link, selectedTags } = this.state

        if (link === null) {
            return <Spinner show />
        }

        const { title, targetUrl, domain, code, usergroup, notes } = link
        return (
            <div>
                <Content wide headerGrid noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-links">
                            <LinkOutline
                                color={'currentColor'}
                                title={'Links'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            Edit link
                        </h1>
                    </div>
                </Content>
                <Content wide>
                    <Form>
                        <Form.Input
                            name="title"
                            onInput={this.handleChange}
                            type="text"
                            data-type="text"
                            label="Title*"
                            data-required
                            errorMessage="Title must not be empty."
                            width={25}
                            placeholder="Title"
                            value={title}
                        />

                        <Form.SelectField
                            name="usergroup"
                            label="Usergroup"
                            options={usergroups}
                            value={usergroup}
                            placeholder="Choose"
                            width={25}
                            onChange={(items) =>
                                this.handleSelectChange(items, 'usergroup')
                            }
                        />

                        <Form.Input
                             name="targetUrl"
                             type="url"
                             onInput={this.handleChange}
                             label="Target URL*"
                             width={50}
                             placeholder="https://www.example.com"
                             data-required
                             errorMessage="Target URL must not be empty."
                             data-type="url"
                             value={targetUrl}
                        />

                        <Form.SelectField
                            label="Branded Domain"
                            name="domain"
                            width={25}
                            isDisabled
                            options={domains}
                            value={domain}
                            onChange={(item) =>
                                this.handleSelectChange(item, 'domain')
                            }
                        />

                        <Form.Input
                            label="Slash-Tag*"
                            name="code"
                            value={code ? code : ''}
                            type="text"
                            data-type="text"
                            data-required
                            errorMessage="Code must not be empty."
                            width={25}
                            additionalNote="Only letters and numbers and the following signs '_', '-' are allowed."
                            placeholder="q5a4s6"
                            isDisabled
                        />

                        <Form.CreateableMultiSelectField
                            name="tags"
                            label="Tags"
                            placeholder="Tag 1, Tag 2, Tag 3"
                            options={tags}
                            value={selectedTags}
                            width={50}
                            isLoading={this.state.tagsLoading}
                            isDisabled={this.state.tagsLoading}
                            onCreateOption={this.handleCreateTag}
                            onChange={this.handleTagsChange}
                        />

                        <Form.Textarea
                            name="notes"
                            onInput={this.handleChange}
                            type="text"
                            data-type="text"
                            label="Notes"
                            width={100}
                            placeholder="Your Notes"
                            value={notes}
                            showLengthWarningAtLength={255}
                            showLengthWarning="Maximum chars you can set are: "
                        />

                        <ParagraphRequiredField />
                        <Content button>
                            <Button
                                label="Cancel"
                                secondary
                                onClick={() => history.push('/links')}
                            />
                            <Button
                                label="Save changes"
                                onClick={this.handleSubmit}
                            />
                        </Content>
                    </Form>
                </Content>
            </div>
        )
    }
}

export default connect(
    'domains,usergroups,tags,prefetchedData',
    actions
)(LinksEdit)

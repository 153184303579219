import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Content from '../../../components/Content';
import MultiSteps from '../../../components/MultiSteps';
import Step1 from './Step1/CreateCampaignStep1';
import Step2 from './Step2/CreateCampaignStep2';
import {
    hasReachedCampaignsLimit,
} from "../../../util/user-management";
import {Link} from "react-router-dom";

export default class CampaignsCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalSteps: 2,
            currentStep: 1,
            disabledSteps: [2],
        };

        this.setStep = this.setStep.bind(this);
        this.handleDisabledSteps = this.handleDisabledSteps.bind(this);
    }

    componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Campaign Create',
        });
    }

    setStep(currentStep) {
        this.setState({ currentStep });
    }

    handleDisabledSteps(disabledSteps) {
        this.setState({ disabledSteps });
    }

    render() {
        const { history } = this.props;
        const { totalSteps, currentStep, disabledSteps } = this.state;
        const getCurrentStep = (step) => this.setState({ currentStep: step });

        return (
            <Content>
                {!hasReachedCampaignsLimit() ? (
                    <div>
                        <MultiSteps
                            totalSteps={totalSteps}
                            currentStep={currentStep}
                            disabledSteps={disabledSteps}
                            getCurrentStep={getCurrentStep}
                        />
                        {currentStep === 1
                            && (
                                <Step1
                                    setStep={this.setStep}
                                    handleDisabledSteps={this.handleDisabledSteps}
                                    history={history}
                                />
                            )}

                        {currentStep === 2
                            && (
                                <Step2
                                    history={history}
                                />
                            )}
                    </div>
                ) : (
                    <div>
                        <h1>Upgrade your plan</h1>
                        <p>You have reached the limit of campaigns you can create. Please <Link
                            to="/stripe-customer-portal">upgrade</Link> your plan to create more campaigns.</p>
                    </div>
                )}
            </Content>
        );
    }
}

import React, { Component } from 'react';
import { connect } from 'react-unistore';
import actions from '../../actions';

class Logout extends Component {
  componentDidMount() {
    const { logout, history } = this.props;
    logout();
    history.push('/login');
  }

  render() {
    return (<div />);
  }
}

export default connect('', actions)(Logout);

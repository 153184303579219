import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import {getExternalDomain, activateDomain, getExternalDomainDeletion, deleteDomain} from '../../../api/domains';
import Spinner from '../../../components/Spinner'
import ActivateMultiStep from "../../../components/Domain/ActivateMultiStep";
import DeleteMultiStep from "../../../components/Domain/DeleteMultiStep";

class DomainDetails extends Component {
    constructor(props) {
        super(props)
        const { match } = this.props
        const { id, action } = match.params
        this.state = {
            domain: null,
            id: parseInt(id, 10),
            action: action,
            defaultTarget: '',
            error: null
        }
        this.startActivation = this.startActivation.bind(this)
        this.startDeletion = this.startDeletion.bind(this)
    }

    async componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Domains',
        })
        const { id, action } = this.state
        const domain = action === 'delete' ? await getExternalDomainDeletion(id) : await getExternalDomain(id)

        this.setState({
            domain,
        })
    }

    async startActivation() {
        const { id } = this.state
        this.setState({
            domain: null,
        })
        try {
            const result = await activateDomain(id)
            console.info(result)
        } catch (e) {
            console.info(e)
        }
        const domain = await getExternalDomain(id)
        this.setState({
            domain,
        })
    }

    async startDeletion() {
        const validateDomain = function (domain) {
            const re = new RegExp(
                // eslint-disable-next-line no-useless-escape
                /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
            )
            return domain.match(re)
        }

        const { id, defaultTarget } = this.state

        if (!validateDomain(defaultTarget)) {
            this.setState({ error: 'Not a valid domain.' })
            return
        }

        this.setState({
            domain: null,
        })

        try {
            const result = await deleteDomain(id, defaultTarget)
            console.info(result)
        } catch (e) {
            console.info(e)
        }
        const domain = await getExternalDomainDeletion(id)
        this.setState({domain})
    }

    render() {
        const { history } = this.props
        const { domain, action } = this.state

        if (domain === null) {
            return <Spinner show />
        }


        return (
            action === 'activate' ? (
                <ActivateMultiStep
                    domain={domain}
                    onClick={this.startActivation}
                    history={history}
                />
            ): (
                <DeleteMultiStep
                    domain={domain}
                    history={history}
                    onClick={this.startDeletion}
                    defaultTarget={this.state.defaultTarget}
                    error={this.state.error}
                    onChange={(value) => this.setState({defaultTarget: value})}
                />
            )
        )
    }
}

DomainDetails.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({}).isRequired,
}

export default DomainDetails

import React, {useCallback} from "react";
import {stripeCheckoutPricingTableSession} from "../../api/payment";
import {getCurrentUserEmail} from "../../util/user-management";
import Content from "../../components/Content";
import {CardOutline} from "react-ionicons";

const publishableKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;

const CheckoutForm = () => {
    const fetchClientSecret = useCallback(() => {
        return stripeCheckoutPricingTableSession()
            .then((data) => data.clientSecret);
    }, []);

    return (
        <div>
            <Content wide headerGrid noMargin>
                <div className="headerRow">
                    <h1 className="underline__lh-default">
                        <CardOutline
                            color={'currentColor'}
                            title={'Card'}
                            width="2rem"
                            height="2rem"
                            className="icon"
                        />
                        Subscription
                    </h1>
                    <div>
                        <h2>Create and share branded short links, track your audience, and more.</h2>
                        <p>
                            Get started with LinkHawk today and reach your audience with branded short links.
                        </p>
                    </div>
                </div>
            </Content>
            <Content fullHeightContent>
                <div id="checkout">
                    <stripe-pricing-table
                        pricing-table-id={pricingTableId}
                        publishable-key={publishableKey}
                        customer-session-client-secret={fetchClientSecret}
                        customer-email={getCurrentUserEmail()}
                    >
                    </stripe-pricing-table>
                </div>
            </Content>
        </div>

    )
}

export default CheckoutForm;

import { getBranding } from '../api/branding'
import { getUserGroups, getGroups } from '../api/groups'
import { getUsers, getUser } from '../api/users'
import { fetchAccount } from '../api/account'
import getTags from '../api/tags'
import { getDomains } from '../api/domains'
import initialState from '../model'

export const toggleSnackbar = (store, state, message, type = 'error') => {
    store.setState({
        loading: false,
        snackbarVisible: true,
        snackbarText: message,
        snackbarType: type,
    })
    setTimeout(() => {
        store.setState({ snackbarVisible: false })
    }, 3000)
}

export const toggleCreateNavigation = (store, state) => {
    store.setState({ showCreateNavigation: !state.showCreateNavigation })
}

export const closeCreateNavigation = (store) => {
    store.setState({ showCreateNavigation: false })
}

export const togglePopup = (store, state, value) => {
    console.log(value);
    store.setState({ showPopup: !state.showPopup })
}

const setNetworkError = (store, state, err, recoverable) => {
    store.setState({
        loading: false,
        networkError: {
            recoverable,
            message: err && err.message ? err.message : null,
        },
    })
}

export const setLoading = (store, state, value) => {
    if (state.networkError && state.networkError.recoverable === false) {
        return
    }
    if (
        value === false &&
        !state.prefetchingAdminData &&
        !state.prefetchingData
    ) {
        store.setState({ loading: value })
    } else {
        store.setState({ loading: value })
    }
}

export const setRefreshed = (store) => {
    store.setState({ refreshed: true })
}

export const logout = (store) => {
    localStorage.clear()
    if (store) {
        store.setState(initialState)
    }
}

/**
 * (re-)loads required data for (subscribed) user routes into state
 *
 * @param {*} store
 * @param {*} state
 */
export const refreshUserData = async(store, state) => {
    const initialFetch = store.prefetchedData
    if (initialFetch) {
        store.setState({
            loading: true,
            prefetchingData: true,
        })
    }

    try {
        const [branding, usergroups, domains, tags] = await Promise.all([
            getBranding(),
            getUserGroups(),
            getDomains(),
            getTags(),
        ])

        store.setState({
            branding,
            usergroups,
            domains,
            tags,
            loading: initialFetch ? false : state.loading,
            prefetchingData: false,
            prefetchedData: true,
            showCreateNavigation: false,
        })
    } catch (err) {
        setNetworkError(store, state, err, false)
    }
}

/**
 * (re-)loads required data for admin routes into state
 *
 * @param {*} store
 * @param {*} state
 */
export const refreshAdminData = async(store, state) => {
    const initialFetch = state.prefetchedAdminData
    if (initialFetch) {
        store.setState({
            loading: true,
            prefetchingAdminData: true,
        })
    }
    try {
        const [groups, users, user, branding, usergroups] = await Promise.all([
            getGroups(),
            getUsers(),
            getUser(),
            getBranding(),
            getUserGroups(),
            fetchAccount(),
        ])

        store.setState({
            usergroups,
            groups,
            branding,
            users,
            user,
            loading: initialFetch ? false : state.loading,
            prefetchingAdminData: false,
            prefetchedAdminData: true,
            showCreateNavigation: false,
        })
    } catch (err) {
        setNetworkError(store, state, err, false)
    }
}

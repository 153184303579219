import React from 'react';
import moment from 'moment';
import style from "../style.module.scss";
import PropTypes from "prop-types";

const CustomNavbar = (
    {
        month,
        nextMonth,
        previousMonth,
        onPreviousClick,
        onNextClick,
        renderTagButtons,
        applyMonth
    }
) => {
    const handlePreviousClick = () => {
        applyMonth(previousMonth);
        onPreviousClick();
    };

    const handleNextClick = () => {
        applyMonth(nextMonth);
        onNextClick();
    };

    return (
        <div
            style={{
                paddingLeft: '6px',
                paddingRight: '6px',
                paddingBottom: '12px',
                width: '100%',
            }}
        >
            <div style={{ paddingTop: '20px' }}>
                <div className={style.calendarNavBar}>
                    <div className="column">
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                handlePreviousClick();
                            }}
                            style={{ textAlign: 'left' }}
                        >
                            <img
                                alt="previous month"
                                src="/icons/arrow-calendar.svg"
                                style={{ textAlign: 'left' }}
                            />
                        </button>
                    </div>
                    <div className="column" style={{ marginTop: '5px' }}>
                        {moment(month).format('MMMM YYYY')}
                    </div>
                    <div className="column">
                        {moment(month).endOf('month').isBefore(moment()) ? (
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNextClick();
                                }}
                                style={{ textAlign: 'right' }}
                            >
                                <img
                                    alt="next month"
                                    src="/icons/arrow-calendar.svg"
                                    style={{ transform: 'rotate(180deg)' }}
                                />
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            {renderTagButtons && renderTagButtons()}
        </div>
    );
}

CustomNavbar.propTypes = {
    renderTagButtons: PropTypes.func,
    applyMonth: PropTypes.func,
};

CustomNavbar.defaultProps = {
    renderTagButtons: () => null,
};

export default CustomNavbar;
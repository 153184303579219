import React from 'react';
import PropTypes from 'prop-types';

const Offline = ({ message }) => (
  <section style={{ padding: '40px' }}>
    <h2>Network error.</h2>
    <p>
      <span>Backend services can not be reached or are not responing as expected.</span>
      <br />
      <span style={{ color: 'darkgray' }}>{message}</span>
    </p>
  </section>
);

Offline.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Offline;

import React, { useState } from 'react'
import ReactGA from 'react-ga'
import { useEffect } from 'react'
import style from './style.module.scss'
import { useStore } from 'react-unistore'

import { Link } from 'react-router-dom'
import { archiveLink, unArchiveLink, unFavLink } from '../../api/links'
import { toggleSnackbar } from '../../actions/general'

import ReactTooltip from 'react-tooltip';
import {
    AnalyticsOutline,
    EllipsisHorizontal,
    EllipsisVertical,
    FileTrayFullOutline,
    FileTrayOutline,
    Pencil,
} from 'react-ionicons'

function ActionToggle({ changeArchiveState, ...props }) {
    const store = useStore()
    const state = useState()
    const [active, setActive] = useState(false)
    const id = props.id
    const archived = props.archive
    const favorite = props.favorite
    const type = props.type
    const transformedType = props.type.toUpperCase()
    const [clickedArchived, setClickedArchived] = useState(false)

    const open = () => {
        setActive(true)
    }

    const close = () => {
        setActive(false)
    }

    // TODO: respond to type prop ... actuall we only need archive for links not campaigns

    const onClickArchiveLink = (id, isArchived) => {
        if (isArchived) {
            unArchiveLink(id).then(() => {
                ReactGA.event({
                    category: 'Link',
                    action: 'Edit',
                    label: 'Unarchived Link',
                })
                setClickedArchived(true)
                changeArchiveState(false)
                // console.info('Entarchivieren', id, isArchived)
                toggleSnackbar(
                    store,
                    state,
                    'Link successfully reactivated'
                )
            })
        } else {
            archiveLink(id, favorite).then(() => {
                ReactGA.event({
                    category: 'Link',
                    action: 'Edit',
                    label: 'Archived Link',
                })
                if (favorite) {
                    unFavLink(id).then(() => {
                        ReactGA.event({
                            category: 'Link',
                            action: 'Favorite',
                            label: 'Unbookmarked Link',
                        })
                    })
                }
                setClickedArchived(true)
                changeArchiveState(true)
                // console.info('Archivieren', id, isArchived)
                toggleSnackbar(
                    store,
                    state,
                    'Link successfully archived'
                )
            })
        }
    }

    useEffect(() => {
        //
    }, [active])

    useEffect(() => {
        //
    }, [clickedArchived])

    return (
        <div className={style.rel} onMouseLeave={() => close()}>
            <div
                className={`${style.actionToggle} ${active ? `${style.active}` : ''
                    }`}
                onMouseEnter={() => open()}
            >
                {active ? (
                    <EllipsisVertical color={'currentColor'} />
                ) : (
                    <EllipsisHorizontal color={'currentColor'} />
                )}
                {active ? (
                    <>
                        <div className={style.actionToggleOpened}>
                            <Link
                                to={`/${type}/edit/${id}`}
                                onClick={() => {
                                    ReactGA.event({
                                        category: transformedType,
                                        action: 'Edit',
                                        label: 'Open Editor',
                                    })
                                }}
                            >
                                <Pencil />
                            </Link>
                            <Link
                                to={`/${type}/detail/${id}`}
                                onClick={() => {
                                    ReactGA.event({
                                        category: transformedType,
                                        action: 'View',
                                        label: 'Analytics',
                                    })
                                }}
                            >
                                <AnalyticsOutline />
                            </Link>
                            {!(archived === undefined) && (
                                <>
                                    {(archived) ? (
                                        <span data-tip="Reactivate Link" data-for="archive">
                                            <FileTrayOutline
                                                onClick={() => onClickArchiveLink(id, archived)}
                                            />
                                        </span>
                                    ) : (
                                        <span data-tip="Archive Link" data-for="archive">
                                            <FileTrayFullOutline
                                                onClick={() => onClickArchiveLink(id, archived)}
                                            />
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                        <ReactTooltip place="left" effect="solid" id="archive" className="customTooltip" />
                    </>
                ) : null}
            </div>
        </div>
    )
}

export default ActionToggle

import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types'

import { connect, useStore } from 'react-unistore';

import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import actions from '../../actions';
import Content from '../../components/Content';
import { clearErrors } from '../../util/validates';
import { Search, AddOutline, MegaphoneOutline } from 'react-ionicons';

import useDebounce from '../../hooks/debounce'
import Table from '../../components/Table/DynamicTable'
import style from './style.module.scss'

import { getCampaigns } from '../../actions/campaigns';

import BeaconLink from '../../components/BeaconLink';
import Copy from '../../components/Copy/Copy';
import ActionToggle from '../../components/ActionToggle/ActionToggle';
import TrendlineChart from '../../components/TrendlineChart/TrendlineChart';


function CampaignList(props) {
    // static vars
    const store = useStore()
    const history = props.history
    const pageSize = props.campaigns.pageSize

    // vars
    const [initial, setInitial] = useState(true) // for initial rendering check
    const [fetched, setFetched] = useState(props.campaigns.fetched) // for api-response checks
    const [data, setData] = useState(props.campaigns.items) // getting and handling data from api
    const [queryString, setQueryString] = useState('')
    const [currentPage, setCurrentPage] = useState(props.campaigns.currentPage)
    const [pages, setPages] = useState(props.campaigns.pages)

    const debouncedSearchTerm = useDebounce(queryString, 1200, true)

    /**
     * Function to handle the api request to get all links with different params
     * TODO: we should use useCallback here ... Problem is, that it rerenders on every stateChange
     */
    const getCampaignListData = () => {
        getCampaigns(store, data, currentPage, pageSize, queryString)
            .then((res) => {
                setData(res.campaigns.items)
                setCurrentPage(res.campaigns.currentPage)
                setFetched(res.campaigns.fetched)
                setPages(res.campaigns.pages)
                store.setState({ loading: false })
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        // INITIAL State loading , fired only once
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Campaigns',
        });
        clearErrors();
        window.scrollTo(0, 0);
    }, [])

    // Searching and it's effect based on debounce
    const onChangeQueryString = (e) => {
        const queryString = e.target.value
        setQueryString(queryString)
    }

    useEffect(() => {
        if (debouncedSearchTerm) {
            setInitial(false)
            getCampaignListData()
        } else {
            getCampaignListData()
        }
    }, [debouncedSearchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

    // Page Change
    const pageChange = (e) => {
        setCurrentPage(e)
    }

    useEffect(() => {
        setInitial(false)
        getCampaignListData()
    }, [currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = React.useMemo(
        () => [
            {
                Header: <BeaconLink beaconId={147} label="Name" />,
                id: 'link_id',
                accessor: (d) => {
                    let date = new Date(d.created_at);
                    date = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
                    return (
                        <div>
                            <h6>{d.title}</h6>
                            <p className="small">
                                Created:&nbsp;
                                <span>{date}</span>
                                {' '}
                                <br />
                                User:&nbsp;
                                <span>{d.user}</span>
                                <br />
                                Group:&nbsp;
                                <span>{d.usergroup}</span>
                            </p>
                        </div>
                    );
                },
            },
            {
                Header: 'Shortlinks',
                id: 'shortUrls',
                accessor: (d) => {
                    return (
                        <div className={style.cellFlex}>
                            {
                                d.shortUrls.map((shortUrl) => (
                                    <div className={style.copyCell} key={`copy-${shortUrl.url}`}>
                                        <Copy key={`copy-${shortUrl.url}`} value={shortUrl.url} />
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            }, {
                Header: <BeaconLink beaconId={144} label="Clicks" />,
                id: 'shortUrls.views',
                accessor: (d) => {
                    return (
                        <div className={style.cellFlex}>
                            {
                                d.shortUrls.map((shortUrl) => (
                                    <p className={style.copyHeight} key={`clicks-${shortUrl.url}`}>{shortUrl.views}</p>
                                ))
                            }
                        </div>
                    )
                }
            },
            {
                Header: (
                    <>
                        <div>
                            Trends
                        </div>
                    </>
                ),
                id: 'trends',
                accessor: (d) => {
                    return (
                        <>
                            {
                                d.shortUrls.map((shortUrl) => (
                                    <TrendlineChart id={shortUrl.id} key={`trends-${shortUrl.id}`} />
                                ))
                            }
                        </>
                    )
                },
            },
            {
                Header: 'Total',
                id: 'views',
                accessor: 'views',
                Cell: ({ value }) => (
                    <div><p className={style.big}>{value}</p></div>
                ),
            }, {
                Header: 'Target URL',
                id: 'targetUrl',
                accessor: (d) => {
                    let trimmedString = d.targetUrl;
                    if (trimmedString.length > 30) {
                        trimmedString = `${trimmedString.substring(0, 30)}...`;
                    }
                    return (
                        <div className="cellPulldown">
                            <a
                                title={d.targetUrl}
                                target="_blank"
                                className="long-value"
                                rel="noopener noreferrer"
                                href={d.targetUrl}
                            >
                                {trimmedString}
                            </a>
                        </div>
                    );
                }
            }, {
                Header: '',
                id: 'actions',
                accessor: (d) => (
                    <ActionToggle
                        id={d.id}
                        type="campaigns"
                    />
                ),
            }
        ], []
    );

    if (fetched && initial || fetched && data.length === 0) {
        return (
            <>
                <div>
                    <Content wide headerGrid noMargin>
                        <div className="headerRow">
                            <h1 className="underline__lh-campaigns">
                                <MegaphoneOutline
                                    color={'currentColor'}
                                    title={'Campaigns'}
                                    width="2rem"
                                    height="2rem"
                                    className="icon"
                                />
                                Create a campaign
                            </h1>
                        </div>
                    </Content>
                    <Content fullHeightContent>
                        <p>Start tracking your customers’ journeys on every link and channel from a single, sleek dashboard.</p>
                        <br/>
                        <Button
                            label="Create campaign"
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Campaign',
                                    action: 'Create',
                                    label: 'Open Editor',
                                });
                                history.push('/campaigns/create');
                            }}
                            width={200}
                        />
                    </Content>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div>
                    <Content wide headerGrid noMargin>
                        <div className="headerRow">
                            <h1 className="underline__lh-campaigns">
                                <MegaphoneOutline
                                    color={'currentColor'}
                                    title={'Campaigns'}
                                    width="2rem"
                                    height="2rem"
                                    className="icon"
                                />
                                Campaigns
                            </h1>
                            <div>
                                <h2>This is your personal campaign overview</h2>
                            </div>
                        </div>
                        <div
                            className="addButton campaigns"
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Campaign',
                                    action: 'Create',
                                    label: 'Open Editor',
                                });
                                history.push('/campaigns/create');
                            }}
                        >
                            <AddOutline
                                color={'currentColor'}
                                title={'Create new campaign'}
                                className="btn_icon_rounded_pulse"
                            />
                        </div>
                    </Content>
                    <Content fullHeightContent>
                        <div className={style.searchContainer}>
                            <div className={style.inputWithIcon}>
                                <Search
                                    className={style.inputIcon}
                                    color={'currentColor'}
                                    title={'Search'}
                                />
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Search"
                                    value={queryString}
                                    onChange={onChangeQueryString}
                                />
                            </div>
                        </div>
                        <div className={style.fullWidth}>
                            <Table
                                columns={columns}
                                data={data}
                                onFetchData={fetched}
                                nothingFoundText="Sorry, we couldn't find any data yet ..."
                            />
                            {pages > 1 ? (
                                <Pagination
                                    pages={pages}
                                    page={currentPage}
                                    onPageChange={(e) => pageChange(e)}
                                    previousText="PREV"
                                    nextText="NEXT"
                                />
                            ) : null}
                        </div>
                    </Content>
                </div>
            </>
        )
    }
}


CampaignList.propTypes = {
    campaigns: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        currentPage: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        fetched: PropTypes.bool.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    getCampaigns: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    location: PropTypes.shape({ search: PropTypes.string.isRequired })
        .isRequired,
}

export default connect('campaigns,loading', actions)(CampaignList);


import {
    togglePopup,
    toggleCreateNavigation,
    closeCreateNavigation,
    toggleSnackbar,
    refreshUserData,
    refreshAdminData,
    setRefreshed,
    setLoading,
    logout
} from './general'
import {
    deleteAccount,
} from './account'
import { getCampaigns, createCampaign } from './campaigns'
import { getLinkList, createLink } from './links'
import { createTag } from './tags'
import { getUsers } from './users'

export default (store) => ({
    getLinks: async(...args) => getLinkList(store, ...args),
    createLink: async(...args) => createLink(store, ...args),
    getCampaigns: async(...args) => getCampaigns(store, ...args),
    createCampaign: async(...args) => createCampaign(store, ...args),
    createTag: async(...args) => createTag(store, ...args),
    getUsers: async(...args) => getUsers(store, ...args),
    deleteAccount: async(...args) => deleteAccount(store, ...args),
    toggleSnackbar: (...args) => toggleSnackbar(store, ...args),
    togglePopup: (...args) => togglePopup(store, ...args),
    toggleCreateNavigation: (...args) => toggleCreateNavigation(store, ...args),
    closeCreateNavigation: (...args) => closeCreateNavigation(store, ...args),
    toggleLoading: (...args) => setLoading(store, ...args),
    setRefreshed: () => setRefreshed(store),
    refreshUserData: (...args) => refreshUserData(store, ...args),
    refreshAdminData: (...args) => refreshAdminData(store, ...args),
    logout: () => logout(store),
})

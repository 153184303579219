import React from 'react'
import style from './style.module.scss'

const Content = (props) => {
    let module = style.contentContainer

    // remove margins and center with space between
    if (props.bar) {
        module += ` ${style.bar}`
    }

    // position of headline button and searchbar
    if (props.header) {
        module += ` ${style.header}`
    }

    // center items
    if (props.center) {
        module += ` ${style.center}`
    }

    // space for successPage
    if (props.successPage) {
        module += ` ${style.successPage}`
    }

    // small content (600px)
    if (props.small) {
        module += ` ${style.small}`
    }

    // small content (600px) Leftsided
    if (props.smallLeft) {
        module += ` ${style.smallLeft}`
    }

    // wide content (fullwidth)
    if (props.wide) {
        module += ` ${style.wide}`
    }

    // PageHeader
    if (props.headerGrid) {
        module += ` ${style.headerGrid}`
    }

    if (props.withFilter) {
        module += ` ${style.withFilter}`
    }

    if (props.fullHeightContent) {
        module += ` ${style.fullHeightContent}`
    }

    // button wrapper
    if (props.button) {
        module += ` ${style.button}`
    }

    // no padding
    if (props.noPadding) {
        module += ` ${style.noPadding}`
    }

    return (
        <div className={module} style={props.style}>
            {props.children}
        </div>
    )
}

export default Content

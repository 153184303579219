import React, { Component } from 'react';
import style from './style.module.scss';

export default class Footer extends Component {
    render() {
        return (
        <section id={style.footer}>
            <img className={style.logo} src="/icons/logo-hawk.svg" alt="Linkhawk Pro" />
        </section>
        );
    }
}

import React, {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";

import {stripeCheckoutSessionStatus} from "../../api/payment";
import {getCurrentUserName} from "../../util/user-management";
import Content from "../../components/Content";
import ButtonLink from "../../components/Button/ButtonLink";

const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        stripeCheckoutSessionStatus(JSON.stringify({sessionId}))
            .then(data => {
                setStatus(data.status);
                setCustomerEmail(data.customer_email);
            }).catch(err => {
                console.error(err);
            });
    }, []);

    if (status === 'open') {
        return (
            <Redirect to={{pathname: '/checkout'}} />
        )
    }

    if (status === 'complete') {
        return (
            <section id="success">
                <Content wide headerGrid>
                    <div className="headerRow">
                        <h1>Hi {getCurrentUserName()}.</h1>
                        <div>
                            <h2>Welcome to Linkhawk!</h2>
                            <p>
                                We appreciate your business! A confirmation email will be sent to {customerEmail}.

                                If you have any questions, please email <a
                                href="mailto:support@linkhawk.com">support@linkhawk.com</a>.
                            </p>
                        </div>
                        <div>
                            <ButtonLink
                                href="/domains/add"
                                label="Add your first domain"
                                className="button" />
                        </div>
                    </div>
                </Content>
            </section>
    )
    }

    return null;
    }

    export default Return;

import React, { Component } from 'react'
import { connect } from 'react-unistore'
import ReactGA from 'react-ga'
import Form from '../../../../components/Form'
import Content from '../../../../components/Content'
import Button from '../../../../components/Button'
import { getCode } from '../../../../api/data-management'
import actions from '../../../../actions'
import { validateRequiredFields } from '../../../../util/validates'
import { convertToSelectValue } from '../../../../util/helperFunctions'
import Spinner from '../../../../components/Spinner'
import ParagraphRequiredField from '../../../../components/ParagraphRequiredField'
import { LinkOutline } from 'react-ionicons'

class LinksCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            url: '',
            code: null,
            domain: '',
            usergroup: '',
            tags: [],
            selectedTags: [],
            notes: '',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleTagsChange = this.handleTagsChange.bind(this)
        this.handleCreateTag = this.handleCreateTag.bind(this)
    }

    async componentDidMount() {
        const { domains, usergroups } = this.props
        // TODO: indicate loading
        const code = await getCode()
        const domain = domains[0]?.id ?? null
        const usergroup = usergroups[0]?.id ?? null

        this.setState({
            domain,
            usergroup,
            code: code.code,
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSelectChange(items, name) {
        const value = Array.isArray(items)
            ? items.map((item) => item.value)
            : items.value
        this.setState({ [name]: value })
    }

    handleTagsChange(items) {
        this.setState({
            selectedTags: items,
            tags: items !== null ? items.map((item) => item.value) : [],
        })
    }

    async handleCreateTag(value) {
        this.setState({ tagsLoading: true })
        await this.props.createTag(JSON.stringify({ name: value }))
        // retrieve the tag that was newly added
        const latestTag = this.props.tags[this.props.tags.length - 1]
        const selectedTags = [
            ...this.state.selectedTags,
            convertToSelectValue(latestTag),
        ]
        const tags = [...this.state.tags, latestTag.id]
        this.setState({
            tags,
            selectedTags,
            tagsLoading: false,
        })
    }

    async handleSubmit(event) {
        event.preventDefault()
        const {
            createLink,
            toggleLoading,
            toggleSnackbar,
            handleDisabledSteps,
            setStep,
        } = this.props

        // TODO: do not access dom directly in react app!!!
        const requiredFields = Array.from(
            document.querySelectorAll("[data-required], [data-type='url']")
        )
        const error = validateRequiredFields(requiredFields)

        if (!error) {
            const { title, url, code, domain, usergroup, tags, notes } = this.state

            // TODO: reformat tags content before submit
            const payload = JSON.stringify({
                title,
                url,
                domain,
                code,
                usergroup,
                tags,
                notes,
            })

            toggleLoading(true)
            try {
                ReactGA.event({
                    category: 'Link',
                    action: 'Create',
                    label: 'Create Link',
                })
                await createLink(payload)
                toggleLoading(false)
            } catch (err) {
                toggleLoading(false)
                toggleSnackbar(err.message)
                return
            }

            if (this.props.error) {
                return
            }

            handleDisabledSteps([1])
            setStep(2)
        } else {
            toggleSnackbar('Please complete all required fields!')
        }
    }

    render() {
        const { title, usergroup, url, domain, code, selectedTags, notes } = this.state

        const { usergroups, domains, tags, history } = this.props

        if (code === null) {
            return <Spinner show />
        }

        return (
            <div>
                <Content wide headerGrid noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-links">
                            <LinkOutline
                                color={'currentColor'}
                                title={'Links'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            Create a new link
                        </h1>
                    </div>
                </Content>
                <Content wide>
                    <Form>
                        <Form.Input
                            name="title"
                            onInput={this.handleChange}
                            type="text"
                            data-type="text"
                            label="Title*"
                            data-required
                            width={25}
                            placeholder="Title"
                            value={title}
                        />

                        <Form.SelectField
                            name="usergroup"
                            label="Usergroup"
                            width={25}
                            options={usergroups}
                            value={usergroup}
                            onChange={(item) =>
                                this.handleSelectChange(item, 'usergroup')
                            }
                        />

                        <Form.Input
                            name="url"
                            type="url"
                            onInput={this.handleChange}
                            label="Target URL*"
                            width={50}
                            placeholder="https://www.example.com"
                            errorMessage="Target URL must not be empty."
                            data-required
                            data-type="url"
                            value={url}
                        />

                        <Form.SelectField
                            name="domain"
                            label="Branded Domain"
                            width={25}
                            options={domains}
                            value={domain}
                            onChange={(item) =>
                                this.handleSelectChange(item, 'domain')
                            }
                        />

                        <Form.Input
                            name="code"
                            onInput={this.handleChange}
                            type="text"
                            data-type="slash-tag"
                            label="Slash-Tag*"
                            beaconId={145}
                            width={25}
                            placeholder="q5a4s6"
                            data-required
                            errorMessage="Code must not be empty."
                            additionalNote="Only letters and numbers and the following signs '_', '-' are allowed."
                            value={code}
                        />

                        <Form.CreateableMultiSelectField
                            name="tags"
                            label="Tags"
                            beaconId={146}
                            placeholder="Tag 1, Tag 2, Tag 3"
                            options={tags}
                            value={selectedTags}
                            width={50}
                            isLoading={this.state.tagsLoading}
                            isDisabled={this.state.tagsLoading ? true : false}
                            onCreateOption={this.handleCreateTag}
                            onChange={this.handleTagsChange}
                        />

                        <Form.Textarea
                            name="notes"
                            onInput={this.handleChange}
                            type="text"
                            data-type="text"
                            label="Notes"
                            width={100}
                            placeholder="Your Notes"
                            value={notes}
                            showLengthWarningAtLength={255}
                            showLengthWarning="Maximum chars you can set are: "
                        />

                        <ParagraphRequiredField />
                        <Content button>
                            <Button
                                label="Cancel"
                                secondary
                                onClick={() => history.push('/links')}
                            />
                            <Button
                                label="Create link"
                                onClick={this.handleSubmit}
                            />
                        </Content>
                    </Form>
                </Content>
            </div>
        )
    }
}

export default connect(
    'domains,usergroups,tags,prefetchedData',
    actions
)(LinksCreate)

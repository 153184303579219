import React from 'react';
import PropTypes from 'prop-types';

const BeaconLink = (props) => {
    const { beaconId, label, type } = props;
    return (<a href="#" className={type} onClick={() => window.Beacon('article', `${beaconId}`, { type: 'sidebar' })}>{label}</a>);
};

BeaconLink.defaultProps = {
    beaconId: null,
    label: '',
    type: 'link--beacon',
};

BeaconLink.propTypes = {
    beaconId: PropTypes.number,
    label: PropTypes.string,
    type: PropTypes.oneOf(['button--small', 'link--beacon']),
};

export default BeaconLink;

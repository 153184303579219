/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as user from '../../../util/user-management';
import Footer from '../../Footer';
import Header from '../../Header';

const UserRoute = ({ component: Component, ...rest }) => {
    // Add your own authentication on the below line.
    const isLoggedIn = user.isLoggedIn();

    return (
        <Route
        {...rest}
        render={(props) => {
            return (isLoggedIn ? (
            <div className="lh__layout-inner-grid">
                <Header activeLink={props.location.pathname} showNavigation={user.role()} />
                <main className="lh__layout-inner-grid-content">
                    <Component {...props} />
                </main>
                <Footer />
            </div>
            ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            ));
        }}
        />
    );
};
export default UserRoute;

import React, { Component } from 'react';
import { connect } from 'react-unistore';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import actions from '../../actions';
import {
    // getCurrentUserId,
    // getAccount,
    // getLatestPayment,
    // isCanceled,
    isSubscribed,
} from '../../util/user-management';
import Content from '../../components/Content';
import Table from '../../components/Table/DynamicTable';
import { Pencil, People } from 'react-ionicons';
import SearchBar from "../../components/FilterBar/SearchBar";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            searchTerm: '',
            filteredUsers: props.users,
        };

        this.setActiveClass = this.setActiveClass.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Users',
        });
    }

    handleSearch(searchTerm) {
        const lowerCaseTerm = searchTerm.toLowerCase();
        const filteredUsers = this.props.users.filter(user => {
            if (user.firstname) {
                return user.firstname.toLowerCase().includes(lowerCaseTerm);
            }

            if (user.lastname) {
                return user.lastname.toLowerCase().includes(lowerCaseTerm);
            }

            return user.email.toLowerCase().includes(lowerCaseTerm);
        });

        this.setState({ searchTerm, filteredUsers });
    }

    setActiveClass(state, rowInfo) {
        const { search } = this.props.location;
        const query = new URLSearchParams(search);

        const newuser = query.get('newuser');
        const newUsers = newuser ? newuser.split(',')
            .map((userId) => parseInt(userId, 10))
            : [];
        if (newUsers.includes(rowInfo.original.id)) {
            return {
                className: 'animated',
            };
        }
        return {};
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    toggleDeletePopup(userId) {
        this.setState({ userId });
        this.props.togglePopup();
    }

    async deleteUser() {
        const input = document.getElementById('confirmCheckout');
        const inputVar = input.value;
        input.classList.remove('error');

        // checks whether the input is identical
        if (inputVar === 'delete') {
            input.classList.remove('error');

            await this.props.deleteUser(this.state.userId);
        } else {
            input.classList.add('error');
        }
    }

    render() {
        const columns = [
            {
                Header: 'User',
                id: 'users',
                accessor: (d) => (
                    <div>
                        <div className="left">
                            <h6>{d.firstname === undefined ? 'Unknown' : `${d.firstname} ${d.lastname}`}</h6>
                            {d.roles.includes('ROLE_SUPER_ADMIN') && <p className="label" style={{ float: 'left' }}>Admin</p>}
                        </div>
                        <p className="small">{d.email}</p>
                    </div>
                ),
            }, {
                Header: 'Group(s)',
                id: 'groups',
                accessor: (d) => {
                    const groups = d.usergroups.map((item, i, array) => (
                        <span key={item.name + i}>
                            {item.name}
                            {i + 1 < array.length ? ', ' : ''}
                        </span>
                    ));

                    return (
                        <div className="cellPulldown">
                            {groups.map((group) => group)}
                        </div>
                    );
                },
            }, {
                Header: 'Edit',
                id: 'edit',
                style: {
                    width: '3rem',
                },
                accessor: (d) => (
                    <div className="cellPulldown center">
                        <Link
                            to={`/user/edit/${d.id}`}
                            onClick={() => {
                                ReactGA.event({
                                    category: 'User',
                                    action: 'Edit',
                                    label: 'Open Editor',
                                });
                            }}
                        >
                            <Pencil />
                        </Link>
                    </div>
                ),
            },
        ]

        return (
            <div>
                <Content wide headerGrid noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-default">
                            <People
                                color={'currentColor'}
                                title={'Users'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            Users
                        </h1>
                        <div>
                        </div>
                    </div>
                    <Content button>
                        <div className="headerActions">
                            <SearchBar
                                placeholder="Search Users..."
                                onSearch={this.handleSearch}
                            />
                        </div>
                        <Button
                            isDisabled={!isSubscribed()}
                            title={!isSubscribed() ? 'You cannot invite users without an active subscription.' : null}
                            label="Invite users"
                            onClick={() => {
                                ReactGA.event({
                                    category: 'User',
                                    action: 'Invite',
                                    label: 'Open Editor',
                                });
                                this.props.history.push('/user/create');
                            }}
                        />
                    </Content>
                </Content>
                <Content fullHeightContent>
                    <Table
                        data={this.state.filteredUsers}
                        columns={columns}
                        pagination={true}
                        pageSize={7}
                        nothingFoundText="Sorry, we couldn't find any data yet ..."
                    />
                </Content>
            </div>
        );
    }
}

export default connect('users,showPopup', actions)(Users);

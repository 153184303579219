import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import style from './style.module.scss';
import { customTooltips, addDecimalDivider } from './Tooltip';
import BeaconLink from "../BeaconLink";

const DoughnutChart = (props) => {
  const {
    data, containerStyle, headline, colorDesktop, colorMobile, colorTablet, beaconId
  } = props;
  const config = {
    data: {
      labels: ['Desktop', 'Mobile', 'Tablet'],
      datasets: [{
        label: '# of Votes',
        data: [
          data.desktop,
          data.mobile,
          data.tablet,
        ],
        backgroundColor: [
          colorDesktop,
          colorMobile,
          colorTablet,
        ],
      }],
    },
    options: {
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      cutoutPercentage: 73,
      legend: {
        display: false,
        position: 'right',
      },
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
        mode: 'point',
        yAlign: 'bottom',
        intersect: false,
        custom: (toolTipModel) => customTooltips(toolTipModel, 'doughnutChartTooltip', 'device'),
      },
    },
  };

  const renderLegend = () => (
    <ul className={style.legend}>
      { config.data.labels.map((item) => {
        const index = config.data.labels.indexOf(item);
        return (
          <li className={style.legendItem} key={`legend-${index}`}>
            <span className={style.legendItemIcon} style={{ backgroundColor: config.data.datasets[0].backgroundColor[index] }} />
            {item}
            <span className={style.legendItemNumber}>{addDecimalDivider(config.data.datasets[0].data[index], ',')}</span>
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className={style.doughnutChartWrapper} style={containerStyle}>
      <h2 className={style.chartHeadline}>{beaconId ? (<BeaconLink beaconId={beaconId} label={headline} />) : headline}</h2>
      <div className={style.chart}>
        <Doughnut data={config.data} options={config.options} legend={config.labels} />
      </div>
      <div className={style.legendWrapper}>
        { renderLegend() }
      </div>
      <div id="doughnutChartTooltip" className="tooltip" />
    </div>
  );
};

export default DoughnutChart;

import React from 'react';
import { connect } from 'react-unistore';
import style from './popup.module.scss';
import actions from '../../actions';

const PopUp = ({ children, onClose, togglePopup }) => (
  <div className={style.overlay}>
    <div className={style.popup}>
      <img
        className={style.cancel}
        onClick={() => {
          if (onClose) {
            onClose();
          } else {
            togglePopup();
          }
        }}
        src="/icons/cancel.svg"
        alt=""
      />
      {children}
    </div>
  </div>
);

export default connect('', actions)(PopUp);

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { People } from 'react-ionicons'
import { connect } from 'react-unistore'
import actions from '../../../actions'
import { LinkhawkServiceEntityNotFound } from '../../../api/common'
import { getUsers, updateUser } from '../../../api/users'
import Button from '../../../components/Button'
import Content from '../../../components/Content'
import Form from '../../../components/Form'
import ParagraphRequiredField from '../../../components/ParagraphRequiredField'
import Spinner from '../../../components/Spinner'
import {
    getCurrentUserId,
    isCanceled,
} from '../../../util/user-management'
import { validateRequiredFields } from '../../../util/validates'
import ConfirmDeleteUserPopup from "../../../components/PopUp/ConfirmDeleteUserPopup";

class UserEdit extends Component {
    constructor(props) {
        super(props)

        const { match } = props
        const { id } = match.params
        this.state = {
            id,
            user: null,
            notFound: false,
            usergroups: [],
            rolesAll: [
                {
                    id: 'ROLE_USER',
                    name: 'User',
                },
                {
                    id: 'ROLE_SUPER_ADMIN',
                    name: 'Admin',
                },
            ],
            role: '',
            // temporaryNumber: 'TODO',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'User Edit',
        })
        const { groups } = this.props
        const { id } = this.state
        try {
            const user = await getUsers(id)

            const usergroupIds = user.usergroups.map((group) => group.id)
            const usergroups = groups
                .filter((item) => usergroupIds.includes(item.id))
                .reduce((acc, item) => [...acc, item.id], [])
            const role = user.roles[0] || 'ROLE_USER'

            this.setState({
                usergroups,
                role,
                user,
            })
        } catch (err) {
            if (err instanceof LinkhawkServiceEntityNotFound) {
                this.setState({ notFound: true })
            }
        }
    }

    handleChange(event) {
        const { user } = this.state
        this.setState({
            user: {
                ...user,
                [event.target.name]: event.target.value,
            },
        })
    }

    handleSelectChange(items, name) {
        const value =
            items !== null
                ? Array.isArray(items)
                    ? items.map((item) => item.value)
                    : items.value
                : []
        this.setState({ [name]: value })
    }

    async handleSubmit(event) {
        event.preventDefault()
        const { toggleLoading, toggleSnackbar, refreshAdminData, history } =
            this.props
        const { id, usergroups, user, role } = this.state
        const { firstname, lastname, email, enabled } = user

        const payload = JSON.stringify({
            firstname,
            lastname,
            email,
            role,
            usergroups,
            enabled,
        })

        // TODO: do not access dom directly in react app!!!
        const requiredFields = Array.from(
            document.querySelectorAll('[data-required]')
        )
        const error = validateRequiredFields(requiredFields)

        if (!error) {
            toggleLoading(true)
            try {
                ReactGA.event({
                    category: 'User',
                    action: 'Edit',
                    label: 'Save Changes',
                })
                await updateUser(id, payload)
                // refetch users and groups
                await refreshAdminData()
                toggleLoading(false)
                toggleSnackbar('User updated successfully.', 'success')
                history.push('/user')
            } catch (err) {
                toggleLoading(false)
                toggleSnackbar(err.message)
            }
        } else {
            toggleSnackbar('Please complete all required fields!')
        }
    }

    render() {
        const subscriptionCanceled = isCanceled()
        const currentUserId = getCurrentUserId()
        const {
            groups,
            togglePopup,
            history,
            showPopup,
            toggleLoading,
            refreshAdminData,
            toggleSnackbar,
        } = this.props
        const { id, user, usergroups, rolesAll, role, notFound } = this.state

        if (notFound) {
            return (
                <Content>
                    <h3>The User was not found.</h3>
                </Content>
            )
        }
        if (user === null) {
            return <Spinner show />
        }

        return (
            <div>
                <Content wide headerGrid noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-default">
                            <People
                                color={'currentColor'}
                                title={'Users'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            Edit user
                        </h1>
                        <div>
                        </div>
                    </div>
                </Content>
                <Content fullHeightContent>
                    <Form>
                        <Form.Input
                            name="firstname"
                            value={user.firstname}
                            onInput={this.handleChange}
                            type="text"
                            data-required
                            errorMessage="Firstname must not be empty."
                            label="First name*"
                            width={33}
                            placeholder="First Name"
                        />

                        <Form.Input
                            name="lastname"
                            value={user.lastname}
                            onInput={this.handleChange}
                            type="text"
                            data-type="text"
                            errorMessage="Lastname must not be empty."
                            label="Last name"
                            width={33}
                            placeholder="Last Name"
                        />

                        <Form.Input
                            name="email"
                            value={user.email}
                            onInput={this.handleChange}
                            type="text"
                            data-required
                            data-type="email"
                            errorMessage="Email address must not be empty."
                            label="Email address*"
                            width={33}
                            placeholder="Email address"
                        />

                        <Form.SelectField
                            name="role"
                            label="Role"
                            width={50}
                            options={rolesAll}
                            value={role}
                            placeholder="Select"
                            onChange={(items) =>
                                this.handleSelectChange(items, 'role')
                            }
                        />

                        <Form.MultiSelectField
                            name="usergroups"
                            label="Usergroup"
                            width={50}
                            options={groups}
                            value={usergroups}
                            placeholder="Choose"
                            onChange={(items) =>
                                this.handleSelectChange(items, 'usergroups')
                            }
                        />

                        <Content bar noPadding>
                            <ParagraphRequiredField />
                            <Content button>
                                {!subscriptionCanceled &&
                                    currentUserId !== parseInt(id) && (
                                        <Button
                                            secondary
                                            label="Delete User"
                                            onClick={() => {
                                                ReactGA.event({
                                                    category: 'User',
                                                    action: 'Delete',
                                                    label: 'Open Confirmation Modal',
                                                })
                                                togglePopup()
                                            }}
                                            type="reset"
                                        />
                                    )}
                                <Button
                                    label="Cancel"
                                    secondary
                                    onClick={() => history.push('/user')}
                                />
                                <Button
                                    label="Save changes"
                                    onClick={this.handleSubmit}
                                />
                            </Content>
                        </Content>
                    </Form>

                    {showPopup && (
                        <ConfirmDeleteUserPopup
                            refreshAdminData={refreshAdminData}
                            togglePopup={togglePopup}
                            id={id}
                            toggleSnackbar={toggleSnackbar}
                            toggleLoading={toggleLoading}
                        />
                    )}
                </Content>
            </div>
        )
    }
}

UserEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.node,
        }).isRequired,
    }).isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    toggleLoading: PropTypes.func.isRequired,
    refreshAdminData: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    toggleSnackbar: PropTypes.func.isRequired,
    togglePopup: PropTypes.func.isRequired,
    showPopup: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
}

export default connect('groups,showPopup', actions)(UserEdit)

import React, { Component } from 'react'
import ReactGA from 'react-ga'
import Content from '../../../components/Content'
import MultiSteps from '../../../components/MultiSteps'
import Step1 from './Step1/EditStep1'
import Step2 from './Step2/EditStep2'

class LinksEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalSteps: 2,
            currentStep: 1,
            disabledSteps: [2],
        }

        this.setStep = this.setStep.bind(this)
        this.handleDisabledSteps = this.handleDisabledSteps.bind(this)
    }

    componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Link Edit',
        })
    }

    setStep(currentStep) {
        this.setState({ currentStep })
    }

    handleDisabledSteps(disabledSteps) {
        this.setState({ disabledSteps })
    }

    render() {
        const { totalSteps, currentStep, disabledSteps } = this.state
        const getCurrentStep = (step) => this.setState({ currentStep: step })
        const getState = (state) => this.setState({ link: state.link })
        const { id } = this.props.match.params
        const { history } = this.props

        return (
            <Content>
                <MultiSteps
                    totalSteps={totalSteps}
                    currentStep={currentStep}
                    disabledSteps={disabledSteps}
                    getCurrentStep={getCurrentStep}
                />

                {currentStep === 1 && (
                    <Step1
                        setStep={this.setStep}
                        handleDisabledSteps={this.handleDisabledSteps}
                        id={id}
                        getState={getState}
                        history={history}
                    />
                )}

                {currentStep === 2 && (
                    <Step2 id={id} getState={this.state} history={history} />
                )}
            </Content>
        )
    }
}

export default LinksEdit

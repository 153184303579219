import React from 'react';

import style from './style.module.scss';

const Input = (props) => {
  const label = props.label || 'Label';
  const type = props.type === 'checkbox' ? 'checkbox' : 'radio';
  let className = `${style.input_wrapper} ${props.type === 'checkbox' ? 'checkbox' : 'radio'} ${props.className ? props.className : ''}`;

  if (props.width) {
    className += `width_${props.width}`;
  }

  return (
    <div className={className} style={props.containerStyle} onClick={props.onClick}>
      <div className={style.checkboxWrapper}>
        <input
          type={type}
          id={props.id}
          name={props.name}
          checked={props.checked}
          defaultChecked={props.defaultChecked}
          onChange={props.onChange}
        />
        <div className={style.checkmark} />
      </div>
      <label htmlFor={props.id}>
        {label}

        {props.children}

      </label>
    </div>
  );
};

export default Input;

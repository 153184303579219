import React, { Component } from 'react'
import { connect } from 'react-unistore'
import ReactGA from 'react-ga'
import actions from '../../../../actions'
import Content from '../../../../components/Content'
import style from '../style.module.scss'
import Tag from '../../../../components/Tag'
import Copy from '../../../../components/Copy/Copy'
import Button from '../../../../components/Button'
import { MegaphoneOutline } from 'react-ionicons'

class Step2 extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { campaign } = this.props

        return (
            <div>
                <Content wide headerGrid noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-campaigns">
                            <MegaphoneOutline
                                color={'currentColor'}
                                title={'Campaigns'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            Well done!
                        </h1>
                    </div>
                </Content>
                <Content wide>
                    <h2>Campaign successfully created.</h2>

                    <table className={`ReactTable ${style.tableGeneral}`}>
                        <tbody>
                            <tr>
                                <td>Title:</td>
                                <td>{campaign.title}</td>
                            </tr>
                            <tr>
                                <td>Target URL:</td>
                                <td>
                                    <a
                                        className={style.shorten}
                                        href={campaign.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {campaign.url}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Group:</td>
                                <td>{campaign.usergroup}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className={`ReactTable ${style.tableShortlinks}`}>
                        <thead>
                            <tr>
                                <th>Shortlinks</th>
                                <th>Tags</th>
                            </tr>
                        </thead>
                        <tbody>
                            {campaign.links.map((shortUrl, index) => (
                                <tr key={shortUrl + '_' + index}>
                                    <td className={style.shortlinks}>
                                        <Copy bg={'white'} value={shortUrl.url} />
                                    </td>
                                    <td className={style.tags}>
                                        <div
                                            className={
                                                style['scroll-container']
                                            }
                                        >
                                            {shortUrl.tags.map((tag) => (
                                                <Tag label={tag.name} />
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Content
                        button
                        style={{ display: 'inline-block', marginTop: '40px' }}
                    >
                        <Button
                            label="Edit campaign"
                            secondary
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Campaign',
                                    action: 'Edit',
                                    label: 'Open Editor (from Success Page)',
                                })
                                this.props.history.push(
                                    `/campaigns/edit/${campaign.id}`
                                )
                            }}
                        />
                        <Button
                            label="Campaigns overview"
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Campaign',
                                    action: 'Back to Overview',
                                    label: 'Back to Campaign Overview',
                                })
                                this.props.history.push(
                                    `/campaigns?highlightId=${campaign.id}`
                                )
                            }}
                        />
                    </Content>
                </Content>
            </div>
        )
    }
}

export default connect('campaign', actions)(Step2)

import React from 'react'
import { Line } from 'react-chartjs-2'
import style from './style.module.scss'
import { addDecimalDivider, customTooltips } from './Tooltip'
import BeaconLink from '../BeaconLink'

const LineChart = (props) => {
    const {
        data,
        containerStyle,
        headline,
        totalHits,
        keyColor,
        totalInteractions,
        beaconId,
    } = props

    function getDateLabel(datestring) {
        const date = new Date(datestring)
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()

        return `${day}.${month}.`
    }

    const datasets = Array.isArray(data)
        ? data.map((dataItem) => ({
            data: Object.values(dataItem.data),
            borderColor: dataItem.color,
            label: dataItem.label,
            fill: false,
        }))
        : [
            {
                data: Object.values(data),
                borderColor: keyColor,
                label: 'Clicks',
                fill: false,
            },
        ]

    const config = {
        data: {
            labels: Array.isArray(data)
                ? Object.keys(data[0].data).map((key) => getDateLabel(key))
                : Object.keys(data).map((key) => getDateLabel(key)),
            datasets,
        },
        options: {
            legend: {
                display: Array.isArray(data),
                position: 'bottom',
                align: 'start',
                labels: {
                    boxWidth: 14,
                    fontSize: 12,
                    fontColor: '#222222',
                    fontFamily: 'Roboto Slab',
                    padding: 30,
                    borderWidth: 0,
                },
            },
            title: {
                display: false,
            },
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            userCallback(label) {
                                // , index, labels
                                // when the floored value is the same as the value we have a whole number
                                if (Math.floor(label) === label) {
                                    return label
                                }
                            },
                        },
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            borderColor: '#EBEBEB',
                        },
                        ticks: {
                            maxRotation: 0,
                        },
                    },
                ],
            },
            tooltips: {
                enabled: false,
                mode: 'nearest',
                yAlign: 'bottom',
                intersect: false,
                custom: (toolTipModel) =>
                    customTooltips(toolTipModel, 'lineChartTooltip', 'date'),
            },
        },
    }

    return (
        <div className={style.lineChartWrapper} style={!config.options.legend.display ? { containerStyle, paddingBottom: '3rem' } : containerStyle}>
            <div className={style.headlineWrapper}>
                <h2 className={style.chartHeadline}>
                    {beaconId ? (
                        <BeaconLink beaconId={beaconId} label={headline} />
                    ) : (
                        headline
                    )}
                </h2>
            </div>
            <div className={style.chart}>
                <Line
                    data={config.data}
                    options={config.options}
                    legend={config.labels}
                    style={{ height: window.innerHeight * 0.8 }}
                />
                {totalHits >= 0 && (
                    <div className={style.totalClicks} style={config.options.legend.display ? { marginTop: '-1.5rem' } : { marginTop: '.5rem' }}>
                        <span className={style.totalClicks_label}>
                            <i>Total clicks: </i>
                            {totalHits
                                ? addDecimalDivider(totalHits, '.')
                                : '0'}
                        </span>
                        {totalInteractions !== undefined ? (
                            <span
                                className={style.totalClicks_label}
                                style={{ paddingLeft: '26px' }}
                            >
                                <i>Total interactions: </i>
                                {totalInteractions
                                    ? addDecimalDivider(totalInteractions, '.')
                                    : '0'}
                            </span>
                        ) : null}
                    </div>
                )}
            </div>
            <div id="lineChartTooltip" className="tooltip" />
        </div>
    )
}

export default LineChart

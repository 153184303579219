/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-unistore'
import actions from '../../../actions'
import * as user from '../../../util/user-management'
import UserRoute from '../UserRoute'
import Spinner from '../../Spinner'

class AdminRoute extends Component {
    componentDidMount() {
        this.prefetchData()
    }

    async prefetchData() {
        const { refreshAdminData, prefetchingAdminData, prefetchedAdminData } =
            this.props

        if (
            user.isLoggedIn() &&
            !prefetchingAdminData &&
            !prefetchedAdminData
        ) {
            await refreshAdminData()
        }
    }

    render() {
        const {
            component: Component,
            prefetchedAdminData,
            ...rest
        } = this.props
        return (
            <Route
                {...rest}
                render={(props) => {
                    if (user.isLoggedIn()) {
                        if (user.isAdmin()) {
                            // Components in this route expect this data to be available
                            if (!prefetchedAdminData) {
                                return <Spinner show />
                            }
                            return (
                                <div className="lh__layout-inner">
                                    <UserRoute
                                        {...props}
                                        component={Component}
                                    />
                                </div>
                            )
                        }
                        return (
                            <Redirect
                                to={{
                                    pathname: '/',
                                    state: { from: props.location },
                                }}
                            />
                        )
                    }

                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    )
                }}
            />
        )
    }
}

AdminRoute.propTypes = {
    refreshAdminData: PropTypes.func.isRequired,
    prefetchingAdminData: PropTypes.bool.isRequired,
    prefetchedAdminData: PropTypes.bool.isRequired,
    component: PropTypes.node.isRequired,
}

export default connect(
    'prefetchingAdminData,prefetchedAdminData',
    actions
)(AdminRoute)

import React, {Component} from "react";
import PropTypes from "prop-types";
import {addDecimalDivider} from "../Charts/Tooltip";
import Content from "../Content";
import LineChart from "../Charts/LineChart";
import BarChart from "../Charts/BarChart";
import chartStyle from "../Charts/style.module.scss";
import DoughnutChart from "../Charts/DoughnutChart";
import ListChart from "../Charts/ListChart";
import Top5Chart from "../Charts/Top5Chart";

class AnalyticsMetrics extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {
            dateStart,
            dateEnd,
            analytics,
            history,
        } = this.props


        function getDescriptionText() {
            return (
                <p className="information-bar">
                    {'You have '}
                    <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(
                        analytics.linksCount,
                        '.'
                    )} links`}</span>
                    {', '}
                    <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(
                        analytics.campaignsCount,
                        '.'
                    )} campaigns`}</span>
                    {' with '}
                    <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(
                        analytics.hitsTotals.data.total,
                        '.'
                    )} clicks`}</span>
                    {' in the selected time range.'}
                </p>
            )
        }

        return (
            <>
                <Content wide>
                <div>
                    {analytics ? getDescriptionText() : null}
                </div>
            </Content>
                {analytics ? (
                    <div>
                        <LineChart
                            data={[
                                {
                                    label: 'Links',
                                    data: analytics.linkClicks.hitsByDay.data,
                                    color: '#318692',
                                },
                                {
                                    label: 'Campaigns',
                                    data: analytics.campaignClicks.hitsByDay.data,
                                    color: '#3bd5c3',
                                },
                            ]}
                            totalHits={analytics.hitsTotals.data.total}
                            headline="Clicks by day"
                            beaconId={148}
                            keyColor="rgba(199, 149, 208, 1.0)"
                            containerStyle={{marginTop: 20}}
                        />
                        <div
                            className={chartStyle.chartGroup}
                            style={{marginBottom: 30}}
                        >
                            {analytics.linkClicks.top5Links && (
                                <Top5Chart
                                    dateStart={dateStart}
                                    dateEnd={dateEnd}
                                    title="Links"
                                    beaconId={152}
                                    history={history}
                                    containerStyle={{marginTop: 30}}
                                    data={analytics.linkClicks.top5Links.data.list}
                                />
                            )}
                            {analytics.campaignClicks.top5Campaigns && (
                                <Top5Chart
                                    title="Campaigns"
                                    beaconId={153}
                                    dateStart={dateStart}
                                    dateEnd={dateEnd}
                                    history={history}
                                    containerStyle={{marginTop: 30}}
                                    data={analytics.campaignClicks.top5Campaigns.data.list}
                                />
                            )}
                        </div>
                        <BarChart
                            data={[
                                {
                                    label: 'Links',
                                    data: analytics.linkClicks.hitsByHourOfDay
                                        .data,
                                    color: '#318692',
                                },
                                {
                                    label: 'Campaigns',
                                    data: analytics.campaignClicks
                                        .hitsByHourOfDay.data,
                                    color: '#3bd5c3',
                                },
                            ]}
                            headline="Clicks by time"
                            beaconId={149}
                            keyColor="rgba(199, 149, 208, 1.0)"
                            containerStyle={{marginTop: 30}}
                        />
                        <div
                            className={chartStyle.chartGroup}
                            style={{marginBottom: 30}}
                        >
                            <DoughnutChart
                                data={analytics.hitsTotals.data}
                                headline="Devices"
                                beaconId={150}
                                colorDesktop="#318692"
                                colorMobile="#3DB3C4"
                                colorTablet="#B9DFE5"
                                containerStyle={{marginTop: 30}}
                            />
                            {analytics.hitsByReferrer ? (
                                <ListChart
                                    data={analytics.hitsByReferrer.data}
                                    headline="Top 5 visitor sources by clicks"
                                    beaconId={150}
                                    containerStyle={{marginTop: 30}}
                                />
                            ) : null}
                        </div>

                        <Content
                            style={{color: 'grey', fontSize: '12px', marginTop: '1rem'}}
                        >{`* displayed analytics include links, campaigns for your group(s): ${analytics.usergroupNames.join(
                            ', '
                        )}`}</Content>
                    </div>
                ) : null}
            </>

        )
    }
}

AnalyticsMetrics.propTypes = {
    analytics: PropTypes.shape({
        usergroupNames: PropTypes.arrayOf(PropTypes.string),
        linksCount: PropTypes.number,
        campaignsCount: PropTypes.number,
        hitsTotals: PropTypes.shape({
            data: PropTypes.shape({
                total: PropTypes.string,
                desktop: PropTypes.string,
                mobile: PropTypes.string,
                tablet: PropTypes.string,
            }),
        }),
        linkClicks: PropTypes.shape({
            top5Links: PropTypes.shape({
                data: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.object),
                })
            }),
            hitsByDay: PropTypes.shape({
                data: PropTypes.object, // date-string => hits (int)
            }),
            hitsByHourOfDay: PropTypes.shape({
                data: PropTypes.object, // hour-string => hits (int)
            })
        }),
        campaignClicks: PropTypes.shape({
            top5Campaigns: PropTypes.shape({
                data: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.object),
                }),
            }),
            hitsByDay: PropTypes.shape({
                data: PropTypes.object, // date-string => hits (int)
            }),
            hitsByHourOfDay: PropTypes.shape({
                data: PropTypes.object, // hour-string => hits (int)
            })
        }),
        hitsByReferrer: PropTypes.shape({
            data: PropTypes.object, // referer string => clicks (int)
        })
    }),
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default AnalyticsMetrics;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import Form from '../../../components/Form';
import { getGroups, updateGroup } from '../../../api/groups';
import Input from '../../../components/Form/components/Input';
import { validateRequiredFields } from '../../../util/validates';
import Button from '../../../components/Button';
import actions from '../../../actions';
import Content from '../../../components/Content';
import Spinner from '../../../components/Spinner';
import { updateUser } from '../../../api/users';
import Table from '../../../components/Table/DynamicTable';
import { PeopleOutline } from 'react-ionicons';

class GroupsEdit extends Component {
    constructor(props)
    {
        super(props);
        const { id } = props.match.params;
        this.state = {
            id,
            group: null,
            userFilter: '',
            includeGroupUsers: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateGroupName = this.updateGroupName.bind(this);
        this.toggleGroupUserInclude = this.toggleGroupUserInclude.bind(this);
        this.removeUserFromGroup = this.removeUserFromGroup.bind(this);
        this.addUserToGroup = this.addUserToGroup.bind(this);
        this.filterUsers = this.filterUsers.bind(this);
    }

    async componentDidMount()
    {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Groups Edit',
        });
        const { id } = this.state;
        const group = await getGroups(id);
        this.setState({
            group,
        });
    }

    handleChange(event)
    {
        if (event.target.name === 'name') {
            this.setState({
                group: {
                    ...this.state.group,
                    [event.target.name]: event.target.value,
                },
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    }

    toggleGroupUserInclude()
    {
        const { includeGroupUsers } = this.state;
        this.setState({ includeGroupUsers: !includeGroupUsers });
    }

    async addUserToGroup(userId)
    {
        const {
            users, getUsers, toggleSnackbar, toggleLoading,
        } = this.props;
        const { group } = this.state;
        const { id } = group;
        const updatedUser = users
            .filter((u) => userId === u.id)
            .map((u) => {
                const groups = u.usergroups.map((usergroup) => usergroup.id);
                const role = u.roles.includes('ROLE_SUPER_ADMIN') ? 'ROLE_SUPER_ADMIN' : 'ROLE_USER';
                return {
                    ...u,
                    role,
                    usergroups: [
                        ...groups,
                        id,
                    ],
                };
            })[0];

        toggleLoading(true);
        ReactGA.event({
            category: 'Group',
            action: 'Edit',
            label: 'Add User',
        });
        try {
            await updateUser(userId, JSON.stringify(updatedUser));
            await getUsers();
            toggleSnackbar('Updated successfully.');
        } catch (err) {
            toggleSnackbar(err.message);
        }

        toggleLoading(false);
    }

    async removeUserFromGroup(userId)
    {
        const {
            users, getUsers, toggleSnackbar, toggleLoading,
        } = this.props;
        const { group } = this.state;
        const { id } = group;
        const user = users
            .filter((u) => userId === u.id)
            .map((u) => {
                const usergroups = u.usergroups
                    .filter((usergroup) => usergroup.id !== id)
                    .map((usergroup) => usergroup.id);
                const role = u.roles.includes('ROLE_SUPER_ADMIN') ? 'ROLE_SUPER_ADMIN' : 'ROLE_USER';
                return {
                    ...u,
                    role,
                    usergroups,
                };
            })[0];

        toggleLoading(true);
        ReactGA.event({
            category: 'Group',
            action: 'Edit',
            label: 'Remove User',
        });
        try {
            await updateUser(user.id, JSON.stringify(user));
            await getUsers();
            toggleSnackbar('Updated successfully.');
        } catch (err) {
            toggleSnackbar(err.message);
        }
        toggleLoading(false);
    }

    filterUsers()
    {
        const { users } = this.props;
        const { includeGroupUsers, group, userFilter } = this.state;
        const filteredUsers = !includeGroupUsers
            ? users
            : users.filter(
                (user) => user.usergroups.map((usergroup) => usergroup.name).includes(group.name),
            );

        const filter = userFilter.toLowerCase();
        return filteredUsers.filter((user) => {
            const matchesGroup = user.usergroups.filter(
                (usergroup) => usergroup.name.toLowerCase().indexOf(filter) !== -1,
            ).length > 0;
            return ((user.username && user.username.toLowerCase().indexOf(filter) !== -1)
                || (user.firstname && user.firstname.toLowerCase().indexOf(filter) !== -1)
                || (user.lastname && user.lastname.toLowerCase().indexOf(filter) !== -1)
                || matchesGroup);
        });
    }

    async updateGroupName(event)
    {
        event.preventDefault();
        const {
            toggleSnackbar, toggleLoading, refreshAdminData,
        } = this.props;
        const { group, id } = this.state;
        const payload = JSON.stringify({ name: group.name });
        // TODO: do not access dom directly in react app!!!
        const requiredFields = Array.from(document.querySelectorAll('[data-required]'));
        const error = validateRequiredFields(requiredFields);

        if (!error) {
            toggleLoading(true);
            try {
                ReactGA.event({
                    category: 'Group',
                    action: 'Edit',
                    label: 'Save Name',
                });
                await updateGroup(id, payload);
                // refetch users and groups
                await refreshAdminData();
                toggleLoading(false);
                toggleSnackbar('Successfully updated group name.');
            } catch (err) {
                toggleLoading(false);
                toggleSnackbar(err.message);
            }
        } else {
            toggleSnackbar('Please complete all required fields!');
        }
    }

    renderUserTable()
    {
        const { group, userFilter, includeGroupUsers } = this.state;

        const columns = [
            {
                Header: 'Name',
                id: 'group',
                accessor: (d) => (
                    <div>
                        <h4>{d.username}</h4>
                        <p>
                            {d.firstname}
                            {' '}
                            {d.lastname}
                        </p>
                    </div>
                ),
        },
            {
                Header: 'Groups',
                id: 'currentGroup',
                accessor: (d) => (
                    <div>
                        {
                            d.usergroups.map((usergroup) => (
                                <p key={usergroup.id}>{usergroup.name}</p>
                            ))
                        }
                    </div>
                ),
        },
            {
                Header: '',
                id: 'addToGroup',
                style: {
                    textAlign: 'right',
                    width: "170px"
                },
                className: 'icons',
                accessor: (d) => {
                    const user = d;
                    const userId = user.id;
                    const inThisGroup = user.usergroups.filter(
                        (usergroup) => usergroup.id === group.id,
                    ).length > 0;

                    return (
                        <div style={{ display: 'inline-flex', width: '100%' }}>
                            {
                                inThisGroup
                                    ? (
                                        <div className={style.alignContent}>
                                            <img
                                                style={{ width: '45px' }}
                                                onClick={() => this.removeUserFromGroup(userId)}
                                                src="/icons/button-minus.svg"
                                                alt=""
                                            />
                                            <p>Remove user</p>
                                        </div>
                                    )
                                    : (
                                        <div className={style.alignContent}>
                                            <img
                                                style={{ width: '45px' }}
                                                onClick={() => this.addUserToGroup(userId)}
                                                src="/icons/button-plus.svg"
                                                alt=""
                                            />
                                            <p>Add user</p>
                                        </div>
                                    )
                            }
                        </div>
                    );
                },
        },
        ];

        return (
            <div style={{ marginTop: 20 }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 40,

                    }}
                >
                    <h2 style={{ flexBasis: 'auto', flexShrink: 0, flexGrow: 2, margin: 0 }}>Add or remove users</h2>
                    <Input
                        name="userFilter"
                        placeholder="Search User"
                        label=""
                        onInput={this.handleChange}
                        value={userFilter}
                        containerStyle={{
                            flexBasis: 400, flexShrink: 0, flexGrow: 0, marginBottom: 0, margin: '0 1rem 0 auto',
                            }}
                    />
                    <Form.Checkbox
                        name="includeGroupUsers"
                        id="includeGroupUsers"
                        label="Show only users from the current group"
                        width={100}
                        onChange={this.toggleGroupUserInclude}
                        type="checkbox"
                        checked={includeGroupUsers}
                        containerStyle={{
                            flexBasis: 'auto',
                            flexShrink: 0,
                            flexGrow: 0,
                            width: 'auto',
                            }}
                    />
                </div>

                <Table
                    data={this.filterUsers()}
                    columns={columns}
                    defaultPageSize={7}
                    pagination={true}
                    nothingFoundText="Sorry, we couldn't find any data yet ..."
                />
            </div>
        );
    }

    render()
    {
        const { group } = this.state;
        if (group === null) {
            return (<Spinner show />);
        }
        return (
            <div>
                <Content wide headerGrid noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-default">
                            <PeopleOutline
                                color={'currentColor'}
                                title={'Groups'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            Edit group
                        </h1>
                    </div>
                </Content>
                <Content fullHeightContent>
                    <Form styles={{ alignItems: 'flex-end' }}>
                        <Form.Input
                            name="name"
                            value={group.name}
                            onInput={this.handleChange}
                            type="text"
                            data-required
                            errorMessage="Name must not be empty."
                            label="Name"
                            width={75}
                            placeholder="Name"
                        />
                        <Button
                            style={{ height: '50px', marginBottom: '30px' }}
                            label="Save name"
                            width={25}
                            onClick={this.updateGroupName}
                        />
                    </Form>
                    {
                        this.renderUserTable()
                    }
                    <Content bar>
                        <Link to="/groups">&lt; Groups</Link>
                    </Content>

                </Content>
            </div>
        );
    }
}

GroupsEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.node,
        }).isRequired,
    }).isRequired,
toggleSnackbar: PropTypes.func.isRequired,
toggleLoading: PropTypes.func.isRequired,
updateUser: PropTypes.func.isRequired,
refreshAdminData: PropTypes.func.isRequired,
users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default connect('users', actions)(GroupsEdit);

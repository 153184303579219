import React, { useState, useEffect } from 'react';
import Content from "../../../components/Content";
import { connect } from "react-unistore";
import actions from "../../../actions";
import {confirmEmail} from "../../../api/email";
import style from './../style.module.scss';
import { Link } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import ResendEmailConfirm from "../../../components/ResendEmailConfirm";

const EmailConfirm = (props) => {
    const [state, setState] = useState({
        loading: false,
        error: false,
        success: false,
        email: ''
    });

    const query = useQuery();

    useEffect(() => {
        (async() => {
            const token = query.get('token');
            try {
                await confirmEmail({ token });
                setState({ ...state, loading: false, success: true });
            } catch (e) {
                setState({ ...state, loading: false, error: true });
                props.toggleSnackbar('Failed to confirm email.');
            }
        })();
    }, []);


    const { loading, error, success } = state;
    return (
        <section id={style.email}>
            <Content small center>
                <div>
                    {loading && <h2>Confirming email...</h2>}
                    {error && <h2>Failed to confirm email</h2>}
                    {success && <h2>Email confirmed</h2>}
                    <div>
                        {error && (
                            <div>
                                <p>
                                    Your email confirmation link is invalid or your account is already confirmed.<br/>
                                    Please enter your email address below and we will send you a new confirmation email.
                                </p>
                                <ResendEmailConfirm email={state.email} />
                            </div>
                        )}
                    </div>
                    <p>
                        {success && (
                            <span>
                                Your email has been confirmed successfully. You can now <Link to="/login">login</Link>.
                            </span>
                        )}
                    </p>
                </div>
            </Content>
        </section>
    );
}

export default connect('user', actions)(EmailConfirm);

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { connect } from 'react-unistore';
import Content from '../../components/Content';
import { AddOutline, Pencil, PeopleOutline } from 'react-ionicons';
import Table from '../../components/Table/DynamicTable';
import SearchBar from "../../components/FilterBar/SearchBar";

function GroupsList(props) {
    const [filteredGroups, setFilteredGroups] = useState(props.groups);
    const history = props.history

    useEffect(() => {
        // INITIAL State loading , fired only once
        window.scrollTo(0, 0);
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Groups',
        });
    }, [])

    useEffect(() => {
        setFilteredGroups(props.groups);
    }, [props.groups]);

    const handleSearch = (searchTerm) => {
        const lowerCaseTerm = searchTerm.toLowerCase();
        const filtered = props.groups.filter(group =>
            group.name.toLowerCase().includes(lowerCaseTerm)
        );
        setFilteredGroups(filtered);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                id: 'name',
                accessor: (d) => (
                    <p>{d.name}</p>
                )
            },
            {
                Header: 'User count',
                id: 'count',
                style: {
                    width: '200px',
                },
                accessor: (d) => (
                    <p>{d.users.length}</p>
                ),
            },
            {
                Header: 'Edit',
                id: 'edit',
                style: {
                    width: '3rem',
                },
                accessor: (d) => (
                    <Link
                        to={`/groups/edit/${d.id}`}
                        onClick={() => {
                            ReactGA.event({
                                category: 'Group',
                                action: 'Edit',
                                label: 'Open Editor',
                            });
                        }}
                    >
                        <Pencil />
                    </Link>
                ),
            },
        ],[]
    )

    return (
        <div>
            <Content wide headerGrid noMargin>
                <div className="headerRow">
                    <h1 className="underline__lh-default">
                        <PeopleOutline
                            color={'currentColor'}
                            title={'Groups'}
                            width="2rem"
                            height="2rem"
                            className="icon"
                        />
                        Groups
                    </h1>
                    <div>
                    </div>
                </div>
                <div className="headerActions" style={{
                    display: 'flex',
                    justifySelf: 'flex-end'
                }}>
                    <SearchBar
                        placeholder="Search Groups..."
                        onSearch={handleSearch}
                    />
                    <div
                        className="addButton groups"
                        onClick={() => {
                            ReactGA.event({
                                category: 'Group',
                                action: 'Create',
                                label: 'Open Group Editor',
                            });
                            history.push('/groups/create');
                        }}
                    >
                        <AddOutline
                            color={'currentColor'}
                            title={'Create new group'}
                            className="btn_icon_rounded_pulse"
                        />
                    </div>
                </div>
            </Content>
            <Content fullHeightContent>
                <div>
                    <Table
                        data={filteredGroups}
                        columns={columns}
                        pagination={true}
                        pageSize={7}
                        nothingFoundText="Sorry, we couldn't find any data yet ..."
                    />
                </div>
            </Content>
        </div>
    );


}

GroupsList.propTypes = {
    groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    location: PropTypes.shape({}).isRequired,
};

export default connect('groups')(GroupsList);
import PropTypes from 'prop-types'

const initialState = {
    branding: null,
    campaigns: {
        items: [],
        currentPage: 0,
        pages: -1,
        pageSize: 10,
        fetched: false,
    },
    links: {
        items: [],
        currentPage: 0,
        pages: -1,
        pageSize: 12,
        fetched: false,
        sortDirection: 'desc',
        sortedField: 'created_at'
    },
    user: {},
    users: [],
    groups: [],
    usergroups: [],
    domains: [],
    invoices: [],
    tags: [],
    error: false,
    loading: false,
    invoicesLoading: true,
    snackbarVisible: false,
    snackbarText: '',
    snackbarType: 'error',
    showPopup: false,
    showCreateNavigation: false,
    refreshed: false,
    prefetchedData: false,
    prefetchingData: false,
    prefetchingAdminData: false,
    prefetchedAdminData: false,
    networkError: null,
}

export const campaignTypes = {}

export const linkTypes = {}

export const stateTypes = {
    branding: PropTypes.shape({}).isRequired,
    campaigns: {
        items: PropTypes.arrayOf(PropTypes.shape({ campaignTypes })).isRequired,
        currentPage: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        fetched: PropTypes.bool.isRequired,
    },
    links: {
        items: PropTypes.arrayOf(PropTypes.shape({ linkTypes })).isRequired,
        currentPage: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        fetched: PropTypes.bool.isRequired,
    },
    user: PropTypes.shape({}).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    usergroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    error: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    invoicesLoading: PropTypes.bool.isRequired,
    snackbarVisible: PropTypes.bool.isRequired,
    snackbarText: PropTypes.string.isRequired,
    snackbarType: PropTypes.string.isRequired,
    showPopup: PropTypes.bool.isRequired,
    showCreateNavigation: PropTypes.bool.isRequired,
    refreshed: PropTypes.bool.isRequired,
    prefetchedData: PropTypes.bool.isRequired,
    prefetchingData: PropTypes.bool.isRequired,
    prefetchingAdminData: PropTypes.bool.isRequired,
    prefetchedAdminData: PropTypes.bool.isRequired,
}

export const routerTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    location: PropTypes.shape({}).isRequired,
}

export const historyTypes = PropTypes.shape({
    push: PropTypes.func.isRequired,
}).isRequired

export default initialState

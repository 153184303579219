import React from 'react'

const AutocompleteInput = (props) => {
    const className = `input_wrapper width_${props.width} ${
        props.className ? props.className : ''
    } ${props.error ? 'error' : ''}`
    return (
        <div className={className} style={props.containerStyle}>
            {props.label && <label>{props.label}</label>}
            <div>
                <input
                    type="text"
                    name={props.name}
                    autoComplete="true"
                    placeholder={props.placeholder}
                    value={props.value}
                    {...props}
                    disabled={props.isDisabled}
                />
                {props.error && (
                    <div className="error_message">{props.errorMessage}</div>
                )}
            </div>
        </div>
    )
}

export default AutocompleteInput

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { connect } from 'react-unistore'
import { Link } from 'react-router-dom'
import actions from '../../actions'
import Button from '../../components/Button'
import Content from '../../components/Content'
import {
    getExternalDomains,
    getDomains,
    updateAccountDefaultDomain,
} from '../../api/domains'
import { getAccount } from '../../api/account'
import Spinner from '../../components/Spinner'
import Form from '../../components/Form'
import {GlobeOutline, Pencil, TrashBinOutline} from 'react-ionicons';
import Table from '../../components/Table/DynamicTable'

class Domains extends Component {
    constructor(props) {
        super(props)
        this.state = {
            externalDomains: null,
            loading: false,
            defaultDomainSelection: null,
        }

        this.updateDefaultDomain = this.updateDefaultDomain.bind(this)
    }

    async componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Domains',
        })

        const domains = await getDomains()
        const account = await getAccount()
        const externalDomains = await getExternalDomains()
        this.setState({
            externalDomains,
            domains,
            defaultDomainSelection:
                account.defaultDomain !== null
                    ? account.defaultDomain.id
                    : null,
        })
    }

    async updateDefaultDomain() {
        const { toggleSnackbar, refreshUserData } = this.props
        const { defaultDomainSelection } = this.state
        this.setState({ loading: true })

        try {
            await updateAccountDefaultDomain(defaultDomainSelection)
            await refreshUserData()
            toggleSnackbar('Default domain updated', true)
        } catch (e) {
            toggleSnackbar(e.message)
        }
        this.setState({ loading: false })
    }

    render() {
        const { history } = this.props
        const { externalDomains, domains, loading, defaultDomainSelection } =
            this.state

        if (externalDomains === null || loading === true) {
            return <Spinner show />
        }

        const getStatus = (status) => {
            // eslint-disable-next-line default-case
            switch (status) {
                case 'AVAILABLE':
                    return (
                        <>
                            <span className='statusLabel' style={{ backgroundColor: '#def7ec', color: '#014737' }}>
                                available
                            </span>
                        </>
                    )
                case 'PENDING_VERIFICATION':
                    return (
                        <>
                            <span className='statusLabel' style={{ backgroundColor: '#f8b4b4', color: '#771d1d' }}>
                                update nameservers!
                            </span>
                        </>
                    )
                case 'PENDING_DELETION':
                    return (
                        <>
                            <span className='statusLabel' style={{ backgroundColor: '#fdf6b2', color: '#723b13' }}>
                                deletion in progress
                            </span>
                        </>
                    )
                case 'PENDING_ACTIVATION':
                    return (
                        <>
                            <span className='statusLabel' style={{ backgroundColor: '#fdf6b2', color: '#723b13' }}>
                                activation in progress
                            </span>
                        </>
                    )
            }
        }

        const columns = [
            {
                Header: 'Name',
                id: 'name',
                accessor: (d) => (
                    <Link
                        to={d.status === 'PENDING_DELETION' ? `/domains/details/${d.id}/delete`:`/domains/details/${d.id}/activate`}
                        onClick={() => {
                            ReactGA.event({
                                category: 'Domain',
                                action: 'Details',
                                label: 'Open Editor',
                            })
                        }}
                    >
                        {d.name}
                    </Link>
                ),
            },
            {
                Header: 'Status',
                id: 'status',
                accessor: (d) => <p>{getStatus(d.status)}</p>,
                headerClassName: 'sort',
            },
            {
                Header: 'Details',
                id: 'details',
                accessor: (d) => (
                    <div className="between">
                        <Link
                            to={`/domains/details/${d.id}/activate`}
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Domain',
                                    action: 'Details',
                                    label: 'Open Editor',
                                })
                            }}
                        >
                            <Pencil />
                        </Link>
                        {(d.status === 'AVAILABLE' || d.status === 'PENDING_VERIFICATION') && (
                            <Link
                                to={`/domains/details/${d.id}/delete`}
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'Domain',
                                        action: 'Details',
                                        label: 'Open Editor',
                                    })
                                }}
                            >
                                <TrashBinOutline />
                            </Link>
                        )}
                    </div>
                ),
                style: {
                    width: '4rem',
                },
            },
        ]

        return (
            <div>
                <Content wide headerGrid noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-default">
                            <GlobeOutline
                                color={'currentColor'}
                                title={'Domains'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            Domains
                        </h1>
                        <div>
                        </div>
                    </div>
                </Content>
                <Content fullHeightContent>
                    <div
                        style={{
                            backgroundColor: '#EFEFEF',
                            width: '100%',
                            borderRadius: '6px',
                            padding: '1rem',
                        }}
                    >
                        <h2>Default Domain</h2>
                        <p>Please select the default domain for your account.</p>
                        <br />
                        <Form>
                            <Form.SelectField
                                label="Default Domain"
                                name="default_domain"
                                width={100}
                                options={domains}
                                onChange={(option) =>
                                    this.setState({
                                        defaultDomainSelection: option.value,
                                    })
                                }
                                value={defaultDomainSelection}
                            />
                        </Form>
                        <Content button>
                            <Button
                                label="Save"
                                onClick={this.updateDefaultDomain}
                            />
                        </Content>
                    </div>
                    <h2>Custom Domains</h2>
                    <p>
                        {`Here you can add domains you registered for use with linkhawk.
                            You can only use domains registered for this specific purpose as
                            you will be required to point the domain's nameservers to linkhawk.com.`}
                    </p>
                    <Table
                        data={externalDomains}
                        columns={columns}
                        pageSize={7}
                        nothingFoundText="Start by adding a custom domain."
                    />

                    <Content button>
                        <Button
                            label="Add domain"
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Domain',
                                    action: 'Add',
                                    label: 'Open Editor',
                                })
                                history.push('/domains/add')
                            }}
                        />
                    </Content>
                </Content>
            </div>
        )
    }
}

Domains.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    location: PropTypes.shape({}).isRequired,
    toggleSnackbar: PropTypes.func.isRequired,
    refreshUserData: PropTypes.func.isRequired,
}

export default connect(null, actions)(Domains)

import React, { Component } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { connect } from 'react-unistore'
import moment from 'moment'
import momenttz from 'moment-timezone'
import style from './style.module.scss'
import Content from '../../components/Content'
import actions from '../../actions'
import { getCurrentUserName } from '../../util/user-management'
import {
    getAnalytics,
} from '../../api/analytics'
import Spinner from '../../components/Spinner'
import {
    AddOutline,
    AnalyticsOutline,
    CalendarOutline,
} from 'react-ionicons'
import AnalyticsMetrics from "../../components/Analytics";

class Welcome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            analytics: null,
            dateStart: null,
            dateEnd: null,
        }
        this.toggleCreateNavigation = this.toggleCreateNavigation.bind(this)
    }

    async componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Dashboard',
        })
        const dateStart = moment()
            .startOf('day')
            .add(12, 'hours')
            .subtract(14, 'days')
            .format('YYYY-MM-DD')
        const dateEnd = moment()
            .startOf('day')
            .subtract(12, 'hours')
            .format('YYYY-MM-DD')

        this.setState({ dateStart })
        this.setState({ dateEnd })

        const analytics = await getAnalytics(
            { dateStart, dateEnd },
            'hits_by_day,hits_totals,hits_by_hour_of_day,top_5_links,top_5_campaigns,hits_by_referrer'
        )

        this.setState({ analytics })
    }

    toggleCreateNavigation() {
        const { toggleCreateNavigation } = this.props
        toggleCreateNavigation()
    }

    render() {
        const { analytics, dateStart, dateEnd } = this.state
        // eslint-disable-next-line no-unused-vars
        const { history } = this.props

        if (analytics === null) {
            return <Spinner show />
        }

        return (
            <section id={style.welcome}>
                <Content wide headerGrid>
                    <div className="headerRow">
                        <h1>Hi {getCurrentUserName()}.</h1>
                        <div>
                            <h2>Welcome to your Linkhawk account.</h2>
                            <p>
                                Linkhawk is the prime place to manage links like
                                a champ! Create short URLs with a custom domain
                                that enhances UX and drives authentic
                                engagement.
                            </p>
                        </div>
                    </div>
                    <div
                        className="addButton sidebar"
                        onClick={this.toggleCreateNavigation}
                    >
                        <AddOutline
                            color={'currentColor'}
                            title={'Add'}
                            className="btn_icon_rounded_pulse"
                        />
                    </div>
                </Content>
                <Content>
                    <main className={style.main}>
                        <div className={style.box}>
                            <div className={style.box_content}>
                                <AnalyticsOutline
                                    color={'currentColor'}
                                    title={'Links'}
                                    width="2rem"
                                    height="2rem"
                                />
                                <div className={style.flexRow}>
                                    <h3 className="underline__lh-default">Analytics</h3>

                                    <div className={style.calendarRow}>
                                        <span>{momenttz.tz.guess()}</span>
                                        <CalendarOutline
                                            color={'currentColor'}
                                            title={'Calendar'}
                                            width="1.25rem"
                                            height="1.25rem"
                                        />
                                        <span className="bold">
                                            {moment(dateStart).format(
                                                'DD.MM.Y'
                                            )}
                                            &nbsp;-&nbsp;
                                            {moment(dateEnd).format('DD.MM.Y')}
                                        </span>
                                    </div>
                                </div>

                                <Content>
                                    {analytics && (
                                        <>
                                            <AnalyticsMetrics analytics={analytics} history={history} dateStart={dateStart} dateEnd={dateEnd} />
                                        </>
                                    )}
                                </Content>
                            </div>
                        </div>
                    </main>
                </Content>
            </section>
        )
    }
}

Welcome.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    toggleCreateNavigation: PropTypes.func.isRequired,
    closeCreateNavigation: PropTypes.func.isRequired,
}

export default connect('showCreateNavigation', actions)(Welcome)

import moment from 'moment-timezone';
import { get, post } from './common';
import { downloadCsvFile } from '../util/helperFunctions';

/**
 * Get capmaigns from DB
 *
 * @param {Object} data
 */
export const getCampaigns = async (data, dates = null) => {
  const dateFilter = dates != null
    ? {
      startDate: dates.dateStart,
      endDate: dates.dateEnd,
    }
    : undefined;

  return get(`/api/campaigns/${data}`, dateFilter);
};

/**
 * Get an unused shortcode
 * @param {number} amount The amount of codes to be retrieved, defaults to null
 */
export const getCode = async (amount = null) => {
  const resource = amount !== null ? `/api/code?amount=${amount}` : '/api/code';
  return get(resource);
};

export const downloadCsv = async (resource, id, dates) => {
  let res = {};

  const dateFilter = {
    startDate: dates.dateStart,
    endDate: dates.dateEnd,
    timezone: moment.tz.guess(),
  };

  res = await get(`/api/${resource}/${id}/csv-download`, dateFilter);

  const blob = await res.blob();
  return downloadCsvFile(blob);
};

export const downloadCsvFromSelectedLinks = async (dates, ids) => {
  let res = {};

  res = await post(`/api/links/csv-download?startDate=${dates.dateStart}&endDate=${dates.dateEnd}&timezone=${moment.tz.guess()}`, 'POST', JSON.stringify({ids}))

  const blob = await res.blob();
  return downloadCsvFile(blob);
}


export const downloadCsvFromAllLinksFiltered = async (dates, search, archived) => {
  let res = {};

  const archivedParam = archived? 1: 0

  const filter = {
    startDate: dates.dateStart,
    endDate: dates.dateEnd,
    timezone: moment.tz.guess(),
    search: search,
    archived: archivedParam,
  };

  res = await get(`/api/links/csv-download`, filter);

  const blob = await res.blob();
  return downloadCsvFile(blob);
};

export const validateEmail = async (email) => post('/api/public/unique-email', 'POST', JSON.stringify({ email }));
export const validateCode = async (code) => post('/api/unique-code', 'POST', JSON.stringify({ code }));
/**
 * Get random background from pictures.linkhawk.com
 */

export const getLatestLinks = async () => get('/api/links/latest-10');

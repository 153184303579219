import moment from 'moment-timezone';
import { get, post } from './common';

export const updateCampaign = async (id, payload) => post(`/api/campaigns/${id}`, 'POST', payload);

export const getCampaigns = async (id, dates = null) => {
  const dateFilter = dates != null
    ? {
      startDate: dates.dateStart,
      endDate: dates.dateEnd,
    }
    : {};

  return get(`/api/campaigns/${id}`, { ...dateFilter, timezone: moment.tz.guess() });
};

export const getCampaignAnalytics = async (id, dates = null, stats = null, region = null) => {
  const filter = {
    startDate: dates.dateStart,
    endDate: dates.dateEnd,
    include: stats || 'hits_by_day,hits_totals,hits_by_hour_of_day,hits_by_referrer',
    timezone: moment.tz.guess(),
    region,
  };

  return get(`/api/campaigns/${id}/analytics`, filter);
};

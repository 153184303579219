import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'

import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import actions from '../../actions'
import { connect, useStore } from 'react-unistore'
import { getLinkList } from '../../actions/links'
import BeaconLink from '../../components/BeaconLink'
import Copy from '../../components/Copy/Copy'
import moment from 'moment'
import Content from '../../components/Content'
import {
    AddOutline,
    AlertCircleOutline,
    CloseOutline,
    CodeDownloadOutline,
    DocumentTextOutline,
    DownloadOutline,
    FileTrayFullOutline,
    GridOutline,
    LinkOutline,
    List,
    Search,
    Star,
} from 'react-ionicons'
import Button from '../../components/Button'
import style from './style.module.scss'
import { clearErrors } from '../../util/validates'
import Pagination from '../../components/Pagination'
import ReactTooltip from 'react-tooltip'
import ActionToggle from '../../components/ActionToggle/ActionToggle'
import { addDecimalDivider } from '../../components/Charts/Tooltip'
import useDebounce from '../../hooks/debounce'
import Table from '../../components/Table/DynamicTable'
import DateFilter from '../../components/FilterBar/DateFilter'
import FilterBar from '../../components/FilterBar'
import CSVDownloadButton from '../../components/CSVDownloadButton/CSVDownloadButton'
import TrendlineChart from '../../components/TrendlineChart/TrendlineChart'
import FavoriteToggle from '../../components/FavoriteToggle/FavoriteToggle'
import LinkGrid from '../../components/Grid/LinkGrid'
import { getLinkAlerts } from '../../api/links'
import { useLocation } from 'react-router-dom'
function LinkList(props) {
    // static vars
    const store = useStore()
    const history = props.history
    const [pageSize, setPageSize] = useState(props.links.pageSize)
    const search = useLocation().search;
    const highLightId = new URLSearchParams(search).get('highlightId');

    // vars
    const [initial, setInitial] = useState(true) // for initial rendering check
    const [fetched, setFetched] = useState(props.links.fetched) // for api-response checks
    const [listView, setListView] = useState(true) // switch between List- and Gridview
    const [exportSelectionActive, setExportSelectionActive] = useState(false);
    const [exportAllActive, setExportAllActive] = useState(false)
    const [exportVisible, setExportVisible] = useState(false)
    const [data, setData] = useState(props.links.items) // getting and handling data from api
    const [alerts, setAlerts] = useState(0);
    const [queryString, setQueryString] = useState('')
    const [currentPage, setCurrentPage] = useState(props.links.currentPage)
    const [pages, setPages] = useState(props.links.pages)
    const [archive, setArchive] = useState(false) // filtering for archived
    const [favorite, setFavorite] = useState(false) // filtering for favorites
    const [something, setSomething] = useState(1) // update when fav changes
    const [alertFilter, setAlertFilter] = useState(false) // filtering for alerts only
    const [sortedField, setSortedField] = useState(props.links.sortedField)
    const [sortDirection, setSortDirection] = useState(props.links.sortDirection)

    const debouncedSearchTerm = useDebounce(queryString, 1200, true)

    // DATES 
    const initialDateStart = moment()
        .startOf('day')
        .add(12, 'hours')
        .subtract(30, 'days')
        .format('YYYY-MM-DD')

    const initialDateEnd = moment()
        .startOf('day')
        .subtract(12, 'hours')
        .format('YYYY-MM-DD')

    const [dateStart, setDateStart] = useState(initialDateStart)
    const [dateEnd, setDateEnd] = useState(initialDateEnd)
    const selectedRange = useState('30d')
    const [selectedRows, setSelectedRows] = useState([]);

    /**
     * Function to handle the api request to get all links with different params
     * TODO: we should use useCallback here ... Problem is, that it rerenders on every stateChange
     */
    const getLinkListData = () => {
        setFetched(false)
        getLinkList(store, data, currentPage, pageSize, queryString, archive, sortedField, sortDirection, favorite, alertFilter)
            .then((res) => {
                // console.log(res.links)
                setData(res.links.items)
                setCurrentPage(res.links.currentPage)
                setFetched(res.links.fetched)
                setPages(res.links.pages)
                store.setState({ loading: false })
            })
            .catch((err) => console.warn(err))
    }

    const getAlertsForLinks = () => {
        getLinkAlerts().then((res) => {
            setAlerts(res.alerts)
        }).catch((err) => console.warn(err))
    }

    useEffect(() => {
        // INITIAL State loading , fired only once
        setInitial(false)
        setArchive(false)
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Links',
        })
        clearErrors()
        window.scrollTo(0, 0);
    }, [])

    // INITIAL EFFECT 
    // We get the initial set of links 
    // We get the initial set of alerted links aswell

    useEffect(() => {
        if (initial === true) {
            getLinkListData()
            getAlertsForLinks()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Searching and it's effect based on debounce

    const onChangeQueryString = (e) => {
        const queryString = e.target.value
        setQueryString(queryString)
    }

    useEffect(() => {
        if (debouncedSearchTerm && initial === false) {
            getLinkListData()
        }
    }, [debouncedSearchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

    // Filtering and it's effect watching archive setter to change

    const onChangeFilterArchive = (e) => {
        setArchive(!e)
    }

    useEffect(() => {
        if (initial === false) {
            getLinkListData()
            getAlertsForLinks()
        }
    }, [archive]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeFilterFavorite = (e) => {
        setFavorite(!e)
    }

    const favChanged = () => {
        setSomething(Math.floor((Math.random() * 1000) + 1))
    }

    useEffect(() => {
        if (initial === false) {
            getLinkListData()
        }
    }, [favorite, something]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeFilterAlter = (e) => {
        setAlertFilter(!e)
    }

    useEffect(() => {
        if (initial === false) {
            getLinkListData()
        }
    }, [alertFilter])

    // CHANGE VIEWLEVELS 
    const onChangeView = (e) => {
        setListView(e);
        if (!e) [
            closeExportBar()
        ]
    }

    // EXPORT FEATURE 

    const onChangeExportAll = () => {
        setExportAllActive(!exportAllActive)
        setExportSelectionActive(false)
        setExportVisible(!exportAllActive)
    }

    const onChangeExportSelection = () => {
        setExportAllActive(false)
        setExportSelectionActive(!exportSelectionActive)
        setExportVisible(!exportSelectionActive)
    }

    const closeExportBar = () => {
        setExportAllActive(false)
        setExportSelectionActive(false)
        setExportVisible(false)
    }

    useEffect(() => {
        if (!listView) {
            setExportAllActive(false)
            setExportSelectionActive(false)
        }
    }, [listView]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (exportAllActive) {
            setListView(true)
        }
    }, [exportAllActive]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (exportSelectionActive) {
            setListView(true)
            setPageSize(pages * pageSize) // get all links to be displayed for user-selection
        } else {
            setPageSize(props.links.pageSize) // fall back to initial pageSize
        }
    }, [exportSelectionActive]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (initial === false) {
            getLinkListData()
        }
    }, [pageSize]) // eslint-disable-line react-hooks/exhaustive-deps

    // TODO: setState inside async is a bad idea ... working for now, but needs fix
    const reactTableInstance = useCallback((instance) => {
        let selected = instance.selectedFlatRows.map(
            d => d.original.id
        )
        let diff = selected.filter(x => !selectedRows.includes(x))
        if (diff.length > 0) {
            setSelectedRows(selected ? selected : [])
        }
    }, [selectedRows]);

    useEffect(() => {
        // console.log(selectedRows)
    }, [selectedRows])

    const updateDate = async (dates) => {
        const dateStart = moment(dates.from).format('YYYY-MM-DD')
        const dateEnd = moment(dates.to).format('YYYY-MM-DD')
        setDateStart(dateStart);
        setDateEnd(dateEnd);
    }

    useEffect(() => {
        // console.log('time changed')
    }, [dateStart, dateEnd])

    // Sorting by click on TableHeader TD

    const onChangeSortTable = useCallback((sort, direction) => {
        if (direction === 'asc') {
            setSortDirection('desc')
            store.setState({ sortDirection: 'desc' })
        } else {
            store.setState({ sortDirection: 'asc' })
            setSortDirection('asc')
        }
        store.setState({ sortedField: sort })
        setSortedField(sort)
    }, [store])

    useEffect(() => {
        if (initial === false) {
            getLinkListData()
        }
    }, [sortDirection, sortedField]) // eslint-disable-line react-hooks/exhaustive-deps

    // Page Change
    const pageChange = (e) => {
        setCurrentPage(e)
    }

    useEffect(() => {
        if (initial === false) {
            getLinkListData()
        }
    }, [currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'notes',
                Cell: ({ value }) => (
                    <div>
                        {value ?
                            <>
                                <span className={style.notes} data-tip={value} data-event="click">
                                    <DocumentTextOutline
                                        color={'currentColor'}
                                        title={'Links'}
                                        width="1.25rem"
                                        height="1.25rem"
                                        className="icon" />
                                </span>
                                <ReactTooltip
                                    place="right"
                                    effect="solid"
                                    className="customTooltip"
                                    multiline={true}
                                    getContent={dataTip => (
                                        <div className={style.notesTooltip}>
                                            <span>Note:</span>
                                            <p>{dataTip}</p>
                                        </div>
                                    )}
                                    clickable={true}
                                    delayHide={200}
                                    globalEventOff="click"
                                >
                                </ReactTooltip>
                            </>
                            : ' ' // make sure to have an empty string for cell rendering!
                        }
                    </div>
                ),
                style: {
                    width: '3rem',
                    textAlign: 'center',
                }
            },
            {
                Header: '',
                id: 'favs',
                accessor: (d) => (
                    <FavoriteToggle
                        id={d.id}
                        isArchive={d.archived}
                        fetched={fetched}
                        favorite={d.favorite}
                        changeFavoriteState={() => favChanged()}
                    />
                )
            },
            {
                Header: 'User',
                accessor: 'user',
                Cell: ({ value }) => (
                    <div className={style.overflowUser}>
                        {value}
                    </div>
                )
            },
            {
                Header: (
                    <div>
                        <span className={style.sortable} onClick={() => onChangeSortTable('created_at', sortDirection)}>Date</span>
                    </div>
                ),
                accessor: 'created_at',
                Cell: ({ value }) => (
                    <div className={style.mono}>
                        {moment(value).format('DD.MM.YY')}
                    </div>
                ),
            },
            {
                Header: (
                    <div>
                        <span className={style.sortable} onClick={() => onChangeSortTable('title', sortDirection)}>Title / Target Url</span>
                        <BeaconLink beaconId={143} />
                    </div>
                ),
                id: 'title',
                accessor: (d) => (
                    <div className={style.overflow}>
                        <span className={style.title}>{d.title}</span>
                        <br />
                        <a
                            href={`${d.targetUrl}`}
                            target="_blank"
                            rel="noreferrer noopener"
                            title={`${d.title}`}
                            className={style.link}
                        >
                            {d.targetUrl}
                        </a>
                    </div>
                ),
            },
            {
                Header: 'Link',
                id: 'shortUrl',
                accessor: (d) => (
                    <div className={style.copyCell} >
                        <Copy value={`${d.shortUrl}`} archive={d.archived} />
                    </div>
                ),
            },
            {
                Header: 'Groups',
                accessor: 'usergroup',
                Cell: ({ value }) => (
                    <div className={style.groups}>{value}</div>
                ),
            },
            {
                Header: (
                    <div>
                        <span className={style.sortable} onClick={() => onChangeSortTable('views', sortDirection)}>Clicks</span>
                        <BeaconLink beaconId={144} />
                    </div>
                ),
                accessor: 'views',
                Cell: ({ value }) => (
                    <div className={style.clicks}>
                        {addDecimalDivider(value, ',')}
                    </div>
                ),
            },
            {
                Header: (
                    <>
                        {!exportVisible &&
                            <div>
                                Trends
                            </div>
                        }
                    </>
                ),
                id: 'trends',
                accessor: (d) => (
                    <>
                        {!exportVisible &&
                            <TrendlineChart id={d.id} />
                        }
                    </>
                ),
            },
            {
                Header: '',
                id: 'actions',
                accessor: (d) => (
                    <ActionToggle
                        id={d.id}
                        archive={d.archived}
                        favorite={d.favorite}
                        changeArchiveState={setArchive}
                        type="links"
                    />
                ),
            },
        ],
        [sortDirection, onChangeSortTable, pageChange]
    )
    // console.log('data', data, fetched)
    if (fetched && !data.length && initial) {
        return (
            <>
                <div>
                    <Content small>
                        <div className="headerRow">
                            <h1 className="underline__lh-links">
                                <LinkOutline
                                    color={'currentColor'}
                                    title={'Links'}
                                    width="2rem"
                                    height="2rem"
                                    className="icon"
                                />
                                Create a shortlink
                            </h1>
                        </div>
                    </Content>
                    <Content small center>
                        <p>
                            Keep your audience engaged by linking your latest
                            news, promoting a line of products, or announcing an
                            event.
                        </p>
                        <Button
                            label="Create link"
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Link',
                                    action: 'Create',
                                    label: 'Open Editor',
                                })
                                history.push('/links/create')
                            }}
                            width={200}
                        />
                    </Content>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div>
                    <Content wide headerGrid noMargin>
                        <div className="headerRow">
                            <h1 className="underline__lh-links">
                                <LinkOutline
                                    color={'currentColor'}
                                    title={'Links'}
                                    width="2rem"
                                    height="2rem"
                                    className="icon"
                                />
                                Links
                            </h1>
                            <div>
                                <h2>This is your personal link overview</h2>
                                <p>
                                    Keep your audience engaged by linking your
                                    latest news, promoting a line of products,
                                    or announcing an event.
                                </p>
                            </div>
                        </div>
                        <div
                            className={`${style.absoluteAdd} addButton links`}
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Link',
                                    action: 'Create',
                                    label: 'Open Editor',
                                })
                                history.push('/links/create')
                            }}
                        >
                            <AddOutline
                                color={'currentColor'}
                                title={'Create new link'}
                                className="btn_icon_rounded_pulse"
                            />
                        </div>
                        <div className={style.exportContainer}>
                            <div className={`${style.filterContainer} ${exportAllActive ? `${style.active}` : ''
                                }`}>
                                <span>Export All</span>
                                <span data-tip="CSV Download of all current Links" data-for="export">
                                    <DownloadOutline
                                        className={`${style.filterIcon} ${exportAllActive ? `${style.active}` : ''
                                            }`}
                                        color={'currentColor'}
                                        title={'Export All'}
                                        onClick={() =>
                                            onChangeExportAll(exportAllActive)
                                        }
                                    />
                                </span>
                            </div>
                            <div className={`${style.filterContainer} ${exportSelectionActive ? `${style.active}` : ''
                                }`}>
                                <span>Export Selection</span>
                                <span data-tip="CSV Download of all user-selected links" data-for="export">
                                    <CodeDownloadOutline
                                        className={`${style.filterIcon} ${exportSelectionActive ? `${style.active}` : ''
                                            }`}
                                        color={'currentColor'}
                                        title={'Export'}
                                        onClick={() =>
                                            onChangeExportSelection(exportSelectionActive)
                                        }
                                    />
                                </span>
                            </div>
                        </div>
                    </Content>
                    <Content fullHeightContent>
                        <div className={style.searchContainer}>
                            <div className={style.inputWithIcon}>
                                <Search
                                    className={style.inputIcon}
                                    color={'currentColor'}
                                    title={'Search'}
                                />
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Search"
                                    value={queryString}
                                    onChange={onChangeQueryString}
                                />
                            </div>
                            <div className={style.filterContainer}>
                                <span className={style.mr}>Filter</span>
                                <span data-tip="Filter by favored links" data-for="filterfav">
                                    <Star
                                        className={`${style.filterIcon} ${favorite ? `${style.active}` : ''
                                            }`}
                                        color={'currentColor'}
                                        title={'Favorite'}
                                        onClick={() =>
                                            onChangeFilterFavorite(favorite)
                                        }
                                    />
                                </span>
                                <span data-tip="Filter by archived Links" data-for="filter">
                                    <FileTrayFullOutline
                                        className={`${style.filterIcon} ${archive ? `${style.active}` : ''
                                            }`}
                                        color={'currentColor'}
                                        title={'Archive'}
                                        onClick={() =>
                                            onChangeFilterArchive(archive)
                                        }
                                    />
                                </span>
                                <span className={style.border}></span>
                                <span>
                                    <span className={`${alerts > 0 ? `${style.filterWithDescription}` : ``}`}>
                                        <span data-tip="Filter by alerts" data-for="filterAlert">
                                            <AlertCircleOutline
                                                className={`${style.filterIcon} ${alertFilter ? `${style.danger}` : ''
                                                    } ${alerts > 0 ? `${style.alertAvailable}` : ``}`}
                                                color={'currentColor'}
                                                title={'Alerts'}
                                                onClick={() =>
                                                    onChangeFilterAlter(alertFilter)
                                                }
                                            />
                                        </span>
                                        {alerts > 0 &&
                                            <span className={style.alertCount}>We have found a total of <span className={style.mono}>{alerts}</span> alerts</span>
                                        }
                                    </span>
                                </span>
                            </div>
                            <div className={style.viewContainer}>
                                <span data-tip="Activate Gridview" data-for="viewLevel">
                                    <GridOutline
                                        className={`${style.filterIcon} ${!listView ? `${style.active}` : ''
                                            }`}
                                        color={'currentColor'}
                                        title={'Archive'}
                                        onClick={() =>
                                            onChangeView(false)
                                        }
                                    />
                                </span>
                                <span data-tip="Activate Listview" data-for="viewLevel">
                                    <List
                                        className={`${style.filterIcon} ${listView ? `${style.active}` : ''
                                            }`}
                                        color={'currentColor'}
                                        title={'Listview'}
                                        onClick={() =>
                                            onChangeView(true)
                                        }
                                    />
                                </span>
                            </div>

                        </div>
                        {exportVisible &&
                            <div className={style.exportWithFilter}>
                                <FilterBar>
                                    <DateFilter
                                        selectedRange={selectedRange}
                                        dateStart={dateStart}
                                        dateEnd={dateEnd}
                                        callback={(dates) => updateDate(dates)}
                                    />
                                    <div className={style.flexRow}>
                                        <CSVDownloadButton
                                            type="linklist"
                                            ids={selectedRows}
                                            dateStart={dateStart}
                                            dateEnd={dateEnd}
                                            archived={archive}
                                            search={queryString}
                                        />
                                        <span data-tip="CSV Download of all user-selected links" data-for="export">
                                            <CloseOutline
                                                className={`${style.filterIcon}`}
                                                color={'currentColor'}
                                                onClick={() =>
                                                    closeExportBar()
                                                }
                                            />
                                        </span>
                                    </div>
                                </FilterBar>
                            </div>
                        }
                        <div>
                            {listView ?
                                <Table
                                    showAlerts={true}
                                    columns={columns}
                                    data={data}
                                    getInstanceCallback={(instance) => reactTableInstance(instance)}
                                    onFetchData={fetched}
                                    archived={archive}
                                    sortedField={sortedField}
                                    sortDirection={sortDirection}
                                    export={exportSelectionActive}
                                    nothingFoundText="Sorry, we couldn't find any links which meet your'e criteria."
                                    highLightId={highLightId}
                                />
                                : <LinkGrid
                                    columns={columns}
                                    data={data}
                                    fetched={fetched}
                                    archived={archive}
                                    sortedField={sortedField}
                                    sortDirection={sortDirection}
                                    export={exportSelectionActive}
                                    setArchive={setArchive.bind(this)}
                                    favChanged={favChanged.bind(this)}
                                    nothingFoundText="Sorry, we couldn't find any links which meet your'e criteria."
                                    highLightId={highLightId}
                                />
                            }
                            {pages > 1 ? (
                                <Pagination
                                    pages={pages}
                                    page={currentPage}
                                    onPageChange={(e) => pageChange(e)}
                                    previousText="PREV"
                                    nextText="NEXT"
                                />
                            ) : null}
                        </div>
                    </Content>
                    <ReactTooltip id="filter"
                        place="top"
                        effect="solid"
                        className="customTooltip"
                    />
                    <ReactTooltip id="filterfav"
                        place="top"
                        effect="solid"
                        className="customTooltip"
                    />
                    <ReactTooltip id="filterAlert"
                        place="top"
                        effect="solid"
                        className="customTooltip"
                    />
                    <ReactTooltip id="viewLevel"
                        place="top"
                        effect="solid"
                        className="customTooltip"
                    />
                    <ReactTooltip id="export"
                        place="top"
                        effect="solid"
                        className="customTooltip"
                    />
                </div>
            </>
        )
    }
}

LinkList.propTypes = {
    links: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        currentPage: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        fetched: PropTypes.bool.isRequired,
        sortDirection: PropTypes.string.isRequired,
        sortedField: PropTypes.string.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    getLinks: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    location: PropTypes.shape({ search: PropTypes.string.isRequired })
        .isRequired,
}

export default connect('links,loading', actions)(LinkList)

import React, {useEffect} from "react";

import { stripeCustomerPortalSession} from "../../api/payment";
import Spinner from "../../components/Spinner";

const StripeCustomerPortalRedirect = () => {
    useEffect(() => {
        stripeCustomerPortalSession().then((response) => {
            window.location.href = response.url;
        });
    }, []);

    return (
        <div>
            <Spinner show={true}/>
        </div>
    );
}

export default StripeCustomerPortalRedirect;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import owasp from 'owasp-password-strength-test';
import { connect } from 'react-unistore';

import { LinkhawkServiceEntityNotFound } from '../../api/common';
import style from './style.module.scss';
import { changePassword, setPassword } from '../../api/users';
import actions from '../../actions';
import Button from '../Button';
import Form from '../Form';
import Content from '../Content';
import Spinner from '../Spinner';

export const PASSWORD_VALIDATOR_MODE_CHANGE = 0;
export const PASSWORD_VALIDATOR_MODE_SET_NEW_PASSWORD = 1;

// eslint-disable-next-line react/prefer-stateless-function
class PasswordValidator extends Component {
    constructor(props)
    {
        super(props);

        this.state = {
            oldPassword: '',
            newPassword: '',
            newPasswordValidation: '',
        // show errors only after user has submitted the form
            showPasswordValidationErrors: false,
            validateLengthSuccess: false,
            validateSpecialCharSuccess: false,
            validateLowerCaseCharSuccess: false,
            validateUpperCaseCharSuccess: false,
            validateNumberSuccess: false,
            loading: false,
        };

        owasp.config({
            allowPassphrases: true,
            maxLength: 64,
            minLength: 8,
            minPhraseLength: 20,
            minOptionalTestsToPass: 4,
        });

        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
    }

    static getClass(valid, showErrors)
    {
        if (valid) {
            return style.valid;
        }
        if (showErrors) {
            return style.error;
        }
        return style.default;
    }

    async submit(event)
    {
        event.preventDefault();

        const {
            oldPassword,
            newPassword,
            newPasswordValidation,
            validateLengthSuccess,
            validateSpecialCharSuccess,
            validateLowerCaseCharSuccess,
            validateUpperCaseCharSuccess,
            validateNumberSuccess,
        } = this.state;

        const {
            token, mode, toggleSnackbar,
        } = this.props;

        this.setState({
            showPasswordValidationErrors: true,
        });

        if (validateLengthSuccess
        && validateSpecialCharSuccess
        && validateLowerCaseCharSuccess
        && validateUpperCaseCharSuccess
        && validateNumberSuccess) {
            if (newPassword === newPasswordValidation) {
                switch (mode) {
                    case PASSWORD_VALIDATOR_MODE_CHANGE:
                        try {
                            this.setState({ loading: true });
                            await changePassword(JSON.stringify({
                                oldPassword,
                                newPassword,
                                newPasswordValidation,
                            }));
                            this.setState({
                                oldPassword: '',
                                newPassword: '',
                                newPasswordValidation: '',
                                showPasswordValidationErrors: false,
                                loading: false,
                            });
                            toggleSnackbar('Well done! Your password was changed successfully!', 'success');
                        } catch (err) {
                            this.setState({
                                oldPassword: '',
                                loading: false,
                            });
                            toggleSnackbar('The old Password appears to be wrong.');
                        }
                    break;
                    case PASSWORD_VALIDATOR_MODE_SET_NEW_PASSWORD:
                        try {
                            this.setState({ loading: true });
                            await setPassword(token, newPassword, newPasswordValidation);
                            this.setState({ loading: false });
                        // history.push('login');
                            toggleSnackbar('Well done! Your password was created successfully!', 'success');
                        } catch (err) {
                            this.setState({ loading: false });
                            if (err instanceof LinkhawkServiceEntityNotFound) {
                                toggleSnackbar('Link expired. Please request new password reset.');
                            } else {
                                toggleSnackbar('Server error.');
                            }
                        }
                    break;
                    default:
                    break;
                }
            } else {
                toggleSnackbar('Passwords do not match.');
            }
        }
    }

    validate(pass)
    {
        const result = owasp.test(pass);
        this.setState({
            validateLengthSuccess: !result.failedTests.includes(0),
            validateSpecialCharSuccess: !result.failedTests.includes(6),
            validateLowerCaseCharSuccess: !result.failedTests.includes(3),
            validateUpperCaseCharSuccess: !result.failedTests.includes(4),
            validateNumberSuccess: !result.failedTests.includes(5),
        });
    }

    handleChange(event)
    {
        this.setState({
            [event.target.name]: event.target.value,
        });
        if (event.target.name === 'newPassword') {
            this.validate(event.target.value);
        }
    }

    render()
    {
        const {
            oldPassword, newPassword, newPasswordValidation,
            validateLengthSuccess,
            validateSpecialCharSuccess,
            validateLowerCaseCharSuccess,
            validateUpperCaseCharSuccess,
            validateNumberSuccess,
            showPasswordValidationErrors,
            loading,
        } = this.state;
        const { mode, history } = this.props;

        const allRequirementsFullfilled = validateLengthSuccess
        && validateSpecialCharSuccess
        && validateLowerCaseCharSuccess
        && validateUpperCaseCharSuccess
        && validateNumberSuccess;

        return (

        <div>
            <Form>
            {mode === PASSWORD_VALIDATOR_MODE_CHANGE ? (

                <Form.Input
                label="Old password"
                id="oldPassword"
                type="password"
                name="oldPassword"
                width={100}
                value={oldPassword}
                onChange={this.handleChange}
                error={showPasswordValidationErrors && oldPassword === ''}
                errorMessage={showPasswordValidationErrors && oldPassword === ''
                    ? 'Old password is wrong.' : null}
                />
            ) : null}

            <Form.Input
                label="New password"
                id="newPassword"
                type="password"
                name="newPassword"
                width={100}
                value={newPassword}
                onChange={this.handleChange}
                error={showPasswordValidationErrors && !allRequirementsFullfilled}
                errorMessage={showPasswordValidationErrors && !allRequirementsFullfilled
                    ? 'Please choose password that fullfills all the requirements.' : null}
            />

            <Form.Input
                label="Repeat new password"
                id="newPasswordValidation"
                type="password"
                name="newPasswordValidation"
                className="no-margin"
                width={100}
                value={newPasswordValidation}
                onChange={this.handleChange}
                error={showPasswordValidationErrors && newPassword !== newPasswordValidation}
                errorMessage={showPasswordValidationErrors && newPassword !== newPasswordValidation
                    ? 'Passwords do not match' : null}
            />

            <Content bar>
                {mode === PASSWORD_VALIDATOR_MODE_CHANGE && (
                    <Button label="Cancel" secondary onClick={() => history.push('/')} />
                )}

                <Button
                label={mode === PASSWORD_VALIDATOR_MODE_CHANGE ? 'Change password' : 'Save password'}
                type="button"
                submitButtonOnClick={this.submit}
                />
            </Content>

            <div className={style.validator_box}>
                <p className={PasswordValidator.getClass(
                    validateLengthSuccess,
                    showPasswordValidationErrors,
                )}
                >
                Minimum 8 characters
                </p>
                <p className={PasswordValidator.getClass(
                    validateLowerCaseCharSuccess,
                    showPasswordValidationErrors,
                )}
                >
                1 lowercase character
                </p>
                <p className={PasswordValidator.getClass(
                    validateUpperCaseCharSuccess,
                    showPasswordValidationErrors,
                )}
                >
                1 uppercase character
                </p>
                <p className={PasswordValidator.getClass(
                    validateSpecialCharSuccess,
                    showPasswordValidationErrors,
                )}
                >
                1 special character

                </p>
                <p className={PasswordValidator.getClass(
                    validateNumberSuccess,
                    showPasswordValidationErrors,
                )}
                >
                At least 1 number
                </p>
            </div>

            </Form>
            <Spinner show={loading} />
        </div>

        );
    }
}

PasswordValidator.defaultProps = {
    token: null,
};

PasswordValidator.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    token: PropTypes.string,
    toggleSnackbar: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
};

export default connect(null, actions)(PasswordValidator);

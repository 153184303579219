/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

// eslint-disable-next-line react/prefer-stateless-function
export default class FilterBar extends Component {
    render() {
        const { children } = this.props
        return <div className={style.filterbar}>{children}</div>
    }
}

FilterBar.propTypes = {
    children: PropTypes.node.isRequired,
}

import React, { Component } from 'react';
import Content from "../Content";
import {GlobeOutline} from "react-ionicons";
import Button from "../Button";
import PropTypes from "prop-types";
import MultiSteps from "../MultiSteps";

class ActivateMultiStep extends Component {

    constructor(props) {
        super(props)
    }
    render() {
        const getMultiSteps = (currentStep) => (
            <MultiSteps
                description={['Add', 'DNS', 'Submit', 'Activation', 'Ready']}
                totalSteps={5}
                currentStep={currentStep}
                disabledSteps={[1,2,3,4,5].filter((e) => e !== currentStep)}
            />
        )
        const {domain, onClick: activationHandler, history} = this.props;

        return (
            <div>
                {domain.status === 'PENDING_VERIFICATION' &&
                domain.verification_result === false ? (
                    <>
                        {getMultiSteps(2)}
                        <Content wide headerGrid noMargin>
                            <div className="headerRow">
                                <h1 className="underline__lh-default">
                                    <GlobeOutline
                                        color={'currentColor'}
                                        title={'Domain'}
                                        width="2rem"
                                        height="2rem"
                                        className="icon"
                                    />
                                    Update your DNS NS records
                                </h1>
                                <h3>{domain.name}</h3>
                            </div>
                        </Content>
                        <Content className="custom_dns">
                            {domain.expected_nameservers.length > 0 ? (
                                <>
                                    <p>
                                        In order to use your domain with linkhawk you need
                                        to point your domains nameservers to linkhawk.
                                        <br/>
                                        In your providers DNS settings update the following
                                        NS records:
                                    </p>
                                    <div
                                        style={{
                                            lineHeight: '24px',
                                            backgroundColor: '#F7F7F7',
                                            padding: '.8rem',
                                            paddingLeft: '.8rem',
                                            paddingRight: '32px',
                                            borderRadius: '4px',
                                            marginTop: '20px',
                                            marginBottom: '20px'
                                        }}
                                    >
                                        <ul>
                                            {domain.expected_nameservers.map((nameServer, index) => (
                                                <li>
                                                    {domain.nameservers[index] ?
                                                        <>Change <b>{domain.nameservers[index]} </b> to <b>{nameServer}</b></>
                                                        :
                                                        <>Add new NS Record <b>{nameServer}</b></>
                                                    }
                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                    <p>
                                        After the records have been updated it can take
                                        <b> up to 48 hours </b> for the changes to take
                                        affect. <br/><br/>
                                        <b>Important:</b> You need to check this page, after the you updated the
                                        records.<br/>
                                        If the change has been propagated, you need to <b>manually</b> request the
                                        domain activation on this page.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p>
                                        Your domain will be registered in the app, this can take <b>up to 30 minutes</b>.
                                        <br/>
                                        Please reload the page or check again later to get information about how to change your NS records.
                                    </p>
                                </>
                            )}

                        </Content>
                    </>
                ) : null}
                {domain.status === 'PENDING_VERIFICATION' &&
                domain.verification_result === true ? (
                    <>
                        {getMultiSteps(3)}
                        <Content wide headerGrid noMargin>
                            <div className="headerRow">
                                <h1 className="underline__lh-default">
                                    <GlobeOutline
                                        color={'currentColor'}
                                        title={'Domain'}
                                        width="2rem"
                                        height="2rem"
                                        className="icon"
                                    />
                                    Looks like your nameserver records are all set!
                                </h1>
                                <h3> {domain.name} </h3>
                            </div>
                        </Content>
                        <Content className="custom_dns">
                            <p>
                                You can start the activation process by pressing the
                                button below. As this step involves some manual
                                labour the activation can take
                                <b> up to 72 hours </b>.<br/>
                                You will be notified by mail when your domain is
                                ready.
                            </p>
                        </Content>
                    </>
                ) : null}
                {domain.status === 'PENDING_ACTIVATION' ? (
                    <>
                        {getMultiSteps(4)}
                        <Content wide headerGrid noMargin>
                            <div className="headerRow">
                                <h1 className="underline__lh-default">
                                    <GlobeOutline
                                        color={'currentColor'}
                                        title={'Domain'}
                                        width="2rem"
                                        height="2rem"
                                        className="icon"
                                    />

                                    Activation in progress
                                </h1>
                                <h3> {domain.name} </h3>
                            </div>
                        </Content>
                        <Content className="custom_dns">
                            <p>
                                You will be notified by mail when your domain is
                                ready.
                            </p>
                        </Content>
                    </>
                ) : null}
                {domain.status === 'AVAILABLE' ? (
                    <>
                        {getMultiSteps(5)}
                        <Content wide headerGrid noMargin>
                            <div className="headerRow">
                                <h1 className="underline__lh-default">
                                    <GlobeOutline
                                        color={'currentColor'}
                                        title={'Domain'}
                                        width="2rem"
                                        height="2rem"
                                        className="icon"
                                    />

                                    Your domain is ready to be used!
                                </h1>
                                <h3> {domain.name} </h3>
                            </div>
                        </Content>
                    </>
                ) : null}
                <Content
                    button
                    style={{
                        display: 'inline-block',
                    }}
                >
                    <Button
                        label="Back to domain overview"
                        secondary
                        onClick={() => {
                            history.push('/domains')
                        }}
                    />
                    {domain.status === 'PENDING_VERIFICATION' &&
                    domain.verification_result === true ? (
                        <Button
                            label="Activate my Domain!"
                            onClick={() => {
                                activationHandler()
                            }}
                        />
                    ) : null}
                    {domain.status === 'AVAILABLE' && (
                        <Button
                            label="Delete Domain"
                            secondary
                            onClick={() => {
                                history.push(`/domains/details/${domain.id}/delete`)
                            }}
                        />
                    )}
                </Content>
            </div>
        );
    }
}

ActivateMultiStep.propTypes = {
    domain: PropTypes.object.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ActivateMultiStep;
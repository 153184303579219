import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import chartStyle from '../../../components/Charts/style.module.scss';
import style from './style.module.scss';
import { getCampaigns, getCampaignAnalytics } from '../../../api/campaigns';
import Spinner from '../../../components/Spinner';
import DoughnutChart from '../../../components/Charts/DoughnutChart';
import LineChart from '../../../components/Charts/LineChart';
import BarChart from '../../../components/Charts/BarChart';
import ListChart from '../../../components/Charts/ListChart';
import FilterBar from '../../../components/FilterBar';
import DateFilter from '../../../components/FilterBar/DateFilter';
import Content from '../../../components/Content';
import Copy from '../../../components/Copy/Copy';
import BackLink from '../../../components/BackLink';
import TagCloud from '../../../components/TagCloud';
import CSVDownloadButton from '../../../components/CSVDownloadButton/CSVDownloadButton';
import { MegaphoneOutline } from 'react-ionicons';
import Table from '../../../components/Table/DynamicTable';

const moment = require('moment');

export default class CampaignDetail extends Component {
    constructor(props) {
        super(props);
        const { match } = props;
        const { id } = match.params;
        this.state = {
            id,
            selectedRange: '30d',
            dateStart: moment().startOf('day').add(12, 'hours').subtract(30, 'days')
                .format('YYYY-MM-DD'),
            dateEnd: moment().startOf('day').subtract(12, 'hours').format('YYYY-MM-DD'),
            analytics: null,
            title: '',
            isFetchingData: true,
        };
    }

    async componentDidMount() {
        ReactGA.ga('send', {
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Campaign Analytics',
        });
        const { id, dateStart, dateEnd } = this.state;
        const campaign = await getCampaigns(id);

        const analytics = await getCampaignAnalytics(id, { dateStart, dateEnd });

        this.setState({
            analytics,
            campaign,
            title: campaign.title,
            isFetchingData: false,
        });
    }

    async updateDate({ from, to }) {
        const { id } = this.state;

        const dateStart = moment(from).format('YYYY-MM-DD');
        const dateEnd = moment(to).format('YYYY-MM-DD');
        this.setState({ isFetchingData: true });
        const analytics = await getCampaignAnalytics(
            id, { dateStart, dateEnd },
        );
        this.setState({
            analytics, dateStart, dateEnd, isFetchingData: false,
        });
    }

    renderLinkTable() {
        const { analytics } = this.state;
        const { containerStyle } = this.props;

        const columns = [
            {
                Header: 'Shortlinks',
                id: 'links',
                accessor: (d) => (
                    <div className={style.listChartEntry}>
                        <Copy value={`${d[1].shortlink}`} />
                    </div>
                ),
                style: {
                    width: '250px',
                },
            },
            {
                Header: 'Tags',
                id: 'tags',
                accessor: (d) => (
                    <div className={style.listChartEntry}>
                        <div className="tagContainer">
                            <TagCloud tags={d[1].tags} />
                        </div>
                    </div>
                ),
                style: {
                    width: '50%',
                    textAlign: 'left',
                },
            },
            {
                Header: 'Clicks',
                id: 'clicks',
                accessor: (d) => (
                    <div className={style.listChartEntryBold}>
                        <p>{`${d[1].hitsTotals.data.total} Views`}</p>
                    </div>
                ),
                style: {
                    width: '200px',
                    textAlign: 'right',
                },
            },
        ];

        return (
            <div className={style.linkTable} style={containerStyle}>
                <h2 className={style.chartHeadline}>Links</h2>
                <div className={style.chart}>
                    <Table
                        data={Object.entries(analytics.shortlinks)}
                        columns={columns}
                        defaultPageSize={100}
                        nothingFoundText="Sorry, we couldn't find any data yet ..."
                    />
                </div>
            </div>
        );
    }

    render() {
        const {
            id, dateStart, dateEnd, campaign, selectedRange, title, analytics, isFetchingData,
        } = this.state;

        function getDescriptionText() {
            return `Created: ${moment(campaign.created_at).format('DD.MM.Y')} · User: ${campaign.user} · Group: ${campaign.usergroup}`;
        }

        return (
            <div>
                <Content style={{ marginBottom: '2rem' }}>
                    <BackLink target="/campaigns" title="Back to Campaigns" />
                </Content>
                <Content wide headerGrid noMargin>
                    <div className="headerRow">
                        <h1 className="underline__lh-campaigns">
                            <MegaphoneOutline
                                color={'currentColor'}
                                title={'Campaigns'}
                                width="2rem"
                                height="2rem"
                                className="icon"
                            />
                            {title}
                        </h1>
                        {campaign ? (
                            <div>
                                <p className="information-bar">
                                    {getDescriptionText()}
                                </p>
                            </div>
                        ) : null}
                    </div>
                </Content>
                <Content wide>
                    <FilterBar>
                        {!isFetchingData
                            ? (
                                <CSVDownloadButton
                                    type="campaigns"
                                    id={parseInt(id, 10)}
                                    dateStart={dateStart}
                                    dateEnd={dateEnd}
                                />
                            ) : null}
                        <DateFilter
                            selectedRange={selectedRange}
                            dateStart={dateStart}
                            dateEnd={dateEnd}
                            callback={(dates) => this.updateDate(dates)}
                        />
                    </FilterBar>
                    <br />
                </Content >
                <Content wide>
                    {analytics ? (
                        <div>
                            {this.renderLinkTable()}
                            <LineChart
                                data={analytics.hitsByDay.data}
                                totalHits={analytics.hitsTotals.data.total}
                                headline="Clicks by day"
                                keyColor="#318692"
                                containerStyle={{ marginTop: 20 }}
                            />
                            <BarChart
                                data={analytics.hitsByHourOfDay.data}
                                headline="Clicks by time"
                                keyColor="#318692"
                                containerStyle={{ marginTop: 30 }}
                            />
                            <div className={chartStyle.chartGroup} style={{ marginBottom: 30 }}>
                                <DoughnutChart
                                    data={analytics.hitsTotals.data}
                                    headline="Devices"
                                    colorDesktop="#318692"
                                    colorMobile="#3DB3C4"
                                    colorTablet="#B9DFE5"
                                    containerStyle={{ marginTop: 30 }}
                                />
                                <ListChart
                                    data={analytics.hitsByReferrer.data}
                                    headline="Top 5 visitor sources by clicks"
                                    containerStyle={{ marginTop: 30 }}
                                />
                            </div>
                        </div>
                    ) : null}
                    <Spinner show={isFetchingData} />
                </Content>
            </div >
        );
    }
}

CampaignDetail.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.node,
        }).isRequired,
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    containerStyle: PropTypes.any.isRequired,
};

import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import Trend from 'react-trend'
import { getLinkAnalytics } from '../../api/links'
import moment from 'moment'
import LinkboardSpinner from '../Spinner/LinkboardSpinner'


function TrendlineChart(props) {
    const id = props.id
    const dateStart = moment().startOf('day').add(12, 'hours').subtract(6, 'days').format('YYYY-MM-DD')
    const dateEnd = moment().startOf('day').add(12, 'hours').format('YYYY-MM-DD')
    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(true);

    const getAnalyticsForTrends = () => {
        getLinkAnalytics(id, { dateStart, dateEnd })
        .then((res) => {
            let data = Object.values(res.hitsByDay.data)
            setData(data)
        }).catch((err) => console.error(err))
    }

    useEffect(() => {
        getAnalyticsForTrends()
    }, [id])

    useEffect(() => {
        setIsFetching(true);
    }, [id])

    useEffect(() => {
        setIsFetching(false);
    }, [data])

    if (!isFetching) {
        return (
            <div className={style.trends}>
                <Trend
                    smooth
                    autoDraw
                    autoDrawDuration={3000}
                    autoDrawEasing="ease-out"
                    data={data && data.length ? data : [0,0]}
                    gradient={data.reduce((partialSum, a) => partialSum + a, 0) === 0 ? ['#9c9c9c'] : ['#048fa1']}
                    radius={10}
                    strokeWidth={6}
                    strokeLinecap={'round'} />
            </div>
        )
    } else {
        return (
            <div className={style.trends}>
               <LinkboardSpinner show={true} small={true}/>
            </div>
        )
    }
    
}

export default TrendlineChart

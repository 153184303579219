/**
 * This is really bad.
 *
 * The DOM should never be manipulated directly in an React application.
 * This leads to all kinds of undefined behaviour and bugs which are impossible to debug.
 *
 * TODO: This needs to be completly re-done asap.
 */

import { LinkhawkServiceEntityConflict } from '../api/common';
/* eslint-disable no-case-declarations */
import { validateEmail, validateCode } from '../api/data-management';

export function validateInput(type, value, formItem) {
    switch (type) {
        case 'email':
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return emailRegex.test(String(value)
                .toLocaleLowerCase());
        case 'url':
            // eslint-disable-next-line no-useless-escape
            const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi
            // TODO: Remove this
            // const urlRegex = /\b(?:(?:https?):\/\/|www\.)[-a-z0-9+&@#/%?=~_|!:,.;]*[-a-z0-9+&@#/%=~_|]/i;
            return urlRegex.test(String(value)
                .toLocaleLowerCase());
        case 'slash-tag':
            const slashTagRegex = /^[a-zA-Z0-9_-]*$/;
            return slashTagRegex.test(String(value).toLocaleLowerCase());
        case 'text':
            return value !== '';
        case 'file':
            const image = formItem.attributes['data-image'];
            return image;
        case 'number':
            const numberRegex = /[0-9]/;
            return numberRegex.test(String(value)
                .toLocaleLowerCase());
        case 'select':
        case 'multi-select':
            const values = formItem.getElementsByClassName('selectbox__value-container--has-value');
            return values.length;
        default:
            return true;
    }
}

export function validateRequiredFields(fields) {
    let error = false;

    // eslint-disable-next-line consistent-return
    // eslint-disable-next-line array-callback-return
    fields.map((formItem) => {
        const { value } = formItem;
        const type = formItem.attributes['data-type']
            ? formItem.attributes['data-type'].value
            : formItem.type;
        const requiredField = formItem.attributes['data-required'];
        const { classList } = type === 'multi-select' || type === 'select'
            ? formItem.querySelector('.selectbox__control')
            : formItem.parentElement;
        const isValid = validateInput(type, value, formItem);

        if (!requiredField && formItem.value === '') {
            formItem.classList.remove('error');
            classList.remove('errorMessage');
            return '';
        }

        if (!isValid) {
            formItem.classList.add('error');
            classList.add('errorMessage');
            classList.add(type);
            error = true;
        } else {
            formItem.classList.remove('error');
            classList.remove('errorMessage');
            classList.remove(type);
        }
    });

    return error;
}

// urls rein reichen
// checken ob required
// wenn nicht required und leer dann return ansonsten normal

export async function validateUnusedEmails(emailInputs) {
    let error = false;

    await Promise.all(emailInputs.map(async (input) => {
        const { value } = input;
        const { classList } = input.parentElement;
        try {
            await validateEmail(value);
            input.classList.remove('error');
            classList.remove('errorMessage');
        } catch (err) {
            if (err instanceof LinkhawkServiceEntityConflict) {
                input.classList.add('error');
                classList.add('errorMessage');
                error = true;
            } else {
                throw err;
            }
        }
    }));

    return error;
}

export async function validateUnusedCode(codeInput) {
    const { value } = codeInput;
    const { classList } = codeInput.parentElement;
    const response = await validateCode(value);

    let error = false;
    if (!response.ok) {
        codeInput.classList.add('error');
        classList.add('errorMessage');
        error = true;
    } else {
        codeInput.classList.remove('error');
        classList.remove('errorMessage');
    }

    return error;
}

export function clearErrors() {
    const errorMessages = Array.from(document.querySelectorAll('.errorMessage'));
    errorMessages.map((field) => {
        field.classList.remove('errorMessage');
        return field;
    });
    const errorSigns = Array.from(document.querySelectorAll('.error'));
    errorSigns.map((field) => {
        if (!field.classList.contains('do_not_remove_using_global_doc_query_bs')) {
            field.classList.remove('error');
            return field;
        }
        return field;
    });
}

export function clearError() {
    document.activeElement.classList.remove('error');
    document.activeElement.parentElement.classList.remove('errorMessage');
}
/* eslint-disable no-case-declarations */
import {
    get, post, del,
} from '../api/common';
import { getAccount } from '../util/user-management';
import { fetchAccount } from '../api/account';

export const unsubscribe = async(store, state, payload) => {
    store.setState({ loading: true });

    await post('/api/unsubscribe', 'POST', payload);

    await fetchAccount();
    store.setState({ loading: false });
};

export const deleteAccount = async(store) => {
    store.setState({ loading: true });

    const account = getAccount();
    await del(`/api/account/${account.id}`);

    store.setState({ loading: false });
    localStorage.clear();
    window.location.href = '/closed-account';
};

export const getInvoices = async(store) => {
    const invoices = await get('/api/invoices');

    store.setState({ invoices, invoicesLoading: false });
};

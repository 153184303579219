import actions from "../../actions";
import {connect} from "react-unistore";
import React, {useEffect, useState} from "react";
import {resendEmailConfirmation} from "../../api/email";
import Form from "../Form";
import Button from "../Button";
import style from './style.module.scss';

const ResendEmailConfirm = (props) => {

    const [state, setState] = useState({
        counterForResend: 0,
        email: props.email,
    });

    useEffect(() => {
        if (state.counterForResend > 0) {
            setTimeout(() => {
                setState({ ...state, counterForResend: state.counterForResend - 1 });
            }, 1000);
        }
    }, [state.counterForResend])

    const handleResendEmailConfirmation = (async(e) => {
        e.preventDefault();

        const email = state.email;

        if (email === '') {
            props.toggleSnackbar('Please enter your email address.');
            return;
        }

        if (state.counterForResend > 0) {
            return;
        }

        setState({ ...state, counterForResend: 60 });

        try {
            await resendEmailConfirmation({ email });
            props.toggleSnackbar('Confirmation email sent.');
        } catch (e) {
            props.toggleSnackbar('Failed to send confirmation email.');
        }
    });

    return (
        <Form styles={{ justifyContent: 'center' }}>
            <div className={style.resend_email_confirm}>
                <Form.AutocompleteInput
                    type="email"
                    name="email"
                    width={100}
                    placeholder="Email address"
                    data-required
                    value={state.email}
                    onChange={e => setState({...state, email: e.target.value})}
                />
                <Button
                    label="Resend confirmation email"
                    type="submit"
                    width={100}
                    onClick={handleResendEmailConfirmation}
                    isDisabled={state.counterForResend > 0}
                />
                <p className={style.counter}>
                    {state.counterForResend > 0 && (
                        <span>Resend again in {state.counterForResend} seconds</span>
                    )}
                </p>
            </div>
        </Form>
    )
}

export default connect('user', actions)(ResendEmailConfirm);
